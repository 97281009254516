// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="post-meta d-inline-flex align-items-center flex-wrap">
        <div class="text-muted mr-05">
            <span
                v-tooltip
                :title="post.published_at | format-time"
                class="mr-05"
            >
                {{ post.published_at | humanize-time }}
            </span>

            <ReadingTime
                v-if="!post.is_video"
                :reading-time="post.reading_time"
                class="mr-05 is-divide text-muted"
            />

            <button
                ref="clipboard"
                v-tooltip
                :class="$style.copyUrl"
                class="icon-btn"
                :title="$t('copyURL')"
            >
                <i class="fa fa-link" aria-hidden="true" />
            </button>
        </div>

        <div v-if="shouldShowFeatured" class="text-muted mr-05">
            <span>{{ $t('profile.featured') }} {{ post.promoted_at | humanize-time }}</span>
        </div>

        <div v-if="shouldShowTrending" class="text-muted mr-05">
            <span>{{ $t('profile.trending') }} {{ post.trend_at | humanize-time }}</span>
        </div>
    </div>
</template>

<script>
    import Clipboard from 'clipboard'
    import { absolute, post as toPost } from '~/utils/url'
    import ReadingTime from '~/components/posts/widgets/ReadingTime.vue'

    export default {
        components: {
            ReadingTime,
        },

        props: {
            post: {
                type: Object,
                required: true,
            },

            showFeatured: {
                type: Boolean,
                default: true,
            },

            showTrending: {
                type: Boolean,
                default: true,
            },
        },

        computed: {
            shouldShowFeatured() {
                return this.showFeatured && Boolean(this.post.promoted_at)
            },

            shouldShowTrending() {
                return this.showTrending && Boolean(this.post.trend_at)
            },
        },

        mounted() {
            const text = () => absolute(toPost(this.post))
            this.$_clipboard = new Clipboard(this.$refs.clipboard, { text })
        },

        beforeDestroy() {
            if (this.$_clipboard) {
                this.$_clipboard.destroy()
            }
        },
    }
</script>

<style module>
    .copyUrl i {
        color: #9b9b9b;
        transform: rotate(-45deg);
    }
</style>
