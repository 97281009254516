// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="pt-2 pb-2">
        <i
            v-if="current.length <= 0 && isLoading"
            class="fa fa-circle-o-notch fa-spin sb-icon loading"
            aria-hidden="true"
        />
        <div v-else class="questions">
            <div>
                <div v-if="current.length <= 0" class="text-center">
                    <empty>
                        {{ $t('question.noQuestion') }}
                    </empty>
                </div>

                <div v-else class="questions-list">
                    <div
                        v-for="(id, index) in current"
                        :key="index"
                        class="questions-list--card"
                    >
                        <QuestionItem
                            :question-id="id"
                            feed-store="profile/questions"
                            :highlight="true"
                        />
                    </div>
                </div>
            </div>

            <client-only>
                <InfiniteLoading :distance="50" spinner="spiral" @infinite="load">
                    <span slot="no-results" />
                    <span slot="no-more" />
                </InfiniteLoading>
            </client-only>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import InfiniteLoading from 'vue-infinite-loading'
    import QuestionItem from '~/components/subdomains-theme-2/feeds/QuestionItem.vue'
    import { handlePageError, getContentSubDomain } from '~/utils/pages'

    export default {
        scrollToTop: true,

        components: {
            InfiniteLoading,
            QuestionItem,
        },

        data() {
            return {
                isLoading: false,
            }
        },

        computed: {
            ...mapState('profile/questions', ['current', 'pagination']),
        },

        // eslint-disable-next-line consistent-return
        async fetch() {
            this.isLoading = true

            const req = this.$nuxt.context.ssrContext?.req
            const store = this.$nuxt.context.store
            const params = this.$nuxt.context.route.params
            const error = this.$nuxt.context.error

            const page = this.pagination?.current_page ? this.pagination?.current_page + 1 : 1
            const host = process.client ? window.location.host : req.headers.host
            const username = store.state.config.vars.is_special_profile ? getContentSubDomain(host) : params.user
            const limit = store.state.settings.perPage - (store.state.settings.perPage % 3)

            try {
                await store.dispatch('profile/questions/fetch', { username, params: { limit, page, isLoadMore: true } })

                this.isLoading = false
            } catch (err) {
                this.isLoading = false
                return handlePageError(error, err)
            }
        },

        beforeDestroy() {
            this.$store.dispatch('profile/questions/resetData')
        },

        watchQuery: ['page'],

        methods: {
            async load($state) {
                const currentPage = this.pagination?.current_page
                const totalPages = this.pagination?.total_pages

                if (currentPage < totalPages) {
                    await this.$fetch()
                    $state.loaded()
                } else {
                    $state.complete()
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
.questions {
    width: 100%;

    @media screen and (max-width: 1280px) {
        padding: 0 16px;
    }

    &-list {
        display: flex;
        justify-content: flex-start;
        align-items: stretch  ;
        flex-wrap: wrap;
        gap: 24px;
        row-gap: 24px;

        &--card {
            width: calc(100% / 3 - 16px);
            min-height: 100%;
        }

        @media screen and (max-width: 1024px) {
            gap: 16px;
            row-gap: 24px;

            &--card {
                width: calc(100% / 2 - 8px);
            }
        }

        @media screen and (max-width: 720px) {
            row-gap: 24px;

            &--card {
                width: 100%;
            }
        }
    }
}

.theme-dark {
    .questions {
        color: #fff;
    }
}
</style>

<style scoped>
.loading {
    position: unset;
    width: 100%;
    text-align: center;
    font-size: 24px;
}
</style>
