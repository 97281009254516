// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <ul class="heading-tabs-subdomain horizontal-scroll">
        <slot />
    </ul>
</template>

<script>
    export default {
        props: {
            activeTabIndex: [String, Number],
        },
    }
</script>

<style lang="scss">
    @import "~assets/sass/bootstrap/colors";

    .heading-tabs-subdomain {
        padding: 0;
        display: flex;
        list-style: none;
        margin-bottom: 0;

        &__item {
            color: #9b9b9b;
            border-bottom: 2px solid #EDEDED;
;
            flex-grow: 1;
            display: flex;
            justify-content: center;

            &.active {
                color: #0EA8FF;
                border-bottom-color: #0EA8FF;
            }

        }

        &__item:not(.active):hover {
            color: #5488c7;
        }

        &__link {
            color: inherit;
            display: block;
            padding: 13px 14px 8px 14px;

            &:hover, &:focus {
                outline: none;
                color: inherit;
                text-decoration: none;
            }
        }
    }

    .horizontal-scroll {
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .theme-dark {
        .heading-tabs-subdomain {
            background: #000;

            &__item {
                color: $dark-text-secondary;
                border-bottom: 2px solid $dark-border;
                &.active {
                    color: white;
                    border-bottom-color: white;
                }
            }
            &__item:not(.active):hover {
                color: white;
            }
        }
    }
</style>
