var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"post-feed-item",class:{ 'bg-highlight': _vm.highlight }},[_c('Avatar',{class:_vm.user.level_partner ? '' : 'mr-05',attrs:{"object":_vm.user,"image":_vm.user.avatar,"is-user":true,"level-partner":_vm.user.level_partner,"partner-width":44,"partner-hight":44,"size":_vm.user.level_partner ? 'md-mini' : 'md'}}),_vm._v(" "),_c('div',{staticClass:"post-feed-item__info ml-05"},[_c('div',{staticClass:"post-meta--inline"},[_c('UserInline',{attrs:{"user":_vm.user,"show-avatar":false,"show-username":false}}),_vm._v(" "),(_vm.hasOrganization && _vm.showOrganization)?_c('div',[(_vm.isSubmitPending)?_c('OrganizationInlinePopover',{staticClass:"mr-05",attrs:{"organization":_vm.post.organization,"prefix":"pending in "}}):_c('OrganizationInlinePopover',{staticClass:"mr-05",attrs:{"organization":_vm.post.organization}})],1):_vm._e(),_vm._v(" "),_c('PostMeta',{attrs:{"post":_vm.post}}),_vm._v(" "),_vm._t("menu"),_vm._v(" "),(_vm.pinnedPost)?_c('span',{staticClass:"pinned-icon"},[_c('i',{staticClass:"fa fa-thumb-tack",attrs:{"aria-hidden":"true"}})]):_vm._e()],2),_vm._v(" "),_c('PostTitleInline',{attrs:{"url":_vm.url,"title":_vm.post.title,"tags":_vm.tags,"is-video":_vm.post.is_video}}),_vm._v(" "),(_vm.showPreview)?_c('MarkdownPure',{attrs:{"contents":_vm.post.contents_short}}):_vm._e(),_vm._v(" "),(!_vm.post.system)?_c('div',{staticClass:"d-flex footer-post-item"},[_c('Stats',{attrs:{"stats":_vm.stats}}),_vm._v(" "),_c('div',{staticClass:"comment-vote-count"},[_c('div',{staticClass:"d-flex"},[(_vm.post.commentators.data.length > 0)?_c('Commentators',{staticClass:"commentators",attrs:{"users":_vm.post.commentators.data,"limit":2}}):_vm._e(),_vm._v(" "),(_vm.hasRole('social-media-editor') || _vm.isAdmin)?_c('span',{staticClass:"d-inline-block ml-05"},[(_vm.post.social_shares)?_c('i',{class:{
                                'text-primary text-bold': _vm.post.is_shared && _vm.post.social_shares[0].user_id !== null,
                                'text-danger text-bold': _vm.post.is_shared && _vm.post.social_shares[0].user_id === null,
                                'el-icon-share': true
                            }}):_c('i',{class:{
                                'text-primary text-bold': _vm.post.is_shared,
                                'el-icon-share': true
                            }})]):_vm._e(),_vm._v(" "),(_vm.hasRole('social-media-editor') || _vm.isAdmin && _vm.post.is_shared)?_c('el-dropdown',{staticClass:"ml-05",attrs:{"size":"medium","placement":"bottom","trigger":"click"}},[_c('i',{staticClass:"el-icon-arrow-down text-muted cursor-pointer"}),_vm._v(" "),_c('el-dropdown-menu',[_c('el-dropdown-item',{nativeOn:{"click":function($event){return _vm.openConfirmDelete.apply(null, arguments)}}},[_c('span',{staticClass:"link link-plain"},[_vm._v("\n                                    "+_vm._s(_vm.$t('cancelScheduled.title'))+"\n                                ")])])],1)],1):_vm._e()],1),_vm._v(" "),_c('Score',{attrs:{"score":_vm.post.points,"rated":_vm.post.rated_value}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }