// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="post-feed-org px-1 pb-2">
        <question-feed feed-store="profile/questions" @request-reload="reload" />
    </div>
</template>

<script>
    import QuestionFeed from '~/components/feeds/QuestionFeed.vue'
    import { handlePageError, getContentSubDomain } from '~/utils/pages'

    const fetchStore = (store, username, params) => store.dispatch('profile/questions/fetch', { username, params })

    export default {
        scrollToTop: true,

        components: {
            QuestionFeed,
        },

        fetch({
            params, query, store, error, redirect, req,
        }) {
            const host = process.client ? window.location.host : req.headers.host
            const user = store.state.config.vars.is_special_profile ? getContentSubDomain(host) : params.user
            return fetchStore(store, user, {
                limit: store.state.settings.perPage,
                page: query.page,
            })
                .then(() => {
                    const pagination = store.state.profile.questions.pagination
                    if (pagination.current_page > pagination.total_pages) {
                        redirect(`/u/${params.user}/questions?page=${pagination.total_pages}`)
                    }
                })
                .catch(handlePageError(error))
        },

        watchQuery: ['page'],

        methods: {
            reload() {
                fetchStore(this.$store, this.$route.params.user, {
                    limit: this.$store.state.settings.perPage,
                    page: this.$route.query.page,
                })
                    .then(() => {
                        const pagination = this.$store.state.profile.questions.pagination
                        if (pagination.current_page > pagination.total_pages) {
                            const url = `/u/${this.$route.params.user}/questions?page=${pagination.total_pages}`
                            this.$router.push(url)
                        }
                    })
            },
        },
    }
</script>

<style scoped lang="scss">
.dark-mode {
  background-color: #000000;
  color: #FFFFFF;
}
</style>
