// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div v-show="show" class="suggestion-container">
        <template v-for="(item, index) in items">
            <div
                :key="index"
                :class="{ active: current === index }"
                class="suggestion-container__item"
                @click="select(item.completion)"
                @mouseover="current = index"
            >
                <slot :item="item" />
            </div>
        </template>
    </div>
</template>

<script>
    import { rotateIndex } from '~/utils/helpers'

    export default {
        props: {
            items: Array,
            show: Boolean,
        },

        data: () => ({
            current: 0,
        }),

        watch: {
            show() {
                this.current = 0
            },
        },

        methods: {
            select(value) {
                this.$emit('selected', value)
            },

            selectCurrent() {
                const current = this.items[this.current]
                this.select(current.completion)
            },

            moveUp() {
                if (this.show && this.items.length > 0) {
                    this.current = rotateIndex(this.current, 0, this.items.length - 1, 'prev')
                }
            },

            moveDown() {
                if (this.show && this.items.length > 0) {
                    this.current = rotateIndex(this.current, 0, this.items.length - 1, 'next')
                }
            },
        },
    }
</script>

<style lang="scss">
    .suggestion-container {
        z-index: 9999;
        max-height: 160px;
        position: absolute;
        background: #fff;
        overflow-y: auto;
        overflow-x: hidden;
        box-shadow: 0 0 5px rgba(27, 31, 35, 0.1);

        &__item {
            display: flex;
            align-items: center;
            height: 32px;
            padding: 5px 10px;
            border-bottom: 1px solid #dfe2e5;

            &.active {
                background: #E8F5FD;
                color: #1DA1F2;
                cursor: pointer;
            }
        }
    }
</style>
