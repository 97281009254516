// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div :class="{ 'card border-0 rounded-0 mb-0': true, 'bg-highlight': highlight }">
        <div class="card-block series-feed-item d-flex">
            <div
                class="mr-05 d-flex position-relative flex-col
                justify-content-center align-items-center max-height-avt-user"
            >
                <avatar
                    :image="series.user.data.avatar"
                    :object="user"
                    :is-user="true"
                    :size="user.level_partner ? 'md-mini' : 'md'"
                    :level-partner="user.level_partner"
                    :partner-width="45"
                    :partner-hight="45"
                />
            </div>

            <div class="ml-05 full-width">
                <series-header :series="series" />

                <div class="d-flex justify-content-between">
                    <series-stats v-if="series.published_at !== null" :stats="stats" :title="titleStats" />
                    <score :score="series.points" :rated="series.rated_value" />
                </div>
            </div>
            <slot name="action" />
        </div>
    </div>
</template>

<script>
    import _pick from 'lodash/pick'
    import { user as toUser } from '~/utils/url'
    import SeriesStats from './Stats.vue'
    import SeriesHeader from './Header.vue'
    import Avatar from '../shared/Avatar/Avatar.vue'
    import Score from '../widgets/Score.vue'

    export default {
        components: {
            Score,
            Avatar,
            SeriesHeader,
            SeriesStats,
        },

        props: {
            series: {
                type: Object,
                required: true,
            },
            highlight: Boolean,
        },

        computed: {
            user() {
                return this.series.user.data
            },

            stats() {
                return _pick(this.series, [
                    'views_count',
                    'clips_count',
                    'comments_count',
                    'posts_count',
                ])
            },

            titleStats() {
                return {
                    comments: this.$t('question.comment'),
                    views: this.$t('question.view'),
                    clips: this.$t('question.clips'),
                    posts: this.$t('question.posts'),
                }
            },
        },

        methods: {
            toUser,
        },
    }
</script>

<style lang="scss">
    @import "../../assets/sass/bootstrap/borders";

    .series-feed-item {
        padding: .5rem;
        border-bottom: 1px solid $base-border-color;

        &.new {
            background-color: rgba(75, 205, 159, .15);
        }

        .content {
            img {
                display: block;
                max-width: 100%;
                margin: 1rem auto;
                max-height: 240px;
                &:not(:first-of-type) {
                    display: none;
                }
            }

            p {
                margin-bottom: 0;
            }
        }

        .content.drafted {
            margin-left: 0;
        }

        .video-thumbnail {
            cursor: pointer;
        }

        .commentators {
            .avatar {
                img {
                    width: 1.3rem;
                }
            }
        }

        .avt-series-size-md-mini {
            top: 17px;
            left: 5px;
        }

        .max-height-avt-user {
            max-height: 70px;
        }
    }

    .theme-dark {
        .series-feed-item {
            background-color: #000;
        }
    }
</style>
