import { render, staticRenderFns } from "./Badges.vue?vue&type=template&id=a972b554&scoped=true&"
import script from "./Badges.vue?vue&type=script&lang=js&"
export * from "./Badges.vue?vue&type=script&lang=js&"
import style0 from "./Badges.vue?vue&type=style&index=0&id=a972b554&lang=scss&scoped=true&"
import style1 from "./Badges.vue?vue&type=style&index=1&id=a972b554&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a972b554",
  null
  
)

export default component.exports