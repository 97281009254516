// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <el-dropdown
        class="comment-menu"
        placement="bottom-start"
        trigger="click"
    >
        <span v-tooltip :title="$t('detailQuestion.commentHasNotAccepted.actionComment.title')" class="cursor-pointer">
            <i :class="{ [`v-menu-icon--${size}`]: !!size }" class="el-icon-more-outline" />
        </span>

        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-if="permissions.canEdit" @click.native="$emit('edit')">
                <i class="fa fa-pencil pr-0 mr-05" aria-hidden="true" />
                {{ $t('detailQuestion.commentHasNotAccepted.actionComment.edit') }}
            </el-dropdown-item>

            <el-dropdown-item v-if="permissions.canReport" @click.native="$emit('report')">
                <i class="fa fa-flag-o pr-0 mr-05" aria-hidden="true" />
                {{ $t('detailQuestion.commentHasNotAccepted.actionComment.report') }}
            </el-dropdown-item>

            <el-dropdown-item v-if="permissions.canEdit || permissions.canDelete" @click.native="confirmDelete">
                <i class="fa fa-trash pr-0 mr-05" aria-hidden="true" />
                {{ $t('detailQuestion.commentHasNotAccepted.actionComment.deleteComment') }}
            </el-dropdown-item>

            <el-dropdown-item v-if="permissions.canConvertToAnswer" :divided="true" @click.native="confirmConvert">
                <i class="fa fa-refresh pr-0 mr-05" aria-hidden="true" />
                {{ $t('detailQuestion.commentHasNotAccepted.actionComment.convertToAnswer') }}
            </el-dropdown-item>

            <el-dropdown-item
                v-if="authenticated"
                :divided="!permissions.canConvertToAnswer"
                @click.native="confirmHighlightingCode"
            >
                <i class="fa fa-magic pr-0 mr-05" />
                {{ $t('detailQuestion.commentHasNotAccepted.actionComment.highlighting') }}
            </el-dropdown-item>
        </el-dropdown-menu>
        <DeleteReasonForm
            v-if="permissions.canEdit"
            ref="deleteReasonDialog"
            :delete-and-store-reason="deleteAndStoreReason"
            :target="comment"
        />
    </el-dropdown>
</template>

<script>
    import { mapGetters } from 'vuex'
    import { storeDeletedReason } from '~/api/delete-reason'
    import { convert } from '~/api/comments'
    import { highlightCodeBlocks } from '~/api/support'
    import { displayError } from '~/utils/pages'
    import DeleteReasonForm from '~/components/shared/DeleteReportForm.vue'

    export default {
        components: {
            DeleteReasonForm,
        },

        props: {
            size: {
                type: String,
                default: 'medium',
            },

            comment: {
                type: Object,
                required: true,
            },
        },

        computed: {
            ...mapGetters('auth', ['authenticated', 'isAdmin', 'hasRole', 'isUser']),

            permissions() {
                return {
                    canEdit: this.comment.is_own || this.isAdmin,
                    canReport: !this.comment.is_own,
                    canDelete: this.comment.commentable_type === 'User'
                        && this.isUser(this.comment.commentable_id),
                    canConvertToAnswer: this.isAdmin && this.comment.commentable_type === 'Question',
                }
            },
        },

        methods: {
            confirmDelete() {
                if (this.comment.is_own || !this.isAdmin) {
                    this.$confirm(
                        this.$t('detailQuestion.commentHasNotAccepted.confirmDelete.content'),
                        this.$t('detailQuestion.commentHasNotAccepted.confirmDelete.title'), {
                            confirmButtonText: this.$t('confirm.ok'),
                            cancelButtonText: this.$t('confirm.cancel'),
                        }
                    )
                        .then(() => this.$emit('delete', this.comment))
                } else {
                    this.$refs.deleteReasonDialog.open()
                }
            },

            confirmConvert() {
                this.$confirm(
                    this.$t('detailQuestion.commentHasNotAccepted.confirmConvert.content'),
                    this.$t('detailQuestion.commentHasNotAccepted.confirmConvert.title'), {
                        confirmButtonText: this.$t('confirm.ok'),
                        cancelButtonText: this.$t('confirm.cancel'),
                    }
                )
                    .then(() => this.convertToAnswer())
            },

            confirmHighlightingCode() {
                this.$confirm(
                    this.$t('detailQuestion.commentHasNotAccepted.confirmHighlight.content'),
                    this.$t('detailQuestion.commentHasNotAccepted.confirmHighlight.title'), {
                        confirmButtonText: this.$t('confirm.ok'),
                        cancelButtonText: this.$t('confirm.cancel'),
                    }
                )
                    .then(() => this.highlightCodeBlocks())
            },

            convertToAnswer() {
                return convert(this.comment)
                    .then(() => this.$message.success(this.$t('detailQuestion.commentHasNotAccepted.confirmConvert.success')))
                    .catch(displayError(this.$message.error))
            },

            highlightCodeBlocks() {
                return highlightCodeBlocks('comments', this.comment.hash_id)
                    .then(() => this.$message.success(this.$t('detailQuestion.commentHasNotAccepted.confirmHighlight.success')))
                    .catch(displayError(this.$message.error))
            },

            deleteAndStoreReason(comment, data) {
                storeDeletedReason('comments', comment.hash_id, data)
                this.$emit('delete', this.comment)
            },
        },
    }
</script>

<style lang="css">
    .v-menu-icon--medium {
        font-size: 1.75rem !important;
    }
    .v-menu-icon--small {
        font-size: 1.375rem !important;
    }
</style>
