// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _get from 'lodash/get'
import _flow from 'lodash/flow'
import _some from 'lodash/some'

import _map from 'lodash/fp/map'
import _keys from 'lodash/fp/keys'
import _take from 'lodash/fp/take'

const _pickBy = require('lodash/fp/pickBy').convert({ cap: false })

const regExps = {
    emoji: /(^|\s):([a-z0-9_]+)$/,
    mention: /(^|\s)@([A-Za-z0-9\\.\\-_]*)$/,
}

/**
 * @param {string} text
 * @returns {string}
 */
export function getSuggestionType(text) {
    return _keys(regExps).find(type => regExps[type].test(text))
}

/**
 * @param {string} type
 * @param {string} text
 */
export function getPendingText(type, text) {
    return text.match(regExps[type])[2]
}

export function createSuggestionProvider({ emoji, mentions }) {
    const providers = {
        emoji: getEmojiSuggestion(emoji),
        mention: getMentionSuggestion(mentions),
    }

    return (type, pendingText) => _get(providers, type, () => [])(pendingText)
}

function getEmojiSuggestion(emoji) {
    return input => _flow(
        _pickBy((_emoji, name) => name.startsWith(input)),
        _keys,
        _take(5),
        _map(key => ({
            completion: key,
            data: emoji[key],
        }))
    )(emoji)
}

function getMentionSuggestion(users) {
    const pickUser = input => user => _some([
        user.name,
        user.username,
    ], text => text.toLowerCase().indexOf(input.toLowerCase()) !== -1)

    const mapUser = index => ({
        completion: users[index].username,
        data: users[index],
    })

    return input => _flow(
        _pickBy(pickUser(input)),
        _keys,
        _take(5),
        _map(mapUser)
    )(users)
}
