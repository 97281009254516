// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <ul class="heading-tabs horizontal-scroll">
        <slot />
    </ul>
</template>

<script>
    export default {
        props: {
            activeTabIndex: [String, Number],
        },
    }
</script>

<style lang="scss">
    @import "../../assets/sass/bootstrap/colors";

    .heading-tabs {
        padding: 0;
        display: flex;
        list-style: none;
        margin-bottom: 0;

        &__item {
            color: $gray-light;
            border-bottom: 2px solid #fff;

            &.active {
                font-weight: bold;
                color: $primary;
                border-bottom-color: $primary;
            }

        }

        &__item:not(.active):hover {
            color: $gray;
        }

        &__link {
            color: inherit;
            display: block;
            padding: 13px 14px 8px 14px;

            &:hover, &:focus {
                outline: none;
                color: inherit;
                text-decoration: none;
            }
        }
    }

    .horizontal-scroll {
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;

        &::-webkit-scrollbar {
            display: none;
        }
    }
</style>
