// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<script lang="js">
    export default {

        functional: true,
        props: {
            stats: Object,
            className: [String, Array, Object],
            title: Object,
        },

        render(h, { props }) {
            return (
                <div class={['series-stats', props.className]}>
                    <span class='stats-item mr-05' title={`${props.title.views}: ${props.stats.views_count}`} v-tooltip>
                        <i class='fa fa-eye' aria-hidden='true'></i> { props.stats.views_count }
                    </span>

                    <span class='stats-item mx-05' title={`${props.title.clips}: ${props.stats.clips_count}`} v-tooltip>
                        <i class='fa fa-bookmark' aria-hidden='true'></i> { props.stats.clips_count }
                    </span>

                    <span class='stats-item mx-05' title={`${props.title.comments}: ${props.stats.comments_count}`} v-tooltip>
                        <i class='fa fa-comments' aria-hidden='true'></i> { props.stats.comments_count }
                    </span>

                    <span class='stats-item mx-05' title={`${props.title.posts}: ${props.stats.posts_count}`} v-tooltip>
                        <i class='fa fa-clipboard' aria-hidden='true'></i> { props.stats.posts_count }
                    </span>
                </div>
            )
        },
    }
</script>

<style lang="scss">
    @import "../../assets/sass/bootstrap/colors";

    .series-stats {
        display: flex;
        align-items: center;
        line-height: 22px;
        color: $gray-light;

        .stats-item {
            cursor: default;
        }
    }
</style>
