// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="answer-item">
        <nuxt-link :to="url" class="link">
            <h3 class="answer-item__title">
                {{ question.title }}
            </h3>
        </nuxt-link>

        <div class="answer-item__meta mb-05">
            <div class="answer-item__user d-flex align-items-center">
                <Avatar
                    :image="user.avatar"
                    :username="user.username"
                    size="md"
                    class="mr-05"
                />

                <div class="answer-item__user__info">
                    <nuxt-link :to="userPage" :class="{ 'is-banned': user.banned_at }" class="mr-05">
                        {{ user.name }}
                    </nuxt-link>
                    <div v-tooltip class="text-muted" :title="answer.created_at | format-time">
                        {{ $t('profile.answered') }} {{ answer.created_at | humanize-time }}
                    </div>
                </div>
            </div>
        </div>

        <ContentBox>
            <Markdown :contents="answer.contents" />
        </ContentBox>

        <div class="answer-item__footer">
            <ItemVote
                :score="answer.points"
                :rated-value="answer.rated_value"
                :send="sendVote.bind(null, answer.hash_id)"
            />
        </div>
    </div>
</template>

<script>
    import _get from 'lodash/get'
    import { user as toUser, question as toQuestion } from '~/utils/url'
    import ItemVote from '~/components/votes/ItemVote.vue'
    import Avatar from '~/components/shared/user/Avatar.vue'
    import Markdown from '~/components/contents/MarkdownPure.vue'
    import ContentBox from '~/components/contents/ContentBox.vue'

    export default {
        components: {
            Avatar,
            ItemVote,
            Markdown,
            ContentBox,
        },

        props: {
            answer: {
                type: Object,
                required: true,
            },

            sendVote: {
                type: Function,
                required: true,
            },
        },

        computed: {
            question() {
                return _get(this.answer, 'question.data', {})
            },

            user() {
                return _get(this.answer, 'user.data', {})
            },

            url() {
                return `${toQuestion(this.question)}#answer-${this.answer.hash_id}`
            },

            userPage() {
                return toUser(this.user)
            },
        },
    }
</script>

<style lang="scss">
    @import "~assets/sass/bootstrap/colors";

    .answer-item {
        padding: 1rem .5rem;

        &:not(:last-child) {
            border-bottom: 1px solid $gray-300;
        }
    }
</style>
