// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <empty v-if="data.length <= 0">
        <p> {{ $t('empty') }} </p>
    </empty>

    <div v-else>
        <feed-item
            v-for="(series, index) in data"
            :key="index"
            :series="series"
            :highlight="shouldHighlight(series)"
        />
    </div>
</template>

<script>
    import Empty from '~/components/shared/Empty.vue'
    import FeedItem from './ListItem.vue'

    export default {
        components: {
            Empty,
            FeedItem,
        },

        props: {
            data: Array,
            shouldHighlight: {
                type: Function,
                default: () => false,
            },
        },
    }
</script>
