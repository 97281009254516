// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="comment-threads profile-subdomain">
        <CommentForm
            v-if="authenticated"
            ref="form"
            :show-cancel="false"
            class="root-comment-form"
            :class="{commentDrakMode: isDrakMode}"
            name="comment_contents"
            :submit-text="$t('commentPost.postComment')"
            :submit="addComment"
            @cancel="cancel"
            @submit-success="submitSuccess"
        />

        <div v-if="!authenticated" class="card text-sm-center mb-1 cursor-pointer" @click="showAuthDialog">
            <div class="card-body">
                <span class="card-text text-muted">
                    <i class="fa fa-comment-o" aria-hidden="true" />
                    {{ $t('commentPost.login') }}
                </span>
            </div>
        </div>

        <div v-else-if="noComments" class="card card-block text-center" :class="modeTheme">
            <span class="card-text text-muted mt-05 mb-05">
                <i class="fa fa-comment-o" aria-hidden="true" />
                {{ $t('commentPost.noComent') }}
            </span>
        </div>

        <ListTransition>
            <Thread
                v-for="(comment, index) in rootComments"
                :key="index"
                :commentable="commentable"
                :thread="comment"
                :store="store"
                class="root"
                :class="{darkItem: isDrakMode}"
                :target-comment="targetComment"
            />
        </ListTransition>
    </div>
</template>

<script>
    import _findIndex from 'lodash/findIndex'
    import { mapActions, mapGetters, mapState } from 'vuex'
    import ListTransition from '~/transitions/List.vue'
    import CommentForm from './Form.vue'
    import Thread from './Thread.vue'

    export default {
        components: {
            Thread,
            CommentForm,
            ListTransition,
        },

        props: {
            commentable: Object,
            store: {
                type: String,
                required: true,
            },
        },

        data: () => ({
            isDrakMode: false,
        }),

        computed: {
            ...mapGetters('auth', ['authenticated']),
            ...mapState({
                rootComments(state, getters) {
                    return getters[`${this.store}/thread`]('root') || []
                },

                targetComment(state) {
                    const comments = Object.values(state.postView.comments.byId)
                    const hashId = this.$route.hash.replace('#comment-', '')
                    const index = _findIndex(comments, comment => comment.hash_id === hashId)

                    return index >= 0 ? comments[index].id : null
                },
            }),
            ...mapState('typeControl', ['modeTheme']),

            noComments() {
                return this.rootComments.length === 0
            },
        },

        watch: {
            modeTheme(value) {
                if (value === 'theme-dark') {
                    this.isDrakMode = true
                    return this.isDrakMode
                }
                this.isDrakMode = false

                return this.isDrakMode
            },
        },

        methods: {
            ...mapActions('auth', ['showAuthDialog']),
            ...mapActions({
                addComment(dispatch, formValues) {
                    return dispatch(`${this.store}/add`, {
                        commentable: this.commentable,
                        values: formValues.data,
                    })
                },
            }),

            submitSuccess() {
                this.$refs.form.reset()
            },

            cancel() {
                this.$refs.form.reset()
            },
        },
    }
</script>

<style lang="scss">
    @import "~/assets/sass/bootstrap/_mixins";
    @import "~/assets/sass/bootstrap/borders";

    .comment-threads.profile-subdomain {
        .root-comment-form {
            border: unset;
            background-color: #fff;
            margin-bottom: 1rem;
        }

        .comment-thread {
            &.root {
                margin-bottom: 1rem;
                border: unset;
                background-color: #fff;
            }

            &.darkItem {
                background-color: #161b22;
                border: 1px solid gray;
            }
        }

        .theme-sepia {
            background-color: #f1e7d0;
            color: $black;
        }
    }

    @include media-breakpoint-down(sm) {
        .comment-origin {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

.theme-dark {
    .comment-threads.profile-subdomain {
        .root-comment-form, .card {
            background-color: #202020;
        }

        .comment-thread {
            &.root {
                background-color: #202020;
            }
        }
    }

    .list-transition .comment-meta .text-muted {
        color: #808080 !important;
    }

    .profile-subdomain .comment-form__username {
        color: #fff;
    }
}
</style>
