// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<script>
    import { tag as toTag } from '~/utils/url'

    const renderTags = (h, tags) => tags.map((tag, index) => <v-tag key={index} url={toTag(tag, 'questions')}>{ tag.name }</v-tag>)

    export default {
        functional: true,

        props: {
            tags: Array,
        },

        render: (h, { props, data }) => (
            <div class={['tags', data.class, data.staticClass]}>
                {renderTags(h, props.tags)}
            </div>
        ),
    }
</script>

<style lang="scss" scoped>
    .el-tag {
        height: 20px;

        line-height: 19px;
    }
</style>
