// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="posts-list-item w-100">
        <div class="w-100 d-flex align-items-center justify-content-start">
            <div
                class="posts-thumbnail col-md-4 d-md-flex flex-column d-none align-items-center justify-content-center"
            >
                <div class="d-none d-md-block position-relative">
                    <nuxt-link :to="url">
                        <img
                            :src="post.thumbnail_url"
                            alt="Thumbnail Image"
                            class="w-100 mr-2"
                        >
                    </nuxt-link>
                    <slot />
                </div>
                <div v-if="!post.system" class="mt-05 w-100 stats-item-lists">
                    <Stats :stats="stats" />
                </div>
            </div>
            <div class="col-md-8 d-block">
                <div class="post-feed-item__info ml-05">
                    <div class="d-flex justify-content-between align-items-center mb-05">
                        <div class="d-flex align-items-center">
                            <Avatar
                                :object="user"
                                :image="user.avatar"
                                :is-user="true"
                                :level-partner="user.level_partner"
                                :partner-width="42"
                                :partner-hight="42"
                                size="sm"
                            />
                            <nuxt-link :to="toUser" class="link">
                                <span class="name-all-posts username-user ml-05"> {{ user.name }} </span>
                            </nuxt-link>
                            <span
                                v-tooltip
                                :title="post.published_at | format-time"
                                class="ml-2 text-muted"
                            >
                                {{ post.published_at | humanize-time }}
                            </span>
                        </div>
                        <div>
                            <span
                                v-if="!post.is_video"
                                class="post-reading_time text-muted mr-1"
                                :title="`${post.reading_time}`.concat($t('contentMenu.minRead'))"
                            >
                                {{ post.reading_time }}{{ $t('contentMenu.minRead') }}
                            </span>
                        </div>
                    </div>

                    <PostTitleInline
                        :url="url"
                        :title="post.title"
                        :is-video="post.is_video"
                        is-post-feed
                    />

                    <MarkdownPure class="description-all-posts" :contents="convertImgToText(post.contents_short)" />

                    <PostTags class="mt-1" :tags="tags.tags" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import _isEmpty from 'lodash/isEmpty'
    import _get from 'lodash/get'
    import { post as toPost, user as toUser } from '~/utils/url'
    import Stats from '~/components/subdomains/shared/Stats.vue'
    import Avatar from '~/components/shared/Avatar/Avatar.vue'
    import PostTitleInline from '~/components/subdomains/posts/widgets/TitleInline.vue'
    import MarkdownPure from '~/components/contents/MarkdownPure.vue'
    import PostTags from '~/components/posts/widgets/Tags.vue'

    export default {
        components: {
            Avatar,
            Stats,
            PostTitleInline,
            MarkdownPure,
            PostTags,
        },

        props: {
            post: {
                type: Object,
                required: true,
            },

            showPreview: Boolean,

            showOrganization: {
                type: Boolean,
                default: true,
            },

            pinnedPost: {
                type: Boolean,
                default: false,
            },
            isOwner: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            ...mapGetters('auth', ['hasRole', 'isAdmin']),

            url() {
                return toPost(this.post)
            },

            toUser() {
                return toUser(this.user)
            },

            user() {
                const user = _get(this.post, 'user.data')

                return typeof user === 'object' && !!user
                    ? user
                    : this.$store.getters['entities/users/get'](user || this.post.user_id)
            },

            stats() {
                return [
                    {
                        name: this.$t('question.views').concat(`${this.post.views_count}`),
                        src: '/images/icon-eye.png',
                        value: this.post.views_count,
                    },
                    {
                        name: this.$t('question.votes').concat(`${this.post.points}`),
                        src: '/images/icon-like.png',
                        value: this.post.points,
                    },
                    {
                        name: this.$t('userInline.clips').concat(`${this.post.clips_count}`),
                        src: '/images/icon-bookmark.png',
                        value: this.post.clips_count,
                    },
                    {
                        name: this.$t('question.comments').concat(`${this.post.comments_count}`),
                        src: '/images/icon-cmt.png',
                        value: this.post.comments_count,
                    },
                ]
            },

            tags() {
                return {
                    tags: this.post.tags ? this.post.tags.data : null,
                    promoted: this.post.promoted,
                    trending: this.post.trending,
                    system: this.post.system_tag,
                }
            },

            hasOrganization() {
                return !_isEmpty(this.post.organization)
            },

            isSubmitPending() {
                if (
                    this.hasOrganization
                    && this.post.submit_status
                    && _isEmpty(this.post.submit_status.data.review_at)
                ) {
                    return true
                }

                return false
            },
        },

        methods: {
            convertImgToText(content) {
                return content ? content.replace(/!\[\]\(.+?\)/g, '[IMG]') : ''
            },
        },
    }
</script>

<style lang="scss">
    @import "~/assets/sass/bootstrap/borders";

    .posts-list-item {
        display: flex;
        align-items: flex-start;
        padding: .5rem;
        margin-bottom: 2rem;

        &__info {
            width: 100%;
        }

        .post-feed-item__info {
            .md-contents {
                font-family: 'Roboto';
                color: #707070;
                font-size: 14px;
                line-height: 26px;
            }

            .tags .tag {
                margin-bottom: 8px;
            }
        }

        .post-meta--inline {
            display: flex;
            flex-wrap: wrap;
            font-size: .8rem;
            margin-bottom: .25rem;
            align-items: center;
        }

        .commentators {
            .avatar {
                img {
                    width: 1.3rem;
                }
            }
        }

        .pinned-icon {
            margin-left: auto;
            font-size: 1rem;
            color: #5488c7;
            transform: rotate(15deg)
        }

        .el-icon-share {
            color: #9b9b9b;
        }

        .posts-thumbnail img {
            aspect-ratio: 16 / 9;
        }

        .description-all-posts {
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
        }

        .name-all-posts {
            font-size: 14px;
        }
    }

    @media screen and (min-width: 1101px) and (max-width: 1280px) {
        .stats-item-lists {
            .stats-item {
                font-size: 12px;

                &__icon {
                    height: 80%;
                    width: 80%;

                    .fa-caret-up {
                        margin-top: -2px;
                    }
                }
            }
        }
    }
    @media screen and (min-width: 991px) and (max-width: 1100px) {
        .stats-item-lists {
            .stats-item {
                font-size: 11px;

                &__icon {
                    margin-right: 1px;
                    height: 80%;
                    width: 50%;

                    .fa-caret-up {
                        margin-top: -2px;
                    }
                }
            }
        }
    }
    @media screen and (min-width: 768px) and (max-width: 900px) {
        .stats-item-lists {
            .stats-item {
                font-size: 12px;

                &__icon {
                    height: 80%;
                    width: 80%;

                    .fa-caret-up {
                        margin-top: -2px;
                    }
                }
            }
        }
    }
</style>

<style lang="scss" scoped>
.posts-thumbnail {
    padding-left: 5px;
    padding-right: 5px;
}

.theme-dark {
    .posts-list-item {
        background-color: #353535;
        border-radius: 8px;

        .name-all-posts {
            color: #fff;
        }

        .description-all-posts {
            color: #e4e6eb;
        }
    }
}

.dark-mode {
  .posts-list-item {
        display: flex;
        align-items: flex-start;
        padding: .5rem;
        margin-bottom: 2rem;
        color: #FFFFFF;

        &__info {
            width: 100%;
        }

        .post-feed-item__info {
            .md-contents {
                font-family: 'Roboto';
                color: #707070;
                font-size: 14px;
                line-height: 26px;
            }

            .tags .tag {
                margin-bottom: 8px;
            }
        }

        .post-meta--inline {
            display: flex;
            flex-wrap: wrap;
            font-size: .8rem;
            margin-bottom: .25rem;
            align-items: center;
        }

        .commentators {
            .avatar {
                img {
                    width: 1.3rem;
                }
            }
        }

        .pinned-icon {
            margin-left: auto;
            font-size: 1rem;
            color: #5488c7;
            transform: rotate(15deg)
        }

        .el-icon-share {
            color: #9b9b9b;
        }

        .posts-thumbnail img {
            aspect-ratio: 16 / 9;
        }

        .description-all-posts {
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
        }

        .name-all-posts {
            font-size: 14px;
        }
    }
}
</style>
