// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div :class="{ 'bg-highlight': highlight }" class="post-feed-item">
        <Avatar
            :object="user"
            :image="user.avatar"
            :is-user="true"
            :level-partner="user.level_partner"
            :partner-width="44"
            :partner-hight="44"
            :size="user.level_partner ? 'md-mini' : 'md'"
            :class="user.level_partner ? '' : 'mr-05'"
        />

        <div class="post-feed-item__info ml-05">
            <div class="post-meta--inline">
                <UserInline
                    :user="user"
                    :show-avatar="false"
                    :show-username="false"
                />

                <div v-if="hasOrganization && showOrganization">
                    <OrganizationInlinePopover
                        v-if="isSubmitPending"
                        :organization="post.organization"
                        class="mr-05"
                        prefix="pending in "
                    />
                    <OrganizationInlinePopover
                        v-else
                        :organization="post.organization"
                        class="mr-05"
                    />
                </div>

                <PostMeta :post="post" />

                <slot name="menu" />

                <span v-if="pinnedPost" class="pinned-icon">
                    <i class="fa fa-thumb-tack" aria-hidden="true" />
                </span>
            </div>

            <PostTitleInline
                :url="url"
                :title="post.title"
                :tags="tags"
                :is-video="post.is_video"
            />

            <MarkdownPure v-if="showPreview" :contents="post.contents_short" />

            <div v-if="!post.system" class="d-flex footer-post-item">
                <Stats :stats="stats" />
                <div class="comment-vote-count">
                    <div class="d-flex">
                        <Commentators
                            v-if="post.commentators.data.length > 0"
                            :users="post.commentators.data"
                            class="commentators"
                            :limit="2"
                        />

                        <span
                            v-if="hasRole('social-media-editor') || isAdmin"
                            class="d-inline-block ml-05"
                        >
                            <i
                                v-if="post.social_shares"
                                :class="{
                                    'text-primary text-bold': post.is_shared && post.social_shares[0].user_id !== null,
                                    'text-danger text-bold': post.is_shared && post.social_shares[0].user_id === null,
                                    'el-icon-share': true
                                }"
                            />
                            <i
                                v-else
                                :class="{
                                    'text-primary text-bold': post.is_shared,
                                    'el-icon-share': true
                                }"
                            />

                        </span>

                        <el-dropdown
                            v-if="hasRole('social-media-editor') || isAdmin && post.is_shared"
                            size="medium"
                            placement="bottom"
                            trigger="click"
                            class="ml-05"
                        >
                            <i class="el-icon-arrow-down text-muted cursor-pointer" />

                            <el-dropdown-menu>
                                <el-dropdown-item @click.native="openConfirmDelete">
                                    <span
                                        class="link link-plain"
                                    >
                                        {{ $t('cancelScheduled.title') }}
                                    </span>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <Score :score="post.points" :rated="post.rated_value" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import _isEmpty from 'lodash/isEmpty'
    import _get from 'lodash/get'
    import { destroy } from '~/api/social-shares'
    import { post as toPost } from '~/utils/url'
    import Stats from '~/components/shared/Stats.vue'
    import Avatar from '../../components/shared/Avatar/Avatar.vue'
    import UserInline from '~/components/shared/user/Inline.vue'
    import OrganizationInlinePopover from '~/components/shared/organization/InlinePopover.vue'
    import PostTitleInline from '~/components/posts/widgets/TitleInline.vue'
    import PostMeta from '~/components/posts/widgets/Meta.vue'
    import Score from '~/components/widgets/Score.vue'
    import MarkdownPure from '~/components/contents/MarkdownPure.vue'
    import Commentators from '~/components/shared/user/Participants.vue'

    export default {
        components: {
            Avatar,
            UserInline,
            OrganizationInlinePopover,
            PostMeta,
            Stats,
            PostTitleInline,
            Score,
            Commentators,
            MarkdownPure,
        },

        props: {
            post: {
                type: Object,
                required: true,
            },

            showPreview: Boolean,

            highlight: Boolean,

            showOrganization: {
                type: Boolean,
                default: true,
            },

            pinnedPost: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            ...mapGetters('auth', ['hasRole', 'isAdmin']),

            url() {
                return toPost(this.post)
            },

            user() {
                const user = _get(this.post, 'user.data')

                return typeof user === 'object' && !!user
                    ? user
                    : this.$store.getters['entities/users/get'](user || this.post.user_id)
            },

            stats() {
                return [
                    {
                        name: this.$t('question.views').concat(`${this.post.views_count}`),
                        icon: 'fa fa-eye',
                        value: this.post.views_count,
                    },
                    {
                        name: this.$t('userInline.clips').concat(`${this.post.clips_count}`),
                        icon: 'fa fa-bookmark',
                        value: this.post.clips_count,
                    },
                    {
                        name: this.$t('question.comments').concat(`${this.post.comments_count}`),
                        icon: 'fa fa-comments',
                        value: this.post.comments_count,
                    },
                ]
            },

            tags() {
                return {
                    tags: this.post.tags ? this.post.tags.data : null,
                    promoted: this.post.promoted,
                    trending: this.post.trending,
                    system: this.post.system_tag,
                }
            },

            hasOrganization() {
                return !_isEmpty(this.post.organization)
            },

            isOwner() {
                return this.post.is_own
            },

            isSubmitPending() {
                if (
                    this.hasOrganization
                    && this.post.submit_status
                    && _isEmpty(this.post.submit_status.data.review_at)
                ) {
                    return true
                }

                return false
            },
        },

        methods: {
            openConfirmDelete() {
                this.$msgbox({
                    showCancelButton: true,
                    confirmButtonText: this.$t('confirm.ok'),
                    cancelButtonText: this.$t('confirm.cancel'),
                    title: this.$t('cancelScheduled.confirm'),
                    message: this.$t('cancelScheduled.content'),
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            this.delete()
                        }
                        done()
                    },
                })
            },

            async delete() {
                try {
                    await destroy(this.post.social_shares[0].id)
                    this.$message.success(this.$t('cancelScheduled.success'))
                } catch (error) {
                    this.$message.error(this.$t('cancelScheduled.error'))
                }
            },
        },
    }
</script>

<style lang="scss">
    @import "../../assets/sass/bootstrap/borders";

    .post-feed-item {
        display: flex;
        align-items: flex-start;
        padding: .5rem;

        &:not(:last-child) {
            border-bottom: 1px solid $base-border-color;
        }

        &__info {
            width: 100%;
        }

        .post-meta--inline {
            display: flex;
            flex-wrap: wrap;
            font-size: .8rem;
            margin-bottom: .25rem;
            align-items: center;
        }

        .commentators {
            .avatar {
                img {
                    width: 1.3rem;
                }
            }
        }

        .pinned-icon {
            margin-left: auto;
            font-size: 1rem;
            color: #5488c7;
            transform: rotate(15deg)
        }

        .el-icon-share {
        color: #9b9b9b;
        }

        .comment-vote-count {
            display: flex;
            flex-grow: 1;
            justify-content: space-between;
        }
    }

    .avt-size-md-mini {
        top: 3px;
        left: 5px;
    }
    @media only screen and (max-width: 380px)  {
        .footer-post-item {
            flex-direction: column;
        }
    }
</style>
