// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="video__player-container">
        <div class="video__player mx-auto embed-responsive embed-responsive-16by9">
            <youtube v-if="provider === 'youtube'" :url="url" />
            <vimeo v-if="provider === 'vimeo'" :url="url" />
        </div>
    </div>
</template>

<script>
    import Vimeo from './video/Vimeo.vue'
    import Youtube from './video/Youtube.vue'

    export default {
        components: {
            Vimeo,
            Youtube,
        },

        props: {
            id: {
                type: String,
                required: true,
            },
            provider: {
                type: String,
                required: true,
            },
            url: {
                type: String,
                required: true,
            },
        },
    }
</script>

<style lang="scss">
    @import '~assets/sass/bootstrap/mixins';

    .video__player-container {
        margin-bottom: 1rem;
        background-color: #000;
    }

    @include media-breakpoint-up(lg) {
        .video__player {
            min-width: 72%;
            max-width: 90%;
        }
    }
</style>
