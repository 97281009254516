// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="ct-chart ct-perfect-fourth posts-tendency" />
</template>
<script>
    import _assign from 'lodash/assign'

    export default {
        props: {
            data: {
                type: Object,
                default: () => {},
                require: true,
            },
            width: {
                type: Number,
                default: 200,
            },
            height: {
                type: Number,
                default: 200,
            },
            defaultOptions: {
                type: Object,
                default: () => {},
            },
        },

        data: () => ({ chart: null }),

        watch: {
            data(newData) {
                this.renderChart(newData, this.defaultOptions)
            },
        },

        mounted() {
            if (process.client) {
                this.renderChart()
            }
        },

        methods: {
            renderChart() {
                if (process.client) {
                    const { data, defaultOptions } = this

                    const Chartist = require('chartist')
                    require('chartist-plugin-tooltips/dist/chartist-plugin-tooltip')

                    const options = _assign({}, defaultOptions, {
                        plugins: [
                            Chartist.plugins.tooltip(),
                        ],
                    })
                    this.chart = new Chartist.Pie(this.$el, data, options)
                    this.chart.update(data)
                }
            },
        },
    }
</script>
<style lang="scss">
    .chartist-tooltip {
        opacity: 0;
        background: rgba(0, 0, 0, 0.8);
        margin: 0 10px 10px 0;
        color: #FFF;
        padding: 5px 10px;
        border-radius: 4px;
        position: absolute;
    }

    .chartist-tooltip.tooltip-show {
        opacity: 1;
        z-index: 100;
    }
</style>
