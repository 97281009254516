// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="sidebar-box">
        <div class="profile-stats">
            <stat-item v-for="(item, index) in stats" :key="index" v-bind="item" />
        </div>
    </div>
</template>

<script>
    import { routeToUser } from '~/lib/functions'
    import formatNumber from '~/filters/formatNumber'
    import StatItem from './StatItem.vue'

    export default {
        name: 'ProfileStats',
        components: { StatItem },
        props: {
            profile: {
                type: Object,
                required: true,
            },
        },
        computed: {
            profileStore() {
                return this.$store.getters['entities/users/get'](this.profile.id)
            },
            stats() {
                const to = page => routeToUser(this.profile, page)
                const totalPageViews = formatNumber(this.profile.total_post_views)

                return [
                    {
                        name: this.$t('profile.profileStats.postViews'),
                        value: totalPageViews,
                        title: this.profile.total_post_views,
                        href: to(),
                    },
                    { name: this.$t('profile.profileStats.reputations'), value: this.profileStore.reputation, href: to('reputations') },
                    { name: this.$t('profile.profileStats.followingTags'), value: this.profile.subscriptions_count.tags, href: to('following-tags') },
                    { name: this.$t('profile.profileStats.followingUsers'), value: this.profile.subscriptions_count.users, href: to('following') },
                    { name: this.$t('profile.profileStats.followers'), value: this.profileStore.followers_count, href: to('followers') },
                    { name: this.$t('profile.profileStats.posts'), value: this.profileStore.posts_count, href: to() },
                    { name: this.$t('profile.profileStats.Clips'), value: this.profile.subscriptions_count.posts, href: to('clips/posts') },
                    { name: this.$t('profile.profileStats.totalQuestions'), value: this.profile.questions_count, href: to('questions') },
                    { name: this.$t('profile.profileStats.totalAnswers'), value: this.profile.answers_count, href: to('answers') },
                ]
            },
        },
    }
</script>
