// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<script lang="js">
    import { humanizeTime, formatTime } from '~/filters/dates'
    import Score from '../../widgets/Score.vue'

    export default {
        functional: true,

        props: {
            className: [String, Array, Object],
            stats: Object,
            title: Object,
        },

        render: (h, { props }) => {
            const { stats, className } = props
            const classes = 'question-stats text-muted'

            const answersClass = `stats-item mr-md-1 ${stats.solved ? ' solved' : ''}`
            const answersIcon = `fa ${stats.solved ? 'fa-check' : 'fa-reply'}`

            return (
                <div>
                    <div class="mb-05 question-time hidden-md-down">
                        <span class='text-muted' title={formatTime(stats.created_at)} v-tooltip><i class='fa fa-calendar mr-05'></i>{ humanizeTime(stats.created_at) }</span>
                    </div>
                    <div class={[classes, className]}>
                        <span class={answersClass} title={`${props.title.answers}: ${stats.answers_count}`} v-tooltip>
                            <i class={answersIcon} aria-hidden='true'></i> { stats.answers_count}
                        </span>

                        <Score class='stats-item mr-md-1' score={stats.points} rated={stats.rated_value} title={`${props.title.score}: ${stats.points}`}/>

                        <span class='stats-item mr-md-1' title={`${props.title.comments}: ${stats.comments_count}`} v-tooltip>
                             <i class='fa fa-comments' aria-hidden='true'></i> { stats.comments_count }
                        </span>

                        <span class='stats-item' title={`${props.title.views}: ${stats.views_count}`} v-tooltip>
                            <i class='fa fa-eye' aria-hidden='true'></i> { stats.views_count }
                        </span>
                    </div>
                </div>
            )
        },
    }
</script>

<style lang="scss">
    @import "../../../assets/sass/bootstrap/mixins";
    .question-time {
        font-size: 95%;
    }
    .question-stats {
        display: grid !important;
        grid-template-columns: repeat(4, minmax(0, 1fr));

        .stats-item {
            white-space: nowrap;
        }

        .solved {
            color: #2c984f
        }
    }
    .stats-item {
      position: relative;

      span {
        padding-top: 0.1rem;
      }
    }
    @include media-breakpoint-down (sm) {
        .question-stats {
            flex-direction: column;
            align-items: flex-end;
        }
    }

    @media screen and (max-width: 1110px) {
      .question-stats {
            display: flex !important;
            flex-wrap: wrap;
        }
    }
</style>
