// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <section
        :class="type ? 'd-flex exhibition-item user' : 'company-list-item d-flex company-show py-1'"
    >
        <div>
            <Avatar
                :object="organization"
                :image="organization.avatar"
                :level-partner="organization.level_partner"
                :partner-width="65"
                :partner-hight="65"
                class="d-block"
                image-size="avatar"
                :size="organization.level_partner ? 'xl-mini' : 'xl'"
            />
        </div>
        <div :class="type ? 'user-info overflow-hidden item-author' : 'd-flex w-100'">
            <div
                class="d-flex flex-column flex-grow-1 justify-content-between company-info"
                :class="type ? '' : 'mx-1'"
            >
                <div :class="type ? 'full-width name-author' : 'company-item-name word-break'">
                    <nuxt-link :to="toOrganization(organization)" :class="type ? 'link name-user' : ''">
                        {{ organization.name }}
                    </nuxt-link>
                </div>
                <div class="company-status">
                    <span
                        v-tooltip
                        class="mr-1"
                        :title="$t('organization.companyItem.stast.posts').concat(`${organization.posts_count}`)"
                    >
                        <i class="fa fa-pencil" /> {{ organization.posts_count || 0 }}
                    </span>
                    <span
                        v-tooltip
                        class="mr-1"
                        :title="$t('organization.companyItem.stast.followers')
                            .concat(`${organization.followers_count}`)"
                    >
                        <i class="fa fa-user-plus" /> {{ followers_count || 0 }}
                    </span>
                    <span
                        v-tooltip
                        class="mr-1"
                        :title="$t('organization.companyItem.stast.member').concat(`${organization.members_count}`)"
                    >
                        <i class="fa fa-users" /> {{ organization.members_count || 0 }}
                    </span>
                    <span
                        v-tooltip
                        :class="type ? '' : 'mr-1'"
                        :title="`${$t('profile.organizations.view')}: ${organization.postviews_count}`"
                    >
                        <i class="fa fa-eye" /> {{ organization.postviews_count | formatNumber }}
                    </span>
                </div>
            </div>
            <subscribe
                ref="subscribe"
                class="my-auto"
                :send="follow"
                :subscribable="{ type: 'Organization', id: organization.slug }"
            >
                <button
                    :class="{ 'following': followings }"
                    class="btn btn-follow"
                    @click="$refs.subscribe.handle(!followings)"
                >
                    {{ followings ? $t('organization.followings') : $t('organization.follow') }}
                </button>
            </subscribe>
        </div>
    </section>
</template>

<script>
    import { organization as toOrganization } from '~/utils/url'
    import { subscribe, unsubscribe } from '~/api/organizations'
    import Subscribe from '~/components/widgets/Subscribe.vue'
    import Avatar from '~/components/shared/Avatar/Avatar.vue'

    export default {
        components: {
            Avatar,
            Subscribe,
        },

        props: {
            organization: {
                type: Object,
                required: true,
            },
            type: {
                type: Boolean,
            },
        },

        data() {
            return {
                followings: this.organization.following,
                followers_count: this.organization.followers_count,
            }
        },

        methods: {
            toOrganization,

            follow(value) {
                try {
                    const slug = this.organization.slug
                    this.followings = value

                    if (value) {
                        this.followers_count += 1

                        return value ? subscribe(slug) : unsubscribe(slug)
                    }
                    this.followers_count -= 1

                    return value ? subscribe(slug) : unsubscribe(slug)
                } catch (err) {
                    throw err
                }
            },
        },
    }
</script>

<style lang="scss">
    .name-author {
        font-weight: bolder;
    }

    .company-status {
        display: flex;
        align-items: center;
        line-height: 22px;
        color: #9b9b9b;
    }

    .avt-orglevel-partner {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

</style>
