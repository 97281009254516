// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="ct-chart ct-perfect-fifth reputation-chart" />
</template>
<script>
    import _assign from 'lodash/assign'

    export default {
        props: {
            data: {
                type: Object,
                default: () => {},
            },
        },

        data: () => ({
            chart: null,
            defaultOptions: {
                axisY: {
                    onlyInteger: true,
                },
                axisX: {
                    labelInterpolationFnc(value, index) {
                        return index % 4 === 0 ? value : null
                    },
                },
                showArea: true,
            },
        }),

        watch: {
            data(newData) {
                this.renderChart(newData, this.defaultOptions)
            },
        },

        mounted() {
            if (process.client) {
                this.renderChart()
            }
        },

        methods: {
            renderChart() {
                const { data, defaultOptions } = this

                const Chartist = require('chartist')

                require('chartist-plugin-tooltips/dist/chartist-plugin-tooltip')

                const options = _assign({}, defaultOptions, {
                    plugins: [
                        Chartist.plugins.tooltip(),
                    ],
                })

                if (!this.chart) {
                    this.chart = new Chartist.Line('.reputation-chart', data, options)
                } else {
                    this.chart.update(data)
                }
            },
        },
    }
</script>

<style lang="scss">
    .ct-grid {
        stroke: rgba(181, 181, 181, 0.33);
    }

    .theme-dark {
        .ct-label {
            fill: #fff;
            color: #fff;
        }
    }
</style>
