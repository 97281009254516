// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="vote">
        <slot :vote="handle" :processing="state.processing" />
    </div>
</template>

<script>
    import _get from 'lodash/get'
    import { mapGetters, mapActions } from 'vuex'
    import processRequest from '~/components/mixins/processRequest'

    export default {
        mixins: [processRequest],

        props: {
            rateable: Object,
            organization: Object,
            send: {
                type: Function,
                required: true,
            },
        },

        computed: {
            ...mapGetters('auth', ['authenticated']),
        },

        methods: {
            ...mapActions('auth', ['showAuthDialog']),

            handle(value) {
                return !this.authenticated
                    ? this.showAuthDialog()
                    : this.sendVote(value).catch(this.showErrorMessage)
            },

            async sendVote(value) {
                if (this.state.processing) {
                    return
                }

                try {
                    await this.processRequest(this.send)(value)
                    this.sendGaEvent()
                } catch (error) {
                    this.$message.error('Something went wrong. Please try again later.')

                    if (!error.response) {
                        throw error
                    }
                }
            },

            showErrorMessage(error) {
                const reason = _get(error, 'response.data.reason')

                if (reason) {
                    this.$message.warning(reason)
                } else {
                    this.$message.error('Something went wrong while trying to send your vote')
                    throw error
                }
            },

            sendGaEvent(value) {
                if (this.rateable) {
                    const organizationTrackingID = _get(this.organization, 'google_analytics_id')
                    const analyticsData = {
                        eventCategory: 'Interaction',
                        eventAction: `Rate ${this.rateable.type}`,
                        eventLabel: this.rateable.id,
                        eventValue: value,
                    }

                    this.$gtag.event('interaction', {
                        sent_to: [
                            process.env.GOOGLE_ANALYTICS_TRACK_ID,
                            organizationTrackingID,
                        ],
                        items: analyticsData,
                    })
                }
            },
        },
    }
</script>
