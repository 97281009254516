// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<script>
    import PostTags from '~/components/posts/widgets/Tags.vue'

    export default {
        functional: true,

        props: {
            url: {
                type: String,
                required: true,
            },

            title: {
                type: String,
                required: true,
            },

            tags: {
                type: Object,
                default: () => ({}),
            },

            isVideo: Boolean,
        },

        render: (h, { props }) => (
            <div class='post-title--inline'>
                { props.isVideo
                    ? <i class="fa fa-play-circle text-success" aria-hidden="true"/>
                    : null
                }

                <h3 class='word-break mr-05'>
                    <nuxt-link to={props.url} class='link'>{ props.title }</nuxt-link>
                </h3>

                <PostTags { ...{ props: props.tags } }/>
            </div>
        ),
    }
</script>

<style lang="scss">
    .post-title--inline {
        display: flex;
        flex-wrap: wrap;

        h3 {
            font-size: 1.1rem;
        }

        .fa-play-circle {
            font-size: 1rem;
            margin-right: .2rem;
        }
    }
</style>
