// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="list-mix">
        <empty v-if="!state && !isNull && data.length == 0">
            <p class="empty-mix">
                {{ $t("empty") }}
            </p>
        </empty>
        <div v-else-if="!isNull && data.length > 0">
            <div class="row">
                <div
                    v-for="(item, index) in data"
                    :key="index"
                    :class="
                        type == 'series'
                            ? 'col-sm-12'
                            : 'col-lg-4 col-md-6 col-sm-12 mb-2'"
                >
                    <post-feed-item v-if="type == 'posts'" :post="item" />
                    <post-feed-item
                        v-if="type == 'series'"
                        :post="item"
                        :show-organization="false"
                        layout="horizontal"
                        class="serie-item"
                        is-serie
                    />
                    <question-feed-item
                        v-if="type == 'questions'"
                        :question="item"
                    />
                </div>
            </div>
        </div>
        <PostFeedPlaceholder v-else />
    </div>
</template>

<script>
    import _findIndex from 'lodash/findIndex'
    import { mapActions } from 'vuex'
    import Empty from '../shared/Empty.vue'
    import PostFeedItem from '~/components/subdomains-theme-2/posts/PostFeedItem.vue'
    import QuestionFeedItem from './feeds/QuestionItemIndex.vue'
    import PostFeedPlaceholder from '~/containers/posts/PostFeedPlaceholder.vue'

    export default {
        components: {
            Empty,
            PostFeedItem,
            QuestionFeedItem,
            PostFeedPlaceholder,
        },
        props: {
            type: {
                type: String,
                default: 'post',
            },
            data: Array,
            pagination: Object,
            state: Boolean,
            loading: Boolean,
        },

        provide() {
            return {
                actions: {
                    destroy: this.doDestroy,
                },
            }
        },

        computed: {
            isNull() {
                return this.data === null
            },
        },

        methods: {
            ...mapActions('entities/questions', ['destroy']),

            doDestroy(data) {
                return this.destroy(data).then(() => {
                    const index = _findIndex(this.data, { hash_id: data.hash_id })
                    if (index !== -1) {
                        this.data.splice(index, 1)
                    }

                    if (this.questionsCount !== 1) {
                        this.$emit('request-reload')
                    } else {
                        const page = this.$route.query.page
                            ? this.$route.query.page
                            : 1
                        const prevPage = page <= 1 ? 1 : page - 1
                        this.$router.push(
                            `/u/${this.$route.params.user}/questions?page=${prevPage}`
                        )
                    }
                })
            },
        },
    }
</script>

<style lang="scss" scoped>
    .column-feed {
        margin-top: 30px
    }

    .theme-dark {
        .list-mix {
            .empty-mix {
                color: #fff;
            }
        }
    }
</style>
