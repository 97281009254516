// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<script>
    import twemoji from 'twemoji'

    export default {
        functional: true,

        props: {
            suggestion: Object,
        },

        render: (h, { props, $style }) => (
            <div>
                <div
                    class={['d-inline-block mr-05', $style.icon]}
                    domPropsInnerHTML={twemoji.parse(props.suggestion.data)}
                />
                <strong>{ props.suggestion.completion }</strong>
            </div>
        ),
    }
</script>

<style module lang="scss">
    .icon {
        min-width: 1.25rem;
        min-height: 1.25rem;

        :global(.emoji) {
            padding: 0;
        }
    }
</style>
