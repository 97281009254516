<template>
    <el-dropdown
        class="w-100 h-100 select-box"
        trigger="click"
    >
        <div
            class="h-100 btn-post-pin"
            :class="!isChange ? position === 0 ? 'btn-post-pin-first' : 'btn-post-pin-small' : ''"
        >
            <i v-if="isLoading === position" class="fa fa-circle-o-notch fa-spin sb-icon" aria-hidden="true" />
            <img v-else-if="isChange" src="/images/change-icon.svg" alt="">
            <i v-else-if="position === 0" class="el-icon-circle-plus avatar-uploader-icon" aria-hidden="true" />
            <i v-else class="el-icon-postcard avatar-uploader-icon" aria-hidden="true" />
        </div>
        <el-dropdown-menu slot="dropdown" class="list-posts-pin">
            <el-dropdown-item
                v-for="post in posts"
                :key="post.id"
                :disabled="postPined(post.id)"
                @click.native="handlePinedPost(post, position)"
            >
                {{ post.title }}
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
    export default {
        props: {
            isLoading: Number,
            position: Number,
            posts: {
                type: Array,
                default: () => [],
            },
            postPined: Function,
            handlePinedPost: Function,
            isChange: {
                type: Boolean,
                default: false,
            },
        },
    }
</script>
