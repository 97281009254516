<template>
    <div class="d-flex search-sort-bar align-items-baseline justify-content-between mb-1">
        <el-dropdown size="medium" trigger="click">
            <span class="cursor-pointer">
                {{ $t('myFollowing.type') }} <b>{{ $t(activeType.label) }}</b>
            </span>

            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                    v-for="(entityType, key) in entityTypes"
                    :key="key"
                    :class="{ 'el-dropdown-menu__item--active': entityType == activeType }"
                    @click.native="getFollowings(entityType)"
                >
                    {{ $t(entityType.label) }}
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>

<script>
    import _filter from 'lodash/filter'


    const entityTypes = [
        {
            label: 'myFollowing.users',
            field: 'authors',
        },
        {
            label: 'myFollowing.organizations',
            field: 'organizations',
        },
    ]

    export default {
        props: {
            isSubdomain: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                entityTypes,
            }
        },

        computed: {
            activeType() {
                const splitPosition = this.isSubdomain ? 2 : 4
                const active = this.$route.path.split('/')[splitPosition] || 'authors'
                return _filter(entityTypes, type => type.field === active)[0]
            },
        },

        methods: {
            _filter,
            getFollowings(entityType) {
                entityType = entityType || this.activeType

                if (this.isSubdomain) {
                    const link = entityType.field === 'organizations'
                        ? '/following/organizations'
                        : '/following'

                    this.$router.push(link)
                } else if (entityType.field === 'authors') {
                    this.$router.push(`/u/${this.$route.params.user}/following`)
                } else {
                    this.$router.push(`/u/${this.$route.params.user}/following/organizations`)
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
.theme-dark {
    .cursor-pointer {
        color: white;
    }
}
</style>
