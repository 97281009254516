// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="vimeo-player">
        <iframe
            :src="url"
            width="100%"
            height="auto"
            frameborder="0"
            allow="fullscreen"
            allowfullscreen
        />
    </div>
</template>

<script>

    export default {
        props: {
            url: {
                type: String,
                required: true,
            },
        },
    }
</script>
