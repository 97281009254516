// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div :class="['feed', `feed-${layout}`, isVideoPin ? 'border-none' : '']">
        <div class="feed-thumbnail">
            <img v-if="isSerie" class="icon-series" src="/images/icon-series.png">
            <div
                v-tooltip
                :title="$t('question.views').concat(`${post.views_count}`)"
                class="icon-view"
            >
                <img src="/images/icon-eye-2.svg">
                <span>{{ post.views_count | formatNumber }}</span>
            </div>
            <Video
                v-if="post.is_video && video"
                :id="video.uuid"
                :provider="video.provider"
                :url="video.url"
            />
            <nuxt-link v-else :to="url">
                <img class="thumbnail" :src="post.thumbnail_url" alt="thumbnail">
            </nuxt-link>
        </div>
        <div class="feed-content">
            <div>
                <div class="feed-info">
                    <div class="feed-author">
                        <div class="feed-author--avatar">
                            <Avatar
                                :object="user"
                                :image="user.avatar"
                                :is-user="true"
                                :level-partner="user.level_partner"
                                :partner-width="isVideoPin ? 55 : 40"
                                :partner-hight="isVideoPin ? 55 : 40"
                                :size="isVideoPin ? 'md' : 'sm'"
                            />
                        </div>
                        <div :class="['feed-author--name', isVideoPin ? 'pin-user-name' : '']">
                            <nuxt-link :to="toUser" class="link">
                                <span class="truncate-1"> {{ user.name }} </span>
                            </nuxt-link>
                            <div v-if="!isVideoPin" class="publish-at">
                                <span v-tooltip :title="post.published_at | format-time">
                                    {{ post.published_at | humanize-time }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="!post.is_video && !isSerie"
                        class="feed-reading-time"
                        :title="`${post.reading_time}`.concat($t('contentMenu.minRead'))"
                    >
                        {{ post.reading_time }}{{ $t('contentMenu.minRead') }}
                    </div>
                </div>
                <div :class="['feed-title truncate-2', isVideoPin ? 'pin-title' : '']">
                    <nuxt-link :to="url" class="link">
                        {{ post.title }}
                    </nuxt-link>
                </div>
                <MarkdownPure
                    v-if="isShowContent"
                    class="feed-desc truncate-2"
                    :contents="isSerie ? convertImgToText(post.contents) : convertImgToText(post.contents_short)"
                />
            </div>
            <div>
                <div v-if="isShowContent" class="feed-tags">
                    <PostTags :tags="tags.tags" />
                </div>
                <div v-if="!post.system" class="feed-stats">
                    <Stats :stats="stats">
                        <span
                            v-if="isSerie"
                            slot="append"
                            v-tooltip
                            :title="this.$t('userInline.posts').concat(`${post.posts_count}`)"
                            :class="['d-flex', 'stats-item', 'mr-1', isVideoPin ? 'white-color' : 'text-muteddd']"
                        >
                            <img src="/images/icon-post-count-stroke.png" class="stats-item__icon">
                            {{ post.posts_count | formatNumber }}
                        </span>
                    </Stats>
                </div>
            </div>
        </div>
        <div v-if="(isOwner && isPostPage && !post.is_video && !isVideoPin) || (isOwner && isVideoPage)">
            <img
                v-if="post.organization_pinned_at || post.profile_pin_position"
                class="pin-icon pinned"
                src="/images/pinned-icon.svg"
                alt="pinned"
                @click="onPined(post)"
            >
            <img
                v-else
                class="pin-icon"
                src="/images/pin-icon.svg"
                alt="pin"
                @click="onPined(post)"
            >
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import _isEmpty from 'lodash/isEmpty'
    import _get from 'lodash/get'
    import { post as toPost, user as toUser } from '~/utils/url'
    import Stats from '~/components/subdomains-theme-2/shared/Stats.vue'
    import Avatar from '~/components/shared/Avatar/Avatar.vue'
    import MarkdownPure from '~/components/contents/MarkdownPure.vue'
    import PostTags from '~/components/posts/widgets/Tags.vue'
    import Video from '~/components/posts/Video.vue'

    export default {
        components: {
            Avatar,
            Stats,
            MarkdownPure,
            PostTags,
            Video,
        },

        props: {
            post: {
                type: Object,
                required: true,
            },

            showPreview: Boolean,

            showOrganization: {
                type: Boolean,
                default: true,
            },

            pinnedPost: {
                type: Boolean,
                default: false,
            },
            isOwner: {
                type: Boolean,
                default: false,
            },
            layout: {
                type: String,
                default: 'vertical',
            },

            isVideoPin: {
                type: Boolean,
                default: false,
            },

            isPostPage: {
                type: Boolean,
                default: false,
            },

            isVideoPage: {
                type: Boolean,
                default: false,
            },

            isSubmission: {
                type: Boolean,
                default: false,
            },

            isSerie: {
                type: Boolean,
                default: false,
            },

            isShowContent: {
                type: Boolean,
                default: true,
            },
            subdomain: String,
            isOrgSubdomain: {
                type: Boolean,
                default: true,
            },
            onPined: {
                type: Function,
                default: () => {},
            },
        },

        computed: {
            ...mapGetters('auth', ['hasRole', 'isAdmin']),

            video() {
                return this.post.video
            },

            toEdit() {
                return this.post.is_video ? `/videos/${this.post.slug}/edit` : `/posts/${this.post.slug}/edit`
            },

            url() {
                return this.isSubmission ? this.toEdit : toPost(this.post)
            },

            toUser() {
                return toUser(this.user)
            },

            user() {
                const user = _get(this.post, 'user.data')

                return typeof user === 'object' && !!user
                    ? user
                    : this.$store.getters['entities/users/get'](user || this.post.user_id)
            },

            stats() {
                return [
                    {
                        name: this.$t('question.votes').concat(`${this.post.points}`),
                        src: '/images/icon-like.svg',
                        value: this.post.points,
                    },
                    {
                        name: this.$t('question.comments').concat(`${this.post.comments_count}`),
                        src: '/images/icon-comment.svg',
                        value: this.post.comments_count,
                    },
                    {
                        name: this.$t('userInline.clips').concat(`${this.post.clips_count}`),
                        src: '/images/icon-bookmark.svg',
                        value: this.post.clips_count,
                    },
                ]
            },

            tags() {
                return {
                    tags: this.post.tags ? this.post.tags.data : null,
                    promoted: this.post.promoted,
                    trending: this.post.trending,
                    system: this.post.system_tag,
                }
            },

            hasOrganization() {
                return !_isEmpty(this.post.organization)
            },

            isSubmitPending() {
                if (
                    this.hasOrganization
                    && this.post.submit_status
                    && _isEmpty(this.post.submit_status.data.review_at)
                ) {
                    return true
                }

                return false
            },


        },

        methods: {
            convertImgToText(content = '') {
                return content.replace(/!\[\]\(.+?\)/g, '[IMG]')
            },
        },
    }
</script>

<style lang="scss">
@import "~/assets/sass/bootstrap/colors";
@import "~/assets/sass/components/posts-view.scss";

.feed {
    position: relative;
    display: flex;
    padding: 16px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);

    &-vertical {
        height: 100%;
        flex-direction: column;

        .feed-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;
        }
    }

    &-horizontal {
        flex-direction: row;
        width: 100%;

        .feed-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 70%;
        }

        .feed-thumbnail {
            width: 30%;
            margin-right: 30px;
        }

        @media screen and (max-width: 768px) {
            .feed-content {
                width: 60%;
            }

            .feed-thumbnail {
                width: 40%;
                margin-right: 15px;
            }
        }

        @media screen and (max-width: 576px) {
            & {
                flex-direction: column;
            }

            .feed-content {
                width: 100%;
            }

            .feed-thumbnail {
                width: 100%;
                margin-right: 0;
            }
        }
    }

    .pin-icon {
        position: absolute;
        top: 4px;
        right: 4px;
        cursor: pointer;
    }

    &-thumbnail {
        position: relative;

        .thumbnail {
            width: 100%;
            margin-bottom: 8px;
            aspect-ratio: 16 / 9;
        }

        .icon-view {
            position: absolute;
            top: 12px;
            right: 12px;
            padding: 0px 8px;
            border-radius: 40px;
            font-size: 12px;
            background: #fff;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
            z-index: 1;

            span {
                vertical-align: middle;
                line-height: normal;
            }
        }

        .icon-series{
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    &-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;

        .feed-reading-time {
            font-size: 12px;
        }
    }

    &-author {
        display: flex;
        align-items: center;

        &--name {
            display: flex;
            flex-direction: column;
            gap: 4px;
            margin-left: 4px;
            font-size: 14px;
            font-weight: 700;
            color: #000;
            line-height: normal;

            a.link {
                display: grid;
            }

            &:hover {
                color: #5488c7
            }

        }

        .publish-at {
            font-size: 12px;
            font-weight: 500;
            color: $text-secondary;
            line-height: normal;
        }
    }

    &-reading-time {
        flex-shrink: 0;
        font-size: 10px;
        color: $text-secondary;
        line-height: normal;
        padding-left: 8px;
    }

    &-title {
        margin-bottom: 8px;
        font-size: 18px;
        font-weight: 700;
        color: $post-title;
        line-height: 20px;

        &:hover {
            color: #5488c7
        }
    }

    &-desc {
        margin-bottom: 8px;
        font-size: 14px;
        color: #707070;
        line-height: 17px;
    }

    &-tags {
        margin-bottom: 8px;

        .tags {
            row-gap: 8px;
        }
    }

    &-stats {
        .white-color {
            color: #fff !important;
        }
    }

    .pin-title {
        font-size: 32px;
        line-height: 1.5;
        color: white;

        a.link:hover, &:hover {
            color: rgba(255,255,255, 0.8)
        }

        @media screen and (max-width: 768px) {
            font-size: 24px;
        }

        @media screen and (max-width: 576px) {
            font-size: 20px;
        }
    }

    .pin-user-name {
        font-size: 28px;
        font-weight: 500;
        color: white;

        a.link:hover, &:hover {
            color: rgba(255,255,255, 0.8)
        }

        @media screen and (max-width: 768px) {
            font-size: 20px;
        }

        @media screen and (max-width: 576px) {
            font-size: 16px;
        }
    }
}

.theme-dark {
    .feed {
        border: 1px solid #585858;
        background: #282828;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);

        &-author {
            &--name {
                color: #fff;
            }

            .publish-at {
                color: #939393;
            }
        }

        &-title {
            color: #fff;
        }

        &-desc {
            color: #cccccc;
        }

        &-reading-time {
            color: #939393;
        }

        &-thumbnail {
            .icon-view {
                color: #000;
            }
        }
    }

    .border-none {
        border: none !important;
    }
}
</style>
