// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="score">
        <button
            v-tooltip
            :class="[classNameFor('up'), isOwn ? 'not-allowed' : '']"
            :title="disabledTitleOr($t('activities.vote.upvote'))"
            class="icon-btn vote"
            @click.prevent="handleClick('up')"
        >
            <i :class="`fa fa-chevron-up`" aria-hidden="true" />
        </button>

        <span :class="['comment-points', value != 0 ? 'text-primary text-bold' : 'text-muted']">
            {{ score | signed }}
        </span>

        <button
            v-tooltip
            :class="[classNameFor('down'), isOwn ? 'not-allowed' : '']"
            :title="disabledTitleOr($t('activities.vote.downVote'))"
            class="icon-btn vote"
            @click.prevent="handleClick('down')"
        >
            <i :class="`fa fa-chevron-down`" aria-hidden="true" />
        </button>
    </div>
</template>

<script>
    import Vote from '../widgets/vote/Vote.vue'

    export default {
        mixins: [Vote],

        props: {
            value: [Number, String],
            score: [Number, String],
            disabled: Boolean,
            isOwn: Boolean,
            timeBetween: {
                type: Number,
                default: 10,
            },
            limit: {
                type: Number,
                default: 10,
            },
        },

        methods: {
            handleClick(value) {
                if (!this.disabled) {
                    this.handle(value)
                }
            },

            classNameFor(value) {
                return !this.disabled ? this.status(value) : ['disabled']
            },

            disabledTitleOr(title) {
                if (!this.disabled) {
                    return title
                } if (this.isOwn) {
                    return this.$t('activities.vote.comment.cannotVote')
                }
                return this.$t('activities.vote.needReputationToVote')
            },

            status(score) {
                switch (score) {
                    case 'up':
                        return { voted: this.value > 0, disabled: this.value < 0 }
                    case 'down':
                        return { voted: this.value < 0, disabled: this.value > 0 }
                    default:
                        return null
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/sass/components/vote";
</style>
