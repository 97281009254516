// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <v-dialog
        ref="dialog"
        :show.sync="show"
        :title="$t('postBody.postContent.dialogReport.title')"
        size="small"
        @close="reset"
    >
        <el-form
            ref="form"
            :model="form"
            :rules="rules"
            label-position="top"
            size="mini"
        >
            <el-form-item prop="reason" :label="$t('postBody.postContent.dialogReport.titleForUser')">
                <el-radio-group v-model="form.reason" class="mb-05">
                    <el-radio label="spam" name="reason" class="d-block mb-05">
                        {{ $t('postBody.postContent.dialogReport.spam') }}
                    </el-radio>
                    <el-radio label="tos_violation" name="reason" class="d-block ml-0 mb-05">
                        {{ $t('postBody.postContent.dialogReport.rulesViolation') }}
                    </el-radio>
                    <el-radio label="harashment" name="reason" class="d-block ml-0 mb-05">
                        {{ $t('postBody.postContent.dialogReport.harassment') }}
                    </el-radio>
                    <el-radio label="infringes_copyright" name="reason" class="d-block ml-0 mb-05">
                        {{ $t('postBody.postContent.dialogReport.infringesCopyright') }}
                    </el-radio>
                    <el-radio label="poor_translation" name="reason" class="d-block ml-0">
                        {{ $t('postBody.postContent.dialogReport.poorTranslation') }}
                    </el-radio>
                </el-radio-group>
            </el-form-item>

            <el-form-item prop="source">
                <el-input
                    v-model="form.source"
                    :autosize="{ minRows: 3 }"
                    type="textarea"
                    :placeholder="$t('postBody.postContent.dialogReport.placeholder')"
                />
            </el-form-item>
        </el-form>

        <div class="d-flex align-items-center">
            <el-button
                :loading="processing"
                size="small"
                class="ml-auto"
                @click="submit($refs.form, report)"
            >
                {{ $t('postBody.postContent.dialogReport.delete') }}
            </el-button>
        </div>
    </v-dialog>
</template>

<script>
    import formMixin from '~/utils/form/mixin'

    export default {
        mixins: [formMixin],

        props: {
            deleteAndStoreReason: {
                type: Function,
                required: true,
            },
            target: {
                type: Object,
                required: true,
            },
        },

        data: vm => ({
            rules: {
                reason: { required: true, message: vm.$t('postBody.postContent.dialogReport.requireReason') },
            },
            show: false,
            reported: false,
            processing: false,
            form: {
                reason: null,
                source: null,
            },
        }),

        methods: {
            open() {
                this.show = true
            },

            close() {
                this.show = false
            },

            async report() {
                try {
                    this.processing = true
                    await this.deleteAndStoreReason(this.target, this.form)
                    this.close()
                    this.reported = true
                    this.$message(this.$t('postBody.postContent.dialogReport.successDeleted'))
                } catch (error) {
                    this.close()
                    this.$message.error(this.$t('postBody.postContent.dialogReport.errorDeleted'))
                    throw error
                } finally {
                    this.processing = false
                }
            },

            reset() {
                this.$refs.form.resetFields()
            },
        },
    }
</script>
