// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <span
        class="post-reading_time"
        :title="`${readingTime}`.concat($t('contentMenu.minRead'))"
    >
        {{ readingTime }}{{ $t('contentMenu.minRead') }}
    </span>
</template>

<script>
    export default {
        props: {
            readingTime: {
                type: Number,
                default: 1,
            },
        },
    }
</script>

<style lang="scss">
    .post-reading_time {
        font-weight: 400;
        &.is-divide:before {
            content: '-';
            margin-right: 0.5rem;
        }
        &.is-divide--fit:before {
            margin-right: unset;
        }
    }
</style>
