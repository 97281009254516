// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="pt-2 pb-2">
        <Contact />
    </div>
</template>

<script>
    import Contact from '~/components/subdomains-theme-2/contacts/Contact.vue'
    import { handlePageError, getContentSubDomain } from '~/utils/pages'

    export default {
        name: 'ContactUser',

        components: {
            Contact,
        },

        async fetch({
            store, params, error, req,
        }) {
            try {
                const host = process.client ? window.location.host : req.headers.host
                const user = store.state.config.vars.is_special_profile ? getContentSubDomain(host) : params.user

                return await store.dispatch('profile/contact/fetch', user)
            } catch (e) {
                return handlePageError(error, e)
            }
        },
    }
</script>
