// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
function PopupShare(url, title, w, h) {
    // Fixes dual-screen position
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY

    // eslint-disable-next-line no-nested-ternary
    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth
    // eslint-disable-next-line no-nested-ternary
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight

    const systemZoom = width / window.screen.availWidth
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    const newWindow = window.open(url, title, `scrollbars=yes, width=${w / systemZoom}, height=${h / systemZoom}, top=${top}, left=${left}`)

    // Puts focus on the newWindow
    if (window.focus) newWindow.focus()
}
export { PopupShare }
