// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="post-layout-switcher">
        <div id="v-step-3" :class="{'hightlight-step3' : step3}">
            <div :class="{ 'pulse add-padding' : step3 }">
                <a
                    v-tooltip
                    :class="layout == 'simple' ? 'active' : ''"
                    class="link"
                    :title="$t('profile.layoutSwitcher.simple')"
                    @click.prevent="setLayout('simple')"
                >
                    <i class="fa fa-th-list" aria-hidden="true" />
                </a>

                <a
                    v-tooltip
                    :class="layout == 'preview' ? 'active' : ''"
                    class="link mode-preview"
                    :title="$t('profile.layoutSwitcher.preview')"
                    @click.prevent="setLayout('preview')"
                >
                    <i class="fa fa-newspaper-o" aria-hidden="true" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'

    export default {
        computed: {
            ...mapState('settings', [
                'layout',
            ]),
            ...mapState('vueTour', ['step3']),
        },

        methods: {
            ...mapActions('settings', [
                'setLayout',
            ]),
        },
    }
</script>

<style lang="scss">
    @import "../../../assets/sass/bootstrap/colors";

    .post-layout-switcher {
        display: flex;
        justify-content: flex-end;

        .fa {
            font-size: 1.2rem;
        }

        .hightlight-step3 {
            z-index: 3000;
            background-color: white;
            padding: 5px;
            margin: 5px;
            border-radius: 4px;
            .add-padding{
                padding-top: 8px
            }
        }

        a {
            cursor: pointer;
            margin-left: 1rem;

            &.active {
                color: $primary;
            }
            &:hover {
                color: $secondary;
            }
        }

        .mode-preview {
            margin-right: 1rem;
        }
    }
</style>
