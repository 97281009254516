<template>
    <div>
        <i class="el-icon-medal text-muted" />
        <i>{{ $t(descriptionLevelPartner) }}</i>
    </div>
</template>

<script>
    export default {
        props: {
            level: {
                type: String,
                require: true,
            },
        },
        computed: {
            descriptionLevelPartner() {
                switch (this.level) {
                    case 'gold':
                        return 'organization.level.goldDescription'
                    case 'diamond':
                        return 'organization.level.diamondDescription'
                    case 'silver':
                        return 'organization.level.silverDescription'
                    default:
                        return null
                }
            },
        },
    }
</script>
