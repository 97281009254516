// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<script>
    export default {
        functional: true,
        render: (h, { props }) => {
            const to = props.href || props.to || 'javascript:void(0);' // eslint-disable-line no-script-url
            const children = [
                h('p', props.name),
                h('p', { attrs: { title: props.title ? props.title : null } }, props.value),
            ]

            return props.native || props.hasOwnProperty('native') // eslint-disable-line no-prototype-builtins
                ? h('a', { class: 'link', attrs: { href: to } }, children)
                : h('nuxt-link', { class: 'link', props: { to } }, children)
        },
    }
</script>
