// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="about">
        <div v-if="isLoading" class="d-flex justify-content-center">
            <button class="loading" />
        </div>
        <div v-else-if="images.length > 0">
            <VueSlickCarousel v-bind="settings">
                <div
                    v-for="(image, index) in images"
                    :key="index"
                    class="gallery__image"
                >
                    <div class="gallery__image___content">
                        <img
                            class="about-image"
                            :src="toImage(image.basename,'full')"
                            alt="thumbnail"
                        >
                    </div>
                    <div v-if="isOwner" class="gallery__image-controls">
                        <a
                            :href="image.path"
                            :download="image.name"
                            class="action mr-05"
                        >
                            <i class="fa fa-download" aria-hidden="true" />
                        </a>
                        <a
                            class="action ml-1"
                            href="#"
                            @click.prevent="() => $refs.confirmDelete.open(image)"
                        >
                            <i class="fa fa-trash" aria-hidden="true" />
                        </a>
                    </div>
                </div>
            </VueSlickCarousel>
        </div>

        <div v-else class="no-images d-flex justify-content-center mb-1">
            {{ $t('empty') }}
        </div>
        <dropzone
            v-if="showUpload"
            ref="dropzone"
            class="mb-1"
            :params="getParams"
            @success="onUploadSucceeded"
            @processing="processingUploadImage"
            @error="errorUploadImage"
        />
        <p
            v-if="isOwner"
            class="button-show-hide-upload mt-1 d-flex justify-content-end"
            @click="handelShowUpload()"
        >
            {{ $t('uploadImage') }}
        </p>
        <ConfirmDeleteImage
            ref="confirmDelete"
            @deleted="(uuid) => removeImage(uuid)"
        />
    </div>
</template>

<script>
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
    import { getImages } from '~/api/organizations'
    import { getImagesProfile } from '~/api/users'
    import { image as toImage } from '~/utils/url'
    import { getSlugSubdomain, getContentSubDomain } from '~/utils/pages'
    import Dropzone from '~/components/Dropzone.vue'
    import ConfirmDeleteImage from '~/components/dialogs/ConfirmDeleteImage.vue'

    export default {
        components: {
            VueSlickCarousel,
            Dropzone,
            ConfirmDeleteImage,
        },

        props: {
            organizationId: {
                type: Number,
                default: null,
            },
            profileId: {
                type: Number,
                default: null,
            },
            isOwner: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                settings: {
                    dots: false,
                    speed: 500,
                    slidesToShow: 3.5,
                    slidesToScroll: 1,
                },
                showUpload: false,
                images: [],
                isLoading: true,
            }
        },

        async mounted() {
            this.isLoading = true
            try {
                const host = process.client ? window.location.host : req.headers.host // eslint-disable-line
                const orgSubdomain = this.$store.state.config.vars.is_special_organization
                    ? getSlugSubdomain(host) : this.$route.params.organization
                const profileSubdomain = this.$store.state.config.vars.is_special_profile
                    ? getContentSubDomain(host) : this.$route.params.user
                const response = this.organizationId
                    ? await getImages(orgSubdomain)
                    : await getImagesProfile(profileSubdomain)

                this.images = response.data
                this.$emit('checkEmptyImages', this.images)
            } catch (e) {
                this.images = []
            } finally {
                this.isLoading = false
            }
        },

        methods: {
            toImage,

            processingUploadImage() {
                this.isLoading = true
            },

            errorUploadImage() {
                this.isLoading = false
            },

            handelShowUpload() {
                this.showUpload = !this.showUpload
            },

            onUploadSucceeded(file, response) {
                this.images = [response.data, ...this.images]
                this.$refs.dropzone.$_dropzone.removeFile(file)
                this.isLoading = false
            },

            getParams() {
                const params = {}
                if (this.organizationId) {
                    params.orgId = this.organizationId
                }
                if (this.profileId) {
                    params.profileId = this.profileId
                }
                return params
            },

            removeImage(uuid) {
                const index = this.images.findIndex(image => image.id === uuid)
                if (index !== -1) {
                    this.images.splice(index, 1)
                }
            },
        },
    }
</script>

<style lang="scss">
@import "~/assets/sass/bootstrap/colors";

.about {
    margin-bottom: 60px;

    &-image {
        width: 100%;
        aspect-ratio: 16 / 9;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
        object-fit: cover;
    }

    .slick-list {
        padding: 0 0 0 15% !important;
    }

    .slick-slider {
        .slick-prev::before {
            font-family: FontAwesome, serif;
            content: "\f053";
            font-size: 22px;
            color: #0EA8FF;
        }

        .slick-next {
            right: -30px;
        }

        .slick-prev {
            left: -22px;
        }

        .slick-next::before {
            font-family: FontAwesome, serif;
            content: "\f054";
            font-size: 22px;
            color: #0EA8FF;
        }
    }

    @media screen and (min-width: 1280px) {
        .about .slick-slider .slick-prev {
           left: -18px;
        }

        .about .slick-slider .slick-next {
            right: -24px;
        }
    }

    .button-show-hide-upload {
        color: $primary;
        cursor: pointer;
    }

    .loading {
        border: 4px solid #f3f3f3;
        border-top: 4px solid gray;
        border-radius: 50%;
        width: 40px !important;
        height: 40px;
        animation: spin 1s linear infinite;
        background: none;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    .gallery__image {
        position: relative;

        .gallery__image___content {
            margin-right: 20px;
            background-color: #282828;
        }
    }

    .gallery__image-controls {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        visibility: hidden;
        transition: visibility 0.2s;
        width: calc(100% - 20px);
        background: rgba(255, 255, 255, 0.5);
    }

    .gallery__image:hover .gallery__image-controls {
        visibility: visible;
    }

}

.theme-dark {
    .about {
        .dz-clickable{
            background-color: #282828;
        }

        .no-images {
            color: #fff;
        }
    }
}
</style>
