// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div>
        <PostPin
            :posts="postPins"
            :is-owner="user.is_self"
            :subdomain="profileSubdomain"
            :is-org-subdomain="false"
        />
        <post-feed store="profile/posts" :is-owner="user.is_self" />
    </div>
</template>

<script>
    import PostFeed from '~/components/subdomains/posts/PostFeed.vue'
    import { handlePageError, getContentSubDomain } from '~/utils/pages'
    import { getPostPinned } from '~/api/users'
    import PostPin from '~/components/subdomains/posts/PostPin.vue'

    export default {
        scrollToTop: true,

        components: {
            PostFeed,
            PostPin,
        },

        props: {
            user: Object,
            profileSubdomain: String,
        },

        async asyncData({
            params, query, store, error, req,
        }) {
            const host = process.client ? window.location.host : req.headers.host

            const postPins = await getPostPinned(
                store.state.config.vars.is_special_profile
                    ? getContentSubDomain(host) : params.user
            )
                .then(({ data }) => data)

            await store.dispatch('profile/posts/fetch', {
                username: store.state.config.vars.is_special_profile ? getContentSubDomain(host) : params.user,
                params: {
                    limit: store.state.settings.perPage,
                    page: query.page,
                },
            }).catch(handlePageError(error))

            return {
                postPins,
            }
        },

        watchQuery: ['page'],
    }
</script>

<style lang="scss">
    .post-feed-org {
        background-color: white;
        border-radius: 0.5rem;
        .title-all-posts {
            font-weight: 500;
            font-size: 30px
        }
        max-width: 836px;
    }

    .theme-dark {
        .post-feed-org {
            background-color: #000;
            .title-all-posts {
                color: white;
            }
        }
    }
</style>
