// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="posts-wrapper pt-2 pb-2">
        <i
            v-if="posts.length <= 0 && isLoading"
            class="fa fa-circle-o-notch fa-spin sb-icon loading"
            aria-hidden="true"
        />
        <div v-else>
            <i
                v-if="isLoading"
                class="fa fa-circle-o-notch fa-spin sb-icon loading"
                aria-hidden="true"
            />
            <PostPin
                v-else
                :posts="postPins"
                :is-owner="user.is_self"
                :subdomain="profileSubdomain"
                :is-org-subdomain="false"
                :on-pined="openConfirmPinOrUnPinPost"
                :on-save-pined-post="handleSavePinedPost"
            />
            <div v-show="!isHiddenAbout">
                <p class="title-about-us">
                    {{ $t('somePicturesAboutUs') }} {{ user.name }}
                </p>
                <About :profile-id="user.id" :is-owner="user.is_self" @checkEmptyImages="onCheckEmptyImages" />
            </div>
            <Badges v-if="badges.length !== 0" :badges="badges" />
            <div>
                <section-title :title="$t('allPosts')" />
                <div class="row">
                    <div v-for="(post, index) in posts" :key="index" class="col-lg-4 col-md-6 col-sm-12 post-item">
                        <PostFeedItem
                            is-post-page
                            :post="post"
                            :is-owner="user.is_self"
                            :on-pined="openConfirmPinOrUnPinPost"
                        />
                    </div>
                </div>
                <client-only>
                    <InfiniteLoading :distance="100" spinner="spiral" @infinite="load">
                        <span slot="no-results" />
                        <span slot="no-more" />
                    </InfiniteLoading>
                </client-only>
            </div>
        </div>
    </div>
</template>

<script>
    import _get from 'lodash/get'
    import _map from 'lodash/map'
    import _isEmpty from 'lodash/isEmpty'
    import _assign from 'lodash/assign'
    import InfiniteLoading from 'vue-infinite-loading'
    import { mapState } from 'vuex'
    import Badges from '~/components/subdomains-theme-2/Badges.vue'
    import PostPin from '~/components/subdomains-theme-2/posts/PostPin.vue'
    import { getPostPinned, pinPostsProfile } from '~/api/users'
    import { handlePageError, getContentSubDomain } from '~/utils/pages'
    import About from '~/components/subdomains-theme-2/shared/About.vue'
    import PostFeedItem from '~/components/subdomains-theme-2/posts/PostFeedItem.vue'
    import SectionTitle from '~/components/subdomains-theme-2/SectionTitle.vue'

    export default {
        scrollToTop: true,

        components: {
            PostPin,
            Badges,
            InfiniteLoading,
            About,
            PostFeedItem,
            SectionTitle,
        },

        props: {
            user: Object,
            profileSubdomain: String,
        },

        data() {
            return {
                postPins: [],
                isLoading: false,
                isHiddenAbout: false,
            }
        },

        computed: {
            ...mapState('profile/posts', ['pagination']),
            ...mapState({
                postIds(state) {
                    return state.profile.posts.current
                },
                posts(state) {
                    const postsFeed = state.profile.posts.current

                    return _map(postsFeed, (id) => {
                        const entity = this.$store.getters['entities/posts/get'](id)
                        const data = _get(this.$store.getters, 'profile/posts/get')(id)

                        return _assign({}, entity, data)
                    })
                },
            }),
            badges() {
                return this.$store.state.profile.profile.badges.data
            },
        },

        watchQuery: ['page'],

        // eslint-disable-next-line consistent-return
        async fetch() {
            this.isLoading = true
            const req = this.$nuxt.context.ssrContext?.req
            const store = this.$nuxt.context.store
            const params = this.$nuxt.context.route.params
            const error = this.$nuxt.context.error
            const page = this.pagination?.current_page ? this.pagination?.current_page + 1 : 1
            const host = process.client ? window.location.host : req.headers.host
            const userSubdomain = store.state.config.vars.is_special_profile ? getContentSubDomain(host) : params.user
            const limit = store.state.settings.perPage - (store.state.settings.perPage % 3)
            try {
                this.postPins = await getPostPinned(userSubdomain).then(({ data }) => data)

                await store.dispatch('profile/posts/fetch', { username: userSubdomain, params: { limit, page, isLoadMore: true } })

                this.isLoading = false
            } catch (err) {
                this.isLoading = false
                return handlePageError(error, err)
            }
        },

        beforeDestroy() {
            this.$store.dispatch('profile/posts/resetData')
        },

        methods: {
            async load($state) {
                if (this.pagination?.current_page < this.pagination?.total_pages) {
                    await this.$fetch()
                    $state.loaded()
                } else {
                    $state.complete()
                }
            },

            onCheckEmptyImages(images) {
                this.isHiddenAbout = _isEmpty(images) && !this.user.is_self
            },

            openConfirmPinOrUnPinPost(post) {
                this.$msgbox({
                    showCancelButton: true,
                    confirmButtonText: this.$t('confirm.ok'),
                    cancelButtonText: this.$t('confirm.cancel'),
                    title: this.$t('cancelScheduled.confirm'),
                    message: post.profile_pin_position ? this.$t('pinPostOrg.confirmPostUnPin') : this.$t('pinPostOrg.confirmPostPin'),
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            this.handlePinOrUnPinPost(post)
                        }
                        done()
                    },
                })
            },

            async handlePinOrUnPinPost(post) {
                try {
                    if (post.profile_pin_position) {
                        const postFiltered = this.postPins.filter(postPin => postPin.id !== post.id)
                        const newPostIds = postFiltered.map(postPin => ({
                            id: postPin.id,
                            position: postPin.profile_pin_position,
                        }))
                        const result = await pinPostsProfile(this.profileSubdomain, { data: newPostIds })
                            .then(({ data }) => data)

                        this.postPins = result.data
                        this.$store.commit('entities/posts/updatePinPosition', { id: post.id, position: null })
                        this.$message.success(this.$t('pinPostOrg.unPinSuccess'))
                    } else {
                        const postIds = this.postPins.map(postPin => ({
                            id: postPin.id,
                            position: postPin.profile_pin_position,
                        }))
                        const baseDate = new Date('2024-07-07')
                        const dateToConvert = new Date()

                        const diffInMilliseconds = dateToConvert - baseDate
                        const diffInTimes = Math.floor(diffInMilliseconds / 1000)

                        const newPostIds = [...postIds, { id: post.id, position: diffInTimes }]
                        this.isLoading = true
                        const result = await pinPostsProfile(this.profileSubdomain, { data: newPostIds })
                            .then(({ data }) => data)

                        this.postPins = result.data
                        this.$store.commit('entities/posts/updatePinPosition', { id: post.id, position: diffInTimes })
                        this.isLoading = false
                        this.$message.success(this.$t('pinPostOrg.pinSuccess'))
                    }
                } catch (error) {
                    this.$message.error(this.$t('postBody.postContent.dialogAddOrganization.error'))
                }
            },

            handleSavePinedPost(pinnedPosts, close) {
                this.$confirm(this.$t('pinPostOrg.confirmPinPost.content'), this.$t('pinPostOrg.confirmPinPost.title'), {
                    confirmButtonText: this.$t('confirm.ok'),
                    cancelButtonText: this.$t('confirm.cancel'),
                })
                    .then(async () => {
                        try {
                            const postIds = pinnedPosts.map(pin => ({
                                id: pin.id,
                                position: pin.profile_pin_position,
                            }))
                            const result = await pinPostsProfile(this.profileSubdomain, { data: postIds })
                                .then(({ data }) => data)
                            this.postPins = result.data
                            this.posts.forEach((postItem) => {
                                if (result.data.every(postPin => postPin.id !== postItem.id)) {
                                    return this.$store.commit('entities/posts/updatePinPosition', { id: postItem.id, position: null })
                                }
                                return this.$store.commit('entities/posts/updatePinPosition', { id: postItem.id, position: postItem.profile_pin_position })
                            })
                            close()
                            this.$message.success(this.$t('pinPostOrg.pinSuccess'))
                        } catch {
                            this.$message.error(this.$t('postBody.postContent.dialogAddOrganization.error'))
                        }
                    })
            },
        },
    }
</script>

<style lang="scss">
    .post-feed-org {
        background-color: white;
        border-radius: 0.5rem;
        .title-all-posts {
            font-weight: 500;
            font-size: 30px
        }
        max-width: 836px;
    }

    .title-about-us {
        font-weight: 700;
        font-size: 30px;
        font-family: 'RobotoCondensed Medium', sans-serif;

        @media screen and (max-width: 768px) {
            font-size: 24px;
        }

        @media screen and (max-width: 500px) {
            font-size: 20px;
        }
    }

    .loading {
        position: unset;
        width: 100%;
        text-align: center;
        font-size: 24px;
    }
</style>

<style scoped lang="scss">
.posts-wrapper {
    .post-item {
        margin-bottom: 30px;
    }

    .title-about-us {
        margin-top: 16px;
    }

    @media screen and (max-width: 1280px) {
        padding: 0 32px;
    }
}
</style>

<style lang="scss">
.posts-wrapper {
  .section-title-line {
    margin: 1rem 0;
    h4 {
      font-size: 30px !important;
      font-weight: 700 I !important;
      font-weight: 700;

      @media screen and (max-width: 768px) {
          font-size: 24px;
      }

      @media screen and (max-width: 500px) {
          font-size: 20px;
      }
    }
  }
}

.theme-dark {
    .posts-wrapper {
        .title-about-us, .section-title-line {
            color: #fff;
        }
    }
}
</style>
