// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <diV>
        <SectionTitle :title="$t('profile.organizations.title')" />

        <el-alert
            v-if="!organizations.length"
            :closable="false"
            class="mb-1"
            type="info"
            title
        >
            <span class="mr-05">{{ $t('profile.organizations.noOrganization') }}</span>

            <el-button
                v-if="isSelf"
                type="text"
                @click="redirectToOrganizationCreatePage()"
            >
                {{ $t('profile.organizations.createOrgaization') }}
            </el-button>
        </el-alert>

        <organization-sidebar
            v-for="organization in organizations"
            v-else
            :key="organization.id"
            :organization="organization"
            :stats="['posts', 'members', 'followers']"
        />
    </diV>
</template>


<script>
    import SectionTitle from './SectionTitle.vue'
    import OrganizationSidebar from '~/components/sidebar/OrganizationSidebar.vue'

    export default {
        components: {
            SectionTitle,
            OrganizationSidebar,
        },

        props: {
            isSelf: Boolean,

            organizations: {
                type: Array,
                default: () => [],
            },
        },

        methods: {
            redirectToOrganizationCreatePage() {
                this.$router.push('/me/organizations/create')
            },
        },
    }
</script>
