// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div>
        <i
            v-if="loading"
            class="fa fa-circle-o-notch fa-spin sb-icon loading"
            aria-hidden="true"
        />

        <comments-thread
            v-else
            v-jump-to-route-hash
            :commentable="commentable"
            store="profile/contact"
        />
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import { CommentableType } from 'viblo-sdk/libs/interactions'
    import CommentsThread from '~/components/subdomains-theme-2/contacts/Thread/Index.vue'

    export default {
        components: {
            CommentsThread,
        },
        computed: {
            ...mapState('profile', {
                loading(state) {
                    return state.contact.loading
                },

                commentable(state) {
                    return {
                        type: CommentableType.User,
                        hashId: state.profile.username,
                        id: state.profile.id,
                    }
                },
            }),
        },
    }
</script>

<style scoped>
.loading {
    position: unset;
    width: 100%;
    text-align: center;
    font-size: 24px;
}
</style>
