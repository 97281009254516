// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <el-dropdown size="medium" placement="bottom" trigger="click">
        <i class="el-icon-arrow-down text-muted cursor-pointer" />

        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
                <nuxt-link :to="`/questions/${questionHashId}/edit`" class="link link--plain">
                    {{ $t('myContent.series.action.edit') }}
                </nuxt-link>
            </el-dropdown-item>

            <el-dropdown-item @click.native="$listeners.clickDelete">
                {{ $t('myContent.series.action.delete') }}
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
    export default {
        props: {
            questionHashId: {
                type: String,
                required: true,
            },
        },
    }
</script>
