// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<script>
    import _pick from 'lodash/pick'
    import _merge from 'lodash/merge'
    import MessageBox from 'element-ui/lib/message-box'
    import { routeToQuestion } from '~/lib/functions'
    import Menu from './Menu.vue'
    import Stats from './Stats.vue'
    import Tags from '../widgets/Tags.vue'
    import Participants from '../../shared/user/Participants.vue'
    import UserInline from '~/components/shared/user/Inline.vue'

    const render = (h, question, className, actions, highlight, statsTitle) => {
        className = _merge({ 'question-item': true, 'bg-highlight': highlight }, className)
        const user = question.user.data
        const stats = _pick(question, [
            'solved', 'answers_count', 'points', 'rated_value', 'comments_count', 'views_count', 'created_at', 'subscribed_at',
        ])
        const title = _pick(statsTitle, ['answers', 'comments', 'views', 'score', 'ok', 'cancel', 'titleDelete', 'contentDelete'])

        const contributors = question.contributors && question.contributors.data.length > 0
            ? (
                <div class='hidden-md-down contributors-item'>
                    <i class='fa fa-reply text-muted mr-05'></i>
                    <Participants users={question.contributors.data} class='contributors-item' />
                </div>
            ) : null

        const handleDelete = confirmDelete.bind(null, question, actions.destroy, title)
        const menu = question.is_own
            ? <Menu questionHashId={question.hash_id} onClickDelete={handleDelete} className='ml-auto'/>
            : null

        return (
            <div class={className}>
                <div class='stats'>
                    <Stats stats={stats} title={title}/>
                </div>
                <div class='summary'>

                    <div class='asked-by d-flex align-items-center'>
                        <div class="d-flex w-100">
                            <UserInline user={user} show-username={false} />

                            {contributors}
                        </div>

                        {menu}
                    </div>

                    <div class='q-title word-break'>
                        <nuxt-link to={routeToQuestion(question)} class='link'>
                            <h3 class='mb-05 mr-05'>{question.title}</h3>
                        </nuxt-link>
                        <Tags tags={question.tags.data}/>
                    </div>
                </div>
            </div>
        )
    }

    function confirmDelete(question, doDelete, title) {
        if (typeof doDelete !== 'function') return
        MessageBox.confirm(title.contentDelete, title.titleDelete, {
            confirmButtonText: title.ok,
            cancelButtonText: title.cancel,
        })
            .then(() => doDelete(question))
    }

    export default {
        functional: true,

        props: {
            question: Object,
            className: {
                type: Object,
                default: () => ({}),
            },
            highlight: Boolean,
            statsTitle: Object,
        },

        inject: ['actions'],

        render: (h, { props, injections }) => render(
            h,
            props.question,
            props.className,
            injections.actions || {},
            props.highlight,
            props.statsTitle
        ),
    }
</script>

<style lang="scss">
    @import "../../../assets/sass/bootstrap/colors";
    @import "../../../assets/sass/bootstrap/mixins";

    .new {
        background-color: rgba(75,205,159,.15);
    }

    .question-item {
        display: flex;
        align-items: flex-start;
        padding: .65rem 0;
        border-bottom: 1px solid $gray-lighter;

        .stats {
            padding-left: .5rem;
            padding-right: 2rem;
            width: 26%;

            .stats-item {
                cursor: default;
            }
        }

        .contributors {
            display: flex;
            align-items: center;
            overflow: hidden;
            position: relative;
            width: 100%;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                width: 3rem;
                background: linear-gradient(90deg, hsla(0, 0%, 100%, 0) 0, #fff 70%);
            }
        }

        .contributors-item {
            display: flex;
            align-items: center;
        }

        .summary {
            width: 74%;

            flex-grow: 1;

            .asked-by{
                margin-bottom: .5rem;
            }

            .q-title {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: .2rem;
            }

            .avatar img {
                width: 20px;
            }

            .text-small {
                font-size: .8rem;
            }
        }
    }

    @include media-breakpoint-down (md) {
        .question-item {
            .stats{
                padding-right: 1rem;
            }
            .summary {
                width: 85%;
                .asked-by{
                    margin-bottom: .5rem;
                }
                .q-title {
                    font-size: 1rem;
                }
            }
        }
    }

    @include media-breakpoint-down (sm) {
        .question-item {
            padding-left: .5rem;
            padding-right: .5rem;

            .summary {
                .q-title {
                    font-size: 1rem;
                }
            }
        }
    }

.theme-dark {
    .question-item {
        background-color: #000;
    }
}
</style>
