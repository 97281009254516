// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div>
        <question-item
            :question="question"
            :highlight="shouldHighlight(question)"
            :stats-title="statsTitle"
        />
    </div>
</template>

<script>
    import _assign from 'lodash/assign'
    import { mapActions } from 'vuex'
    import QuestionItem from '../questions/Item/index.vue'

    export default {
        components: {
            QuestionItem,
        },

        props: {
            questionId: Number,
            feedStore: String,
            shouldHighlight: {
                type: Function,
                default: () => false,
            },
        },

        computed: {
            question() {
                const question = this.$store.getters['entities/questions/get'](this.questionId)
                const user = this.$store.getters['entities/users/get'](question.user_id)
                const extra = this.$store.getters[`${this.feedStore}/get`](this.questionId)

                return _assign({}, question, {
                    user: {
                        data: user,
                    },
                }, extra)
            },

            questionsCount() {
                return this.$store.state.profile.questions.current.length
            },

            statsTitle() {
                return {
                    answers: this.$t('question.answer'),
                    comments: this.$t('question.comment'),
                    views: this.$t('question.view'),
                    score: this.$t('question.score'),
                    ok: this.$t('confirm.ok'),
                    cancel: this.$t('confirm.cancel'),
                    titleDelete: this.$t('questions.delete.title'),
                    contentDelete: this.$t('questions.delete.content'),
                }
            },
        },

        provide() {
            return {
                actions: {
                    destroy: this.doDestroy,
                },
            }
        },

        methods: {
            ...mapActions('entities/questions', ['destroy']),

            doDestroy(question) {
                return this.destroy(question)
                    .then(() => {
                        if (this.questionsCount !== 1) {
                            this.$emit('request-reload')
                        } else {
                            const page = this.$route.query.page ? this.$route.query.page : 1
                            const prevPage = page <= 1 ? 1 : page - 1
                            this.$router.push(`/u/${this.$route.params.user}/questions?page=${prevPage}`)
                        }
                    })
            },
        },
    }
</script>
