// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="pt-2 pb-2">
        <i
            v-if="answers.length <= 0 && loading"
            class="fa fa-circle-o-notch fa-spin sb-icon loading"
            aria-hidden="true"
        />
        <Empty v-else-if="answers.length <= 0 && !loading">
            <p> {{ $t('empty') }} </p>
        </Empty>

        <div v-else class="user-feed__answers">
            <AnswerItem
                v-for="answer in answers"
                :key="answer.id"
                :answer="answer"
                :send-vote="voteAnswer"
                class="user-answer-item"
            />

            <client-only>
                <InfiniteLoading :distance="50" spinner="spiral" @infinite="load">
                    <span slot="no-results" />
                    <span slot="no-more" />
                </InfiniteLoading>
            </client-only>
        </div>
    </div>
</template>

<script>
    import _assign from 'lodash/assign'
    import InfiniteLoading from 'vue-infinite-loading'
    import _findIndex from 'lodash/findIndex'
    import { castVote } from 'viblo-sdk/api/voting'
    import { serializeVote } from '~/utils/voting'
    import { getAnswers } from '~/api/users'
    import Empty from '~/components/shared/Empty.vue'
    import AnswerItem from '~/components/subdomains-theme-2/answers/AnswerItem.vue'
    import { handlePageError, getContentSubDomain } from '~/utils/pages'

    export default {
        components: {
            Empty,
            AnswerItem,
            InfiniteLoading,
        },

        data() {
            return {
                answers: [],
                pagination: null,
                loading: false,
            }
        },

        watchQuery: ['page'],

        // eslint-disable-next-line consistent-return
        async fetch() {
            const req = this.$nuxt.context.ssrContext?.req
            const store = this.$nuxt.context.store
            const params = this.$nuxt.context.route.params
            const error = this.$nuxt.context.error
            const page = this.pagination ? { page: this.pagination.current_page + 1 } : { page: 1 }

            try {
                const host = process.client ? window.location.host : req.headers.host
                const user = store.state.config.vars.is_special_profile ? getContentSubDomain(host) : params.user
                this.loading = true
                const res = await getAnswers(user, page)


                this.answers = [...this.answers, ...res.data]
                this.pagination = res.meta.pagination
                this.loading = false
            } catch (e) {
                this.loading = false
                return handlePageError(error, e)
            }
        },

        methods: {
            async voteAnswer(hashId, value) {
                await castVote('answers', hashId, value)
                const votedAnswerIndex = _findIndex(this.answers, { hash_id: hashId })

                if (votedAnswerIndex !== -1) {
                    const answer = this.answers[votedAnswerIndex]
                    const currentVote = answer.rated_value
                    const nextVote = serializeVote(value)

                    const updatedAnswer = _assign({}, answer, {
                        rated_value: serializeVote(value),
                        points: answer.points + (nextVote - currentVote),
                    })
                    this.answers = [
                        ...this.answers.slice(0, votedAnswerIndex),
                        updatedAnswer,
                        ...this.answers.slice(votedAnswerIndex + 1),
                    ]
                }
            },

            async load($state) {
                if (this.pagination?.current_page < this.pagination?.total_pages) {
                    await this.$fetch()
                    $state.loaded()
                } else {
                    $state.complete()
                }
            },
        },
    }
</script>

<style lang="scss">
    .loading {
        position: unset;
        width: 100%;
        text-align: center;
        font-size: 24px;
    }

    .user-answer-item {
        margin-bottom:  24px;
    }
</style>
