// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <p class="heading">
        <span v-if="isIcon" class="heading-icon"><i class="fa fa-thumb-tack" aria-hidden="true" /></span>
        {{ title }}
    </p>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
            },
            isIcon: {
                type: Boolean,
                default: false,
            },
        },
    }
</script>

<style lang="scss" scoped>
.heading {
    font-size: 28px;
    font-weight: 700;
    color: #000;

    @media screen and (max-width: 768px) {
        font-size: 24px;
    }

    @media screen and (max-width: 500px) {
        font-size: 20px;
    }
}

.theme-dark {
    .heading {
        color: #fff;
    }
}
</style>
