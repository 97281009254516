// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template functional>
    <nuxt-link
        :to="props.to"
        :exact="props.exact"
        :class="{ active: props.index && parent.activeTabIndex === props.index }"
        :active-class="parent.activeTabIndex === undefined ? 'active': ''"
        class="heading-tabs-subdomain__item"
        tag="li"
        exact-active-class=""
    >
        <a class="heading-tabs-subdomain__link">
            <slot />
        </a>
    </nuxt-link>
</template>

<script>
    export default {
        functional: true,

        props: {
            index: [String, Number],
            to: [String, Object],
            exact: {
                type: Boolean,
                default: true,
            },
        },
    }
</script>
