// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div>
        <EmbedStats
            v-if="isSelf"
            :text="toPersonalStats"
            :sharing-id="profile.username"
            :label="$t('profile.embed.title')"
            sharing-title="Share"
            class="mt-05"
        />

        <div class="profile-col half">
            <ProfileStats :profile="profile" />
        </div>

        <badges v-if="profile.badges.data.length !== 0" :badges="profile.badges.data" />

        <technical-skills
            :is-self="isSelf"
            :profile="profile"
            :skills="skills"
            class="user-profile__skills"
        />

        <organization
            :organizations="organizations"
            :is-self="isSelf"
            class="user-profile__organizations"
        />

        <div class="profile-col half sidebar-box hidden-sm-down mt-3">
            <SectionTitle :title="$t('profile.postsTendency')" />

            <div class="sidebar-box-content">
                <chart :data="chartData" :default-options="options" />
            </div>
        </div>
    </div>
</template>

<script>
    import map from 'lodash/map'
    import reduce from 'lodash/reduce'
    import size from 'lodash/size'
    import forEach from 'lodash/forEach'
    import { personalStats, absolute } from '~/utils/url'

    import SectionTitle from './SectionTitle.vue'
    import Badges from './Badges.vue'
    import TechnicalSkills from './TechnicalSkills.vue'
    import Chart from '../charts/Chart.vue'
    import ProfileStats from './ProfileStats.vue'
    import Organization from './Organization.vue'
    import EmbedStats from '~/components/widgets/EmbedStats.vue'

    const MAX_CHART_CATEGORIES = 5

    export default {
        components: {
            SectionTitle,
            ProfileStats,
            Badges,
            TechnicalSkills,
            Chart,
            Organization,
            EmbedStats,
        },

        props: {
            profile: {
                type: Object,
                required: true,
            },

            skills: {
                type: Array,
                default: () => [],
            },

            organizations: {
                type: Array,
                default: () => [],
            },
        },

        data() {
            return {
                options: {
                    donut: true,
                    donutWidth: 75,
                    startAngle: 270,
                    showLabel: false,
                },
            }
        },

        computed: {
            isSelf() {
                return this.$store.getters['auth/isUser'](this.profile.id)
            },
            toPersonalStats() {
                const src = absolute(personalStats(this.profile.username))
                return `<iframe id="frame" src="${src}" width="300px" frameborder="1"></iframe>`
            },
            chartData() {
                const normalized = reduce(this.profile.posts_stats, (acc, postsCount, category) => {
                    if (size(acc) >= MAX_CHART_CATEGORIES) {
                        acc.Others = acc.Others ? acc.Others + postsCount : postsCount
                    } else {
                        acc[category] = postsCount
                    }
                    return acc
                }, {})

                const series = this.sortData(normalized)

                return { series }
            },
        },

        methods: {
            sortData(normalized) {
                const sortable = []
                const keys = []
                const values = []

                if (size(normalized) > 5) {
                    const others = normalized.Others
                    delete normalized.Others
                    sortable.push(['Others', others])
                }

                forEach(normalized, (value, property) => {
                    sortable.push([property, value])
                })
                sortable.sort((current, next) => current[1] < next[1])

                map(sortable, (item) => {
                    keys.push(item[0])
                    values.push(item[1])
                })

                const data = keys.map((item, i) => ({ meta: `${item}:`, value: values[i] }))

                return data
            },
        },
    }
</script>

<style lang="scss">
    @import "../../assets/sass/bootstrap/colors";
    @import "../../assets/sass/bootstrap/mixins";

    // Profile
    .user-profile {
        img.avatar, .avatar > img {
            max-width: 90px;
            max-height: 90px;
        }

        .user-card {
            border-bottom: none;

            .user-info-row {
                & > div {
                    &:nth-child(1) {
                        min-width: 90px;
                        max-width: 90px;
                    }

                    &:nth-child(2) {
                        margin-left: 10px;
                    }
                }
            }

            .user-action > .btn-follow {
                width: 100%;
                padding: 0.25rem 1rem;
            }

            .social-accounts {
                display: flex;
                max-width: 130px;
                justify-content: space-around;

                .social-profile-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 30px;
                    height: 30px;

                    &.github-btn {
                        font-size: 1.3em;

                        .fa {
                            margin-left: -0.5px;
                        }
                    }
                }
            }
        }

        .profile-stats {
            padding: 1rem;
            margin: 1rem 0;
            border: 1px solid $base-border-color;

            & > a {
                display: flex;
                justify-content: space-between;

                & > p {
                    margin-bottom: 0.5rem;

                    &:first-child {
                        color: $gray-light;
                    }

                    &:nth-child(2) {
                        font-weight: bold;
                    }
                }
            }
        }
    }

    // Profile page responsive
    @include media-breakpoint-between(sm, md) {
        .user-profile {
            display: flex;
            flex-wrap: wrap;

            .profile-col {
                padding: 0 2rem;

                &.half {
                    flex: 0 0 50%;
                    max-width: 50%;
                }

                &.full {
                    flex: 0 0 100%;
                    max-width: 100%;
                    order: 10;
                }
            }
        }
    }
</style>
