<template>
    <div
        :class="{
            [`social-sharing--${orientation}`]: true,
            [`social-sharing--${size}`]: true
        }"
        class="social-sharing"
    >
        <slot :facebook="shareFacebook" :twitter="shareTwitter" :google="shareGoogle">
            <a
                v-tooltip
                :tooltip-placement="tooltipPlacement"
                rel="noopener"
                :title="$t('postBody.postActions.shareLinkFB')"
                class="link link--muted"
                :class="modeTheme !== 'theme-dark' ? 'link--muted' : ''"
                @click="shareFacebook()"
            >
                <i class="fa fa-facebook" aria-hidden="true" />
            </a>
            <a
                v-tooltip
                :tooltip-placement="tooltipPlacement"
                rel="noopener"
                :title="$t('postBody.postActions.shareLinkTwitter')"
                class="link link--muted"
                :class="modeTheme !== 'theme-dark' ? 'link--muted' : ''"
                @click="shareTwitter()"
            >
                <i class="fa fa-twitter" aria-hidden="true" />
            </a>
        </slot>
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex'
    import * as Facebook from 'fb-sdk-wrapper'
    import { PopupShare } from '~/utils/popup-share'

    const twitter = (url, title, hashTags) => `https://twitter.com/intent/tweet?url=${url}&text=${encodeURIComponent(title)}&hashtags=${encodeURIComponent(hashTags)}`
    const google = url => `https://plus.google.com/share?url=${encodeURIComponent(url)}`

    export default {
        props: {
            title: String,
            url: String,
            tags: Array,
            orientation: {
                type: String,
                default: 'vertical',
            },
            size: {
                type: String,
                default: 'small',
            },
        },
        computed: {
            tooltipPlacement() {
                return this.orientation === 'vertical' ? 'bottom' : 'right'
            },

            ...mapGetters('postView', ['post']),

            ...mapState('typeControl', ['modeTheme']),
        },
        methods: {
            shareFacebook() {
                Facebook.load()
                    .then(() => {
                        Facebook.init({ appId: `${process.env.FACEBOOK_APP_ID}` })
                        Facebook.ui({
                            display: 'popup',
                            method: 'share',
                            hashtag: (new Date() < new Date(2021, 3, 1)) ? '#Vui_Tet_Viblo_Tram_Tro_Keycap' : '',
                            quote: `${this.post ? this.post.preview.teaser : ''}`,
                            href: `${this.post ? this.post.canonical_url : this.url}`,
                            redirect_uri: 'viblo.asia',
                        })
                    })
            },

            shareTwitter() {
                PopupShare(twitter(this.url, this.title), 'twitter-popup', '600', '350')
            },

            shareGoogle() {
                PopupShare(google(this.url), 'twitter-popup', '600', '350')
            },
        },
    }
</script>

<style scoped lang="scss">
    @import "~assets/sass/bootstrap/colors";

    .social-sharing {
        &--small {
            font-size: 15px;
        }

        &--medium {
            font-size: 20px;
        }

        &--vertical {
            .link + .link {
                margin-left: 1rem;
            }
        }

        &--horizontal {
            display: flex;
            flex-direction: column;
            align-items: center;

            & > .link {
                width: 30px;
                height: 30px;
                line-height: 30px;
                text-align: center;
                border-radius: 50%;
                border: 1px solid $gray-300;
                + .link {
                    margin-top: 0.5rem;
                }
            }
        }

        a {
            cursor: pointer;
        }
    }
</style>
