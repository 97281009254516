// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <empty v-if="!state && !isNull && data.length == 0">
        <p> {{ $t('empty') }} </p>
    </empty>
    <div v-else-if="!isNull && data.length > 0">
        <div v-for="(item, index) in data" :key="index">
            <series-feed-item v-if="type == 'series'" :series="item" />
            <post-feed-item v-if="type == 'posts'" :post="item" />
            <question-feed-item v-if="type == 'questions'" :question="item" />
        </div>

        <pagination
            v-if="pagination"
            :current-page="pagination.current_page"
            :last-page="pagination.total_pages"
        />
    </div>
    <PostFeedPlaceholder v-else />
</template>

<script>
    import _findIndex from 'lodash/findIndex'
    import { mapActions } from 'vuex'
    import Empty from './shared/Empty.vue'
    import PostFeedItem from './posts/FeedItem.vue'
    import SeriesFeedItem from './series/ListItem.vue'
    import QuestionFeedItem from './questions/Item/index.vue'
    import Pagination from '~/components/subdomains/Pagination.vue'
    import PostFeedPlaceholder from '~/containers/posts/PostFeedPlaceholder.vue'

    export default {

        components: {
            Empty,
            Pagination,
            PostFeedItem,
            SeriesFeedItem,
            QuestionFeedItem,
            PostFeedPlaceholder,
        },
        props: {
            type: {
                type: String,
                default: 'post',
            },
            data: Array,
            pagination: Object,
            state: Boolean,
        },

        provide() {
            return {
                actions: {
                    destroy: this.doDestroy,
                },
            }
        },

        computed: {
            isNull() {
                return this.data === null
            },
        },

        methods: {
            ...mapActions('entities/questions', ['destroy']),

            doDestroy(data) {
                return this.destroy(data)
                    .then(() => {
                        const index = _findIndex(this.data, { hash_id: data.hash_id })
                        if (index !== -1) {
                            this.data.splice(index, 1)
                        }

                        if (this.questionsCount !== 1) {
                            this.$emit('request-reload')
                        } else {
                            const page = this.$route.query.page ? this.$route.query.page : 1
                            const prevPage = page <= 1 ? 1 : page - 1
                            this.$router.push(`/u/${this.$route.params.user}/questions?page=${prevPage}`)
                        }
                    })
            },
        },
    }
</script>
