// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <empty v-if="tags.length <= 0">
        <p> {{ $t('empty') }} </p>
    </empty>

    <div v-else>
        <div class="row block-exhibition">
            <div v-for="tag in tags" :key="tag.id" class="col-sm-6 col-lg-4 my-2 exhibition-item">
                <tag-item :tag="tag" />
            </div>
        </div>
        <pagination
            :current-page="pagination.current_page"
            :last-page="pagination.total_pages"
        />
    </div>
</template>

<script>
    import { getUserFollowingTags } from 'viblo-sdk/api/users'
    import Empty from '~/components/shared/Empty.vue'
    import TagItem from '~/components/tags/Card.vue'
    import Pagination from '~/components/Pagination.vue'
    import { normalizeTagList } from '~/components/entities/normalize'
    import { handlePageError, getContentSubDomain } from '~/utils/pages'

    export default {
        components: {
            Empty,
            TagItem,
            Pagination,
        },

        scrollToTop: true,

        computed: {
            tags() {
                return this.$store.getters['entities/tags/mapId'](this.tagIds)
            },
            path() {
                return this.$route.path
            },
            page() {
                return (typeof this.$route.query.page === 'undefined') ? 1 : this.$route.query.page
            },
        },

        async asyncData({
            store, error, query, params, req,
        }) {
            try {
                const host = process.client ? window.location.host : req.headers.host
                const user = store.state.config.vars.is_special_profile ? getContentSubDomain(host) : params.user

                const { data: tags, meta: { pagination } } = await getUserFollowingTags(user, {
                    ...query,
                    limit: store.state.settings.perPage - (store.state.settings.perPage % 3),
                })

                const normalizedTags = normalizeTagList(tags)
                const tagIds = normalizedTags.result

                store.commit('entities/tags/put', normalizedTags.entities.tags)

                return { tagIds, pagination }
            } catch (e) {
                return handlePageError(error, e)
            }
        },

        watchQuery: ['page'],
    }
</script>
