// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="user-page">
        <header class="pt-1">
            <user-card :user="profile" />
        </header>

        <div>
            <heading-tabs
                class="subdomain-header subdomains-2-header container"
                :active-tab-index="activeTabIndex"
            >
                <heading-tabs-item
                    :to="tab('')"
                    index="posts"
                >
                    {{ $t('profile.posts') }}
                </heading-tabs-item>
                <heading-tabs-item
                    :to="tab('series')"
                    index="series"
                >
                    {{ $t('profile.series') }}
                </heading-tabs-item>
                <heading-tabs-item
                    :to="tab('questions')"
                    index="questions"
                >
                    {{ $t('profile.questions') }}
                </heading-tabs-item>
                <heading-tabs-item
                    :to="tab('answers')"
                    index="answers"
                >
                    {{ $t('profile.answers') }}
                </heading-tabs-item>
                <heading-tabs-item
                    :to="tab('clips/posts')"
                    :exact="false"
                    index="clips"
                >
                    {{ $t('profile.clips') }}
                </heading-tabs-item>
                <heading-tabs-item
                    :to="tab('following')"
                    index="following"
                >
                    {{ $t('profile.followings') }}
                </heading-tabs-item>
                <heading-tabs-item
                    :to="tab('followers')"
                    index="followers"
                >
                    {{ $t('profile.followers') }}
                </heading-tabs-item>
                <heading-tabs-item
                    :to="tab('following-tags')"
                    index="following-tags"
                >
                    {{ $t('profile.tags') }}
                </heading-tabs-item>
                <heading-tabs-item
                    :to="tab('reputations')"
                    index="reputations"
                >
                    {{ $t('profile.reputations') }}
                </heading-tabs-item>
                <heading-tabs-item
                    :to="tab('contact')"
                    index="contact"
                >
                    {{ $t('profile.communication') }}
                </heading-tabs-item>
            </heading-tabs>
        </div>

        <div class="body-profile-page">
            <div class="container subdomain-header">
                <div v-if="profile" class="row pt-3 pb-1 flex-col-xs-reverse flex-row-lg">
                    <div class="section-content-profile">
                        <div class="user-activities">
                            <div class="posts-list">
                                <nuxt-child :user="profile" :profile-subdomain="user" />
                            </div>
                        </div>
                    </div>
                    <div class="hidden-md-down sidebar-profile-subdomain">
                        <user-profile
                            v-if="profile"
                            :profile="profile"
                            class="user-profile mb-2"
                        />

                        <organization-list
                            :organizations="organizations"
                        />

                        <badges v-if="profile.badges.data.length !== 0" :badges="profile.badges.data" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import { defaultTitle } from '~/plugins/seo'
    import { routeToUser } from '~/lib/functions'

    import UserProfile from '~/components/subdomains/users/Profile.vue'
    import UserCard from '~/components/subdomains/profiles/ProfileCard.vue'
    import Badges from '~/components/subdomains/profiles/Badges.vue'
    import HeadingTabs from '~/components/subdomains/shared/HeadingTabs.vue'
    import HeadingTabsItem from '~/components/subdomains/shared/HeadingTabsItem.vue'
    import OrganizationList from '~/components/subdomains/profiles/OrganizationList.vue'
    import { getUserOrganizations } from '~/api/organizations'
    import { getTechnicalSkills } from '~/api/users'
    import { userSEO } from '~/utils/seo/user'
    import { handlePageError, getContentSubDomain } from '~/utils/pages'

    export default {
        components: {
            Badges,
            UserCard,
            UserProfile,
            HeadingTabs,
            HeadingTabsItem,
            OrganizationList,
        },

        layout: 'subdomain/default',

        computed: {
            ...mapGetters('notifications', ['withUnreadCount']),
            ...mapGetters('profile', { profile: 'current' }),

            activeTabIndex() {
                const arrayPath = this.$route.path.split('/')
                if (this.$store.state.config.vars.is_special_profile) {
                    if (arrayPath[1] === 'clips') {
                        return 'clips'
                    }

                    return arrayPath[1] || 'posts'
                }

                if (arrayPath[2] === 'clips') {
                    return 'clips'
                }

                return arrayPath[3] || 'posts'
            },
        },

        async asyncData({
            store, req, params, error,
        }) {
            try {
                const host = process.client ? window.location.host : req.headers.host
                const user = store.state.config.vars.is_special_profile ? getContentSubDomain(host) : params.user
                const { data: { data: organizations } } = await getUserOrganizations(user)
                const { data: { data: technicalSkills } } = await getTechnicalSkills(user)

                return {
                    organizations,
                    technicalSkills,
                    host,
                    user,
                }
            } catch (e) {
                return handlePageError(error, e)
            }
        },

        fetch({
            store, params, error, req,
        }) {
            const host = process.client ? window.location.host : req.headers.host
            const user = store.state.config.vars.is_special_profile ? getContentSubDomain(host) : params.user

            return Promise.all([
                store.dispatch('profile/getProfile', user),
                store.dispatch('profile/skills/fetch', user),
            ]).catch(handlePageError(error))
        },

        methods: {
            tab(page) {
                if (this.$store.state.config.vars.is_special_profile) {
                    page = (typeof page === 'undefined') ? '' : `/${page}`

                    return `${page}`
                }

                return routeToUser(this.profile, page)
            },
        },

        head() {
            const seo = userSEO(this.profile)

            return {
                ...seo,
                title: this.withUnreadCount(`${this.profile.name} - ${defaultTitle}`),
            }
        },

        analytics: route => ({
            title: window.$nuxt.$store.state.config.vars.is_special_profile
                ? getContentSubDomain(window.location.host) : route.params.user,
            dimension1: 'profile.view',
        }),
    }
</script>

<style lang="scss">
    @import "../../../assets/sass/bootstrap/colors";
    @import "../../../assets/sass/bootstrap/mixins";
    @import '~/assets/sass/components/subdomain-header';

    .body-profile-page {
        background-color: #f9f9f9;
    }

    .user-page-subdomain {
        background-color: #f9f9f9;
    }

    .sidebar-profile-subdomain {
        margin-left: 1em;
        width: 28%;

        .user-profile {
            border-radius: 0.5em;
            background-color: white;
            padding: 0 8px;
        }
    }

    .section-content-profile {
        width: 70%;
        padding-left: 0px !important;
    }

    .profile-tabs {
        border: solid $base-border-color;
        border-width: 1px 0 0 0;
    }

    @media only screen and (max-width: 991px) {
        .section-content-profile {
            width: 100%;
        }
    }

    @include media-breakpoint-up(lg) {
        .profile-tabs {
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
        }
    }

    @include media-breakpoint-down(md) {
        .profile-tabs {
            border-width: 6px 0 1px 0;
        }
    }

    .user-profile {
        max-width: 1200px !important;
    }

    .item-menu-profile {
        display: flex;
        flex-wrap: nowrap;
    }

    .theme-dark{
        .user-page {
            background-color: $dark-bg;
        }
        .body-profile-page {
            background-color: $dark-bg;
        }
    }
</style>
