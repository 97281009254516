// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <v-dialog
        :show.sync="show"
        class="dialog-pick-post-pin"
        @close="handleCancel"
    >
        <template slot="title">
            <div class="d-flex dialog-title">
                <span class="pin-icon"><i class="fa fa-thumb-tack" aria-hidden="true" /></span>
                <h3>{{ $t(title) }}</h3>
            </div>
        </template>
        <div v-if="show && pinnedPosts.length > 0" class="wrapper-slick">
            <VueSlickCarousel
                ref="carousel"
                v-bind="settings"
                :class="{'slick-one-item': pinnedPosts.length === 1, 'slick-tree-item': pinnedPosts.length > 2}"
            >
                <div v-for="(post) in pinnedPosts" :key="post.id">
                    <PostPinItem
                        :post="post"
                        :post-small="true"
                        :size-title="'mini'"
                        :is-edit="true"
                        :is-owner="isOwner"
                        :on-pined="onPined"
                        :next-slide="handleNextSlide"
                        :prev-slide="handlePrevSlide"
                        :on-remove-slide="handleRemoveSlide"
                    />
                </div>
            </VueSlickCarousel>
        </div>
        <div class="mt-1">
            <el-button
                type="primary"
                @click.native="onSavePinedPost(pinnedPosts, close)"
            >
                {{ $t('createPost.buttonSave') }}
            </el-button>
            <el-button
                type="danger"
                @click.native="handleCancel"
            >
                {{ this.$t('confirm.cancel'), }}
            </el-button>
        </div>
    </v-dialog>
</template>

<script>
    import VueSlickCarousel from 'vue-slick-carousel'
    import PostPinItem from '../PostPinItem.vue'

    export default {
        components: {
            PostPinItem,
            VueSlickCarousel,
        },

        props: {
            title: {
                type: String,
                default: 'subdomain.PinnedPosts',
            },
            postsPin: {
                type: Array,
                default: () => [],
            },
            isOwner: {
                type: Boolean,
                default: false,
            },
            onPined: {
                type: Function,
                default: () => {},
            },
            isOrgSubdomain: {
                type: Boolean,
                default: true,
            },
            onSavePinedPost: {
                type: Function,
                default: () => {},
            },
        },

        data() {
            return {
                pinnedPosts: [...this.postsPin],
                show: false,
                settings: {
                    dots: false,
                    speed: 500,
                    slidesToShow: 2.5,
                    slidesToScroll: 1,
                    infinite: false,
                    responsive: [
                        {
                            breakpoint: 1024,
                            settings: {
                                slidesToShow: 2.5,
                                slidesToScroll: 1,
                            },
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1.5,
                                slidesToScroll: 1,
                            },
                        },
                        {
                            breakpoint: 425,
                            settings: {
                                slidesToShow: 1.1,
                                slidesToScroll: 1,
                            },
                        },
                    ],
                },
            }
        },

        watch: {
            postsPin(newVal) {
                this.pinnedPosts = newVal
            },
        },

        methods: {
            open() {
                this.show = true
            },
            close() {
                this.show = false
            },
            handleCancel() {
                this.pinnedPosts = [...this.postsPin]
                this.close()
            },
            handleRemoveSlide(post) {
                this.pinnedPosts = this.pinnedPosts.filter(postPin => postPin.id !== post.id)
            },
            handleNextSlide(post) {
                const currentIndex = this.pinnedPosts.findIndex(pin => pin.id === post.id)
                const temp = this.pinnedPosts[currentIndex]
                if (this.isOrgSubdomain && this.pinnedPosts[currentIndex + 1]) {
                    this.$set(this.pinnedPosts, currentIndex,
                              {
                                  ...this.pinnedPosts[currentIndex + 1],
                                  organization_pinned_at: temp.organization_pinned_at,
                              })
                    this.$set(this.pinnedPosts, currentIndex + 1,
                              {
                                  ...temp,
                                  organization_pinned_at: this.pinnedPosts[currentIndex + 1].organization_pinned_at,
                              })
                }
                if (!this.isOrgSubdomain && this.pinnedPosts[currentIndex + 1]) {
                    this.$set(this.pinnedPosts, currentIndex,
                              {
                                  ...this.pinnedPosts[currentIndex + 1],
                                  profile_pin_position: temp.profile_pin_position,
                              })
                    this.$set(this.pinnedPosts, currentIndex + 1,
                              {
                                  ...temp,
                                  profile_pin_position: this.pinnedPosts[currentIndex + 1].profile_pin_position,
                              })
                }
            },
            handlePrevSlide(post) {
                const currentIndex = this.pinnedPosts.findIndex(pin => pin.id === post.id)
                const temp = this.pinnedPosts[currentIndex]
                if (this.isOrgSubdomain && this.pinnedPosts[currentIndex - 1]) {
                    this.$set(this.pinnedPosts, currentIndex,
                              {
                                  ...this.pinnedPosts[currentIndex - 1],
                                  organization_pinned_at: temp.organization_pinned_at,
                              })
                    this.$set(this.pinnedPosts, currentIndex - 1,
                              {
                                  ...temp,
                                  organization_pinned_at: this.pinnedPosts[currentIndex - 1].organization_pinned_at,
                              })
                }
                if (!this.isOrgSubdomain && this.pinnedPosts[currentIndex - 1]) {
                    this.$set(this.pinnedPosts, currentIndex,
                              {
                                  ...this.pinnedPosts[currentIndex - 1],
                                  profile_pin_position: temp.profile_pin_position,
                              })
                    this.$set(this.pinnedPosts, currentIndex - 1,
                              {
                                  ...temp,
                                  profile_pin_position: this.pinnedPosts[currentIndex - 1].profile_pin_position,
                              })
                }
            },
        },
    }
</script>

<style lang="scss">
@import "../../../../assets/sass/bootstrap/colors";

.btn-post-pin {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60px;
}
.dialog-pick-post-pin .dialog {
    width: 60%;
}
.dialog-pick-post-pin {
    .dialog-title {
        span {
            transform: rotate(45deg);
            display: inline-block;
            color: red;
            font-size: 1.2em;
        }
        h3 {
            font-weight: 600;
            font-size: 1.5em;
            margin-left: 12px;
        }
    }

    .wrapper-slick {
        position: relative;
        background: $pin-bg;
        padding: 35px;
        padding-right: 0;

        &::after {
            content: '';
            position: absolute;
            width: 170px;
            top: 0;
            bottom: 0;
            right: 0;
            background: linear-gradient(270deg, #002D66 0%, rgba(0, 45, 102, 0.00) 100%);
        }
    }
}

</style>
