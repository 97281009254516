// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<script>
    export default {
        functional: true,

        props: {
            url: {
                type: String,
                required: true,
            },

            title: {
                type: String,
                required: true,
            },

            isVideo: Boolean,


            sizeTitle: {
                type: String,
                default: 'small',
            },
            isPostFeed: {
                type: Boolean,
                default: false,
            },
        },

        render: (h, { props }) => (
            <div
                class='subdomain-post-title--inline'
                style={props.isPostFeed ? '-webkit-line-clamp: 1' : '-webkit-line-clamp: 2'}
            >
                <h3
                    // eslint-disable-next-line no-nested-ternary
                    style={props.sizeTitle === 'mini'
                        ? { fontSize: '16px', color: '#444444' }
                        : (props.sizeTitle === 'small'
                            ? { fontSize: (props.isPostFeed ? '18px' : '20px'), color: '#444444' }
                            : { fontSize: '28px', marginTop: '10px' }
                        )
                    }
                    class='word-break mr-05'>
                    <nuxt-link to={props.url} class='link'>{ props.title }</nuxt-link>
                </h3>
            </div>
        ),
    }
</script>

<style lang="scss">
    .subdomain-post-title--inline {
        font-family: 'RobotoCondensed Medium', sans-serif;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;

        h3 {
            font-weight: 500;
        }

        .fa-play-circle {
            font-size: 1rem;
            margin-right: .2rem;
        }
    }
</style>

<style lang="scss" scoped>
.theme-dark {
    .link {
        color: #fff !important;
    }

    h3 {
        color: #FFFFFF !important;
    }
}
</style>
