// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <empty v-if="questions.length <= 0">
        <p> {{ $t('empty') }} </p>
    </empty>

    <div v-else class="question-list" :class="modeThemeSubdomain">
        <item
            v-for="(question, index) in questions"
            :key="index"
            :question-id="question"
            :feed-store="feedStore"
            :should-highlight="shouldHighlight"
            v-on="$listeners"
        />

        <pagination
            v-if="pagination"
            :current-page="pagination.current_page"
            :last-page="pagination.total_pages"
        />
    </div>
</template>

<script>
    import _get from 'lodash/get'
    import { mapState } from 'vuex'
    import Empty from '../shared/Empty.vue'
    import Item from './QuestionItem.vue'
    import Pagination from '../Pagination.vue'

    export default {
        components: {
            Item,
            Empty,
            Pagination,
        },

        props: {
            feedStore: String,
            shouldHighlight: {
                type: Function,
                default: () => false,
            },
        },

        computed: mapState({
            questions(state) {
                return _get(state, [...this.feedStore.split('/'), 'current'])
            },

            pagination(state) {
                return _get(state, [...this.feedStore.split('/'), 'pagination'])
            },

            modeThemeSubdomain() {
                return this.$store.state.typeControl.modeTheme
            },
        }),
    }
</script>

<style lang="scss">
.theme-dark {
  .summary {
    .user--inline a {
      color: #AB7738 !important;
    }
  }

  .question-item {
    border-color: #1D1612 !important;
  }
}
</style>
