<template>
    <div
        class="d-flex flex-column post-pin-item h-100"
        :class="{
            'video-subdomain': isVideoPin,
            'justify-content-between': post.is_video || postSmall,
        }"
    >
        <div class="position-relative">
            <div class="post-pin-thumbnail first-pin">
                <nuxt-link v-if="!post.is_video" :to="url">
                    <img :src="post.thumbnail_url" alt="thumbnail">
                </nuxt-link>
                <Video
                    v-else
                    :id="video.uuid"
                    :provider="video.provider"
                    :url="video.url"
                />
            </div>
            <div
                :class="post.is_video ? 'avt-author-video' : 'avt-author-post-pin'"
                class="d-flex flex-column author-info"
            >
                <div class="d-flex align-items-center position-relative">
                    <div class="username-user d-flex align-items-center">
                        <Avatar
                            :object="user"
                            :image="user.avatar"
                            :is-user="true"
                            :level-partner="user.level_partner"
                            :partner-width="postSmall ? 40 : 42"
                            :partner-hight="postSmall ? 40 : 42"
                            :size="postSmall ? 'sm' : 'md-mini'"
                        />
                        <nuxt-link
                            :to="toUser"
                            class="link"
                        >
                            <span
                                class="px-05 sidebar__feed-item__subtitle author-name"
                                :class="postSmall ? 'username-size-small' : 'username-size-medium'"
                            >
                                {{ user.name }}
                            </span>
                        </nuxt-link>
                    </div>

                    <div
                        v-if="isVideoPin"
                        class="ml-1 position-relative"
                        :class="{
                            'post-meta-size-small': postSmall,
                            'post-meta-size-medium': !postSmall,
                            'my-1': postSmall && !post.is_video
                        }"
                        style="bottom: -1.2em;"
                    >
                        <div class="text-muted">
                            <span
                                v-tooltip
                                :title="post.published_at | format-time"
                                class="mr-05"
                            >
                                {{ post.published_at | humanize-time }}
                            </span>

                            <ReadingTime
                                v-if="!post.is_video"
                                :reading-time="post.reading_time"
                                class="is-divide text-muted"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <span
                v-if="isOwner && post.is_video"
                class="pin-icon"
                @click="openConfirm(post)"
            >
                <div :class="post.organization_pinned_at ? 'pinned-icon' : 'unpin-icon'" />
            </span>
        </div>

        <div
            v-if="!isVideoPin && !postSmall"
            class="ml-1 d-flex justify-content-end"
            :class="{
                'post-meta-size-small': postSmall,
                'post-meta-size-medium': !postSmall,
                'my-1': postSmall && !post.is_video
            }"
        >
            <div v-if="!postSmall" class="text-muted">
                <span
                    v-tooltip
                    :title="post.published_at | format-time"
                    class="mr-05"
                >
                    {{ post.published_at | humanize-time }}
                </span>

                <ReadingTime
                    v-if="!post.is_video"
                    :reading-time="post.reading_time"
                    class="is-divide text-muted"
                />
            </div>
        </div>

        <div class="d-flex align-items-center justify-content-between mt-1">
            <PostTitleInline
                :url="url"
                :title="post.title"
                :is-video="post.is_video"
                :size-title="sizeTitle"
            />
        </div>
        <div>
            <div
                v-if="!postSmall"
                class="contents_short-org"
                :class="isVideoPin ? 'text-white' : 'post-pin-content-short'"
            >
                <MarkdownPure class="description-posts-pin" :contents="convertImgToText(post.contents_short)" />
            </div>
            <div
                class="tags-list"
                :class="{ 'mt-05': !postSmall, 'mt-05': post.is_video }"
                :style="postSmall ? 'height: 48px' : 'max-height: 64px'"
            >
                <div v-popover:popover class="table-services-cell">
                    <PostTags
                        :tags="tags.tags"
                        :size="postSmall ? 'mini' : 'medium'"
                        class="d-flex align-items-center"
                    />
                    <el-popover
                        v-if="post.is_video"
                        ref="popover"
                        :title="`Tags (${tags.tags.length})`"
                        width="300"
                        trigger="hover"
                    >
                        <PostTags
                            :tags="tags.tags"
                            :size="postSmall ? 'mini' : 'medium'"
                            class="d-flex align-items-center"
                        />
                    </el-popover>
                </div>
            </div>

            <div class="d-flex footer-post-item">
                <Stats :stats="stats" />
            </div>
        </div>
    </div>
</template>

<script>
    import _get from 'lodash/get'
    import { post as toPost, user as toUser } from '~/utils/url'
    import { unPinVideoOrganization, pinVideoOrganization } from '~/api/organizations'
    import Avatar from '~/components/shared/Avatar/Avatar.vue'
    import PostTags from '~/components/posts/widgets/Tags.vue'
    import PostTitleInline from '~/components/subdomains/posts/widgets/TitleInline.vue'
    import Stats from '~/components/subdomains/shared/Stats.vue'
    import ReadingTime from '~/components/posts/widgets/ReadingTime.vue'
    import Video from '~/components/posts/Video.vue'

    export default {
        components: {
            Avatar,
            PostTags,
            PostTitleInline,
            Stats,
            ReadingTime,
            Video,
        },
        props: {
            post: {
                type: Object,
                required: true,
            },
            isVideoPin: {
                type: Boolean,
                default: false,
            },
            postSmall: {
                type: Boolean,
                default: false,
            },
            sizeTitle: {
                type: String,
                default: 'small',
            },
            isOwner: {
                type: Boolean,
                default: false,
            },
            orgSubdomain: String,
        },
        computed: {
            video() {
                return this.post.video
            },
            url() {
                return toPost(this.post)
            },
            toUser() {
                return toUser(this.user)
            },
            user() {
                const user = _get(this.post, 'user.data')
                return typeof user === 'object' && !!user
                    ? user
                    : this.$store.getters['entities/users/get'](user || this.post.user_id)
            },
            stats() {
                return [
                    {
                        name: this.$t('question.views').concat(`${this.post.views_count}`),
                        src: '/images/icon-eye.png',
                        value: this.post.views_count,
                    },
                    {
                        name: this.$t('question.votes').concat(`${this.post.points}`),
                        src: '/images/icon-like.png',
                        value: this.post.points,
                    },
                    {
                        name: this.$t('userInline.clips').concat(`${this.post.clips_count}`),
                        src: '/images/icon-bookmark.png',
                        value: this.post.clips_count,
                    },
                    {
                        name: this.$t('question.comments').concat(`${this.post.comments_count}`),
                        src: '/images/icon-cmt.png',
                        value: this.post.comments_count,
                    },
                ]
            },
            tags() {
                return {
                    tags: this.post.tags ? this.post.tags.data : null,
                    promoted: this.post.promoted,
                    trending: this.post.trending,
                    system: this.post.system_tag,
                }
            },
        },

        methods: {
            convertImgToText(content) {
                return content.replace(/!\[\]\(.+?\)/g, '[IMG]')
            },
            openConfirm(post) {
                this.$msgbox({
                    showCancelButton: true,
                    confirmButtonText: this.$t('confirm.ok'),
                    cancelButtonText: this.$t('confirm.cancel'),
                    title: this.$t('cancelScheduled.confirm'),
                    message: post.organization_pinned_at ? this.$t('pinPostOrg.confirmContentUnPin') : this.$t('pinPostOrg.confirmContent'),
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            this.handle(post)
                        }
                        done()
                    },
                })
            },

            async handle(post) {
                try {
                    if (post.organization_pinned_at) {
                        await unPinVideoOrganization(post.slug)
                        this.$message.success(this.$t('pinPostOrg.unPinVideoSuccess'))
                    } else {
                        await pinVideoOrganization(post.slug, this.orgSubdomain)
                        this.$message.success(this.$t('pinPostOrg.pinVideoSuccess'))
                    }
                } catch (error) {
                    this.$message.error(this.$t('postBody.postContent.dialogAddOrganization.error'))
                }
            },
        },
    }
</script>

<style lang="scss">
@import "~/assets/sass/bootstrap/borders";
@import "~/assets/sass/bootstrap/colors";

.videofeed-org {
    .post-pin-item {
        .pin-icon {
            display: none;
        }
    }
}

.post-pin-item {
    padding: 8px;
    .title-all-posts span {
        transform: rotate(45deg);
        display: inline-block;
        color: red;
    }
    .pin-icon {
        position: absolute;
        top: -7px;
        right: -7px;
        cursor: pointer;
    }
    .pinned-icon {
        width: 18px;
        height: 18px;
        background-image: url('@/static/images/pinned-icon-sm.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .unpin-icon {
        width: 18px;
        height: 18px;
        background-image: url('@/static/images/pin-icon-sm.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .first-pin img {
        width: 100% !important;
        aspect-ratio: 16 / 9;
    }
    .contents_short {
        .md-contents {
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
        }
    }
    .author-info {
        .min-w-180 {
            min-width: 180px;
        }
        .username-user {
            border-radius: 20px;
            box-shadow: 2px 2px 5px rgba(0,0,0,0.3);
            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
            color: black;
            background-color: white;

            .author-name {
                max-width: 20rem;
            }
        }
    }
    .tags-list {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;

        .tag {
            margin-bottom: 8px;
        }
    }
    .avt-author-post-pin {
        margin-top: -1.2rem;
    }
    .avt-author-video {
        margin-top: -1.5rem;
    }
    .username-size-small {
        font-size: 13px;
        max-width: 10rem !important;
    }
    .username-size-medium {
        font-size: 20px;
    }
    .post-meta-size-small {
        font-size: 10px;
    }
    .contents_short-org {
        font-size: 16px;

        .md-contents {
            font-family: 'Roboto';
        }
    }
    .post-pin-content-short .md-contents {
        font-family: 'Roboto';
        font-size: 16px;
        color: #707070;
        line-height: 26px;
    }
    .post-meta-size-medium {
        margin-top: -1rem;
    }

    .description-posts-pin {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
    }
}
.video-subdomain {
    .contents_short, .subdomain-post-title--inline, .stats-item {
        color: white !important;
    }
    .author-info {
        .username-user {
            border-radius: 20px;
            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
            color: black;
            background-color: white;
            padding: 0 2px;
        }
    }
    .footer-post-item {
        max-width: 400px;
    }
}
@media screen and (max-width: 768px) {
    .post-pin-item {
        margin-bottom: 1rem;
    }
}
@media screen and (min-width: 1101px) and (max-width: 1280px) {
    .footer-post-item {
        .stats-item {
            font-size: 12px;

            &__icon {
                height: 80%;
                width: 80%;

                .fa-caret-up {
                    margin-top: -2px;
                }
            }
        }
    }
}
@media screen and (min-width: 991px) and (max-width: 1100px) {
    .footer-post-item {
        .stats-item {
            font-size: 11px;

            &__icon {
                margin-right: 1px;
                height: 70%;
                width: 70%;

                .fa-caret-up {
                    margin-top: -2px;
                }
            }
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 900px) {
    .footer-post-item {
        .stats-item {
            font-size: 12px;

            &__icon {
                height: 80%;
                width: 80%;
            }

            .fa-caret-up {
                margin-top: -2px;
            }
        }
    }
}
</style>

<style scoped lang="scss">
.theme-dark {
    .post-pin-item {
        background-color: #353535;
        border-radius: 4px;
        padding: 8px;

        .pin-icon {
            color: #fff;
        }

        .footer-post-item {
            .stats-item {
                margin: 0 !important;
            }
        }
    }
}
</style>
