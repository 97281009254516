// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="position-relative user">
        <div class="user-avatar" :class="{ 'member-top': isMemberTop }">
            <Avatar
                :image="user.avatar"
                :object="user"
                :is-user="true"
                :level-partner="user.level_partner"
                :partner-width="90"
                :partner-hight="90"
                size="xl"
            />
        </div>
        <div class="user-card">
            <div
                v-if="isMemberTop"
                v-tooltip
                class="d-flex justify-content-center align-items-center"
                :title="`${$t('profile.reputations')}: ${user.reputation}`"
            >
                <img
                    class="user-card--star"
                    src="/images/star.png"
                    alt=""
                >
                <span class="user-card--view">{{ user.reputation | formatNumber }}</span>
            </div>
            <div class="w-100">
                <nuxt-link :to="profilePage" class="user-card--name text-decoration-none">
                    <b :class="{ 'is-banned' : user.banned_at}">{{ user.name }}</b>
                </nuxt-link>
            </div>
            <div class="d-flex justify-content-between align-items-center user-card--icon">
                <div
                    v-if="!isMemberTop"
                    v-tooltip
                    class="d-flex justify-content-center align-items-center"
                    :title="`${$t('profile.reputations')}: ${user.reputation}`"
                >
                    <img
                        class="user-card--icon---star"
                        src="/images/star.png"
                        alt=""
                    >
                    {{ user.reputation | formatNumber }}
                </div>
                <span v-tooltip :title="`${$t('profile.followers')}: ${user.followers_count}`">
                    <i class="fa fa-user-plus" aria-hidden="true" />
                    {{ user.followers_count | formatNumber }}
                </span>
                <span v-tooltip :title="`${$t('profile.posts')}: ${user.posts_count}`">
                    <i class="fa fa-pencil" aria-hidden="true" /> {{ user.posts_count | formatNumber }}
                </span>
            </div>
            <div v-if="user.is_self">
                <a
                    class="user-card--follow btn"
                    :href="editProfile"
                    target="_blank"
                    rel="noopener"
                >
                    {{ $t('profile.edit') }}
                </a>
            </div>
            <subscribe
                v-else
                ref="subscribe"
                :send="handleFollow"
                :subscribable="{ type: 'User', 'id': user.id }"
            >
                <button
                    :class="{following: user.following}"
                    class="user-card--follow btn"
                    :disabled="!_isEmpty(user.banned_at)"
                    @click="$refs.subscribe.handle(!user.following)"
                >
                    {{ user.following ? $t('profile.following') : $t('profile.follow') }}
                </button>
            </subscribe>
        </div>
    </div>
</template>

<script>
    import _get from 'lodash/get'
    import _isEmpty from 'lodash/isEmpty'
    import { user as toUser } from '~/utils/url'
    import { routeToUser } from '~/lib/functions'
    import Avatar from '~/components/shared/Avatar/Avatar.vue'
    import Subscribe from '~/components/widgets/Subscribe.vue'

    export default {

        components: {
            Avatar,
            Subscribe,
        },
        props: {
            userId: Number,
            isMemberTop: {
                type: Boolean,
                default: false,
            },
            follow: {
                type: Function,
                default: () => {},
            },
        },

        computed: {
            user() {
                const authUserId = _get(this.$store.state, 'auth.user.id')
                const user = this.$store.getters['entities/users/get'](this.userId)

                return {
                    ...user,
                    is_self: authUserId === user.id,
                }
            },

            profilePage() {
                return routeToUser(this.user)
            },

            editProfile() {
                return `${process.env.SSO_APP_URL}/profile/personal`
            },
        },

        methods: {
            _isEmpty,

            toUser(user) {
                return toUser(user)
            },

            handleFollow(value) {
                return this.follow({ username: this.user.username, value })
            },
        },
    }
</script>

<style lang="scss">
.user {
    &-avatar {
        position: absolute;
        top: -22%;
        left: 50%;
        left: calc(50% - 37px);
        background-color: #fff;
        border-radius: 50%;

        @media screen and (max-width: 768px) {
            left: calc(50% - 30px);
        }

        &.member-top {
            top: -19%;
        }

        a {
            width: 75px;
            height: 75px;
            border: 1px solid #ccc;
            border-radius: 50%;
            text-decoration: none;

            .avatar--xl {
                width: 75px;
                height: 75px !important;
                max-height: 75px !important;
            }

            @media screen and (min-width: 480px) and (max-width: 768px) {
                width: 60px;
                height: 60px;

                .avatar--xl {
                    width: 60px;
                    height: 60px !important;
                    max-height: 60px !important;
                }
            }
        }
    }

    & .user-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        padding: 50px 15px 20px 15px;
        border-radius: 16px;
        border: 1px solid #ccc;
        background-color: #fff;

        &--star {
            width: 38px;
            height: 38px;
            margin-right: 8px;
        }

        &--view, &--name {
            color: #000;
            font-size: 20px;
            font-weight: 700;

            @media screen and (max-width: 768px) {
                font-size: 16px;
            }
        }

        &--name {
            display: block;
            width: 100%;
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        &--icon {
            gap: 8px;
            color: #808080;
            font-weight: 700;
            font-size: 14px;

            &---star {
                width: 16px;
                height: 16px;
                margin-right: 3px;
                margin-bottom: 3px;
            }
        }

        &--follow {
            padding: 8px 24px;
            border-radius: 8px;
            background: #D9D9D9;
            font-weight: 700;

            @media screen and (max-width: 768px) {
                font-size: 12px;
            }

            &.following {
                color: #fff;
                background: #227FF3;

                  &:hover {
                      border-color:  darken(#227FF3, 10%);
                      background-color: darken(#227FF3, 10%);
                }
            }

            &:hover {
                color: #fff;
                background: #227FF3;
            }
        }
    }
}

.theme-dark {
    .user {
        & .user-card {
            background-color: #282828;
            border-color: #A5A5A5;

            &--view, &--name {
                color: #fff
            }
        }
    }
}
</style>
