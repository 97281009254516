// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="post-header">
        <div v-if="post.published_at" class="post-meta d-flex align-items-center flex-wrap">
            <UserInline
                :user="post.user.data"
                :show-avatar="false"
                :show-username="false"
            />

            <div class="text-muted">
                <button
                    ref="clipboard"
                    v-tooltip
                    class="icon-btn btn-link btn-copy ml-05 p-0"
                    :title="$t('copyURL')"
                >
                    <i class="fa fa-link" aria-hidden="true" />
                </button>
            </div>
        </div>

        <div class="post-title-box d-flex align-items-center">
            <h1 class="post-title-header word-break">
                <slot name="title">
                    <span v-if="post.published_at && post.is_video">
                        <i class="fa fa-play-circle video-indicator" aria-hidden="true" />
                    </span>
                    <nuxt-link :to="toPost()" class="link">
                        {{ post.title }}
                    </nuxt-link>
                </slot>
            </h1>

            <tags
                :tags="post.tags.data"
                :promoted="post.promoted"
                :trending="post.trending"
                :translation="post.translation_source != null"
                :system="post.system_tag"
            />
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import Clipboard from 'clipboard'
    import { post as toPost, user as toUser, absolute } from '~/utils/url'
    import Tags from './widgets/Tags.vue'
    import UserInline from '~/components/shared/user/Inline.vue'

    export default {
        name: 'PostHeader',

        serverCacheKey: props => props.post.slug,

        components: {
            Tags,
            UserInline,
        },
        props: {
            post: {
                type: Object,
                required: true,
            },
        },

        computed: {
            ...mapState('auth', ['user']),

            isAuthor() {
                return this.user ? (this.post.user.data.id === this.user.id) : false
            },
        },

        mounted() {
            if (this.$refs.clipboard) {
                const text = () => absolute(toPost(this.post))
                this.$clipboard = new Clipboard(this.$refs.clipboard, { text })
            }
        },

        destroyed() {
            if (this.$clipboard) {
                this.$clipboard.destroy()
                this.$clipboard = null
            }
        },

        methods: {
            toPost() {
                return toPost(this.post)
            },
            toUser() {
                return toUser(this.post.user.data)
            },
        },
    }
</script>

<style lang="scss">
    @import "../../assets/sass/bootstrap/colors";
    @import "../../assets/sass/bootstrap/fonts";

    .post-header {
        .post-meta {
            font-size: $font-size-xs;
        }

        .text-marked {
            margin-left: 0.5rem;
        }

        .video-indicator {
            font-size: 1rem;
            color: $success;
            margin-right: .3rem;
        }

        .btn-copy {
            cursor: pointer;
            font-size: $font-size-xs;

            .fa {
                font-size: .7rem;
                color: $gray-light;
                transform: rotate(-45deg);

                &:hover {
                    color: $primary;
                }
            }
        }

        .post-author-avatar {
            margin-bottom: 0;
            margin-right: .5rem;
            align-self: flex-start;
        }

        .post-title-box {
            flex-wrap: wrap;
            word-wrap: break-word;
            word-break: break-word;

            h1 {
                padding-right: .5rem;
                color: $dark;
                font-size: 1.1rem;

                a {
                    color: $dark !important;
                }

                &:hover a {
                    color: $primary !important;
                }
            }
        }

        .post-title-header{
            white-space: normal;
            word-break: break-word;
        }
    }

    .theme-dark {
        .post-header .post-title-box h1 a {
            color: #c9d1d9 !important;
        }
    }
</style>
