// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="youtube-player">
        <iframe
            id="ytplayer"
            type="text/html"
            width="100%"
            height="auto"
            :src="videoSrc"
            frameborder="0"
        />
    </div>
</template>

<script>

    export default {
        props: {
            url: {
                type: String,
                required: true,
            },
        },
        computed: {
            videoSrc() {
                const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
                const match = this.url.match(regExp)
                const id = (match && match[7].length === 11) ? match[7] : false
                return `https://www.youtube.com/embed/${id}`
            },
        },
    }
</script>
