// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="series-header">
        <div v-if="series.published_at" class="series-meta d-flex align-items-center flex-wrap">
            <UserInline
                :user="series.user.data"
                :show-avatar="false"
                :show-username="false"
            />

            <div class="text-muted">
                <span v-tooltip :title="series.published_at | format-time">
                    {{ series.published_at | humanize-time }}
                </span>

                <button
                    ref="clipboard"
                    v-tooltip
                    class="icon-btn btn-link btn-copy ml-05 p-0"
                    :title="$t('copyURL')"
                >
                    <i class="fa fa-link" aria-hidden="true" />
                </button>
            </div>
        </div>

        <div class="series-title-box d-flex align-items-center">
            <h1 class="series-title-header word-break">
                <slot name="title">
                    <nuxt-link :to="toSeries()" class="link">
                        {{ series.title }}
                    </nuxt-link>
                </slot>
            </h1>

            <tags :tags="series.tags.data" />
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'
    import Clipboard from 'clipboard'
    import { series as toSeries, absolute } from '~/utils/url'
    import Tags from './widgets/Tags.vue'
    import UserInline from '~/components/shared/user/Inline.vue'

    export default {
        components: {
            Tags,
            UserInline,
        },

        props: {
            series: {
                type: Object,
                required: true,
            },
        },

        computed: {
            ...mapState('auth', ['user']),
            ...mapGetters('entities/users', ['get']),

            isAuthor() {
                return this.user ? (this.series.user.data.id === this.user.id) : false
            },
        },

        mounted() {
            if (this.$refs.clipboard) {
                const text = () => absolute(toSeries(this.series))
                this.$clipboard = new Clipboard(this.$refs.clipboard, { text })
            }
        },

        destroyed() {
            if (this.$clipboard) {
                this.$clipboard.destroy()
                this.$clipboard = null
            }
        },

        methods: {
            toSeries() {
                return toSeries(this.series)
            },
        },
    }
</script>

<style lang="scss">
    @import "../../assets/sass/bootstrap/colors";
    @import "../../assets/sass/bootstrap/fonts";

    .series-header {
        .series-meta {
            font-size: $font-size-xs;
        }

        .text-marked {
            margin-left: 0.5rem;
        }

        .btn-copy {
            cursor: pointer;
            font-size: $font-size-xs;

            .fa {
                font-size: .7rem;
                color: $gray-light;
                transform: rotate(-45deg);

                &:hover {
                    color: $primary;
                }
            }
        }

        .series-author-avatar {
            margin-bottom: 0;
            margin-right: .5rem;
            align-self: flex-start;
        }

        .series-title-box {
            flex-wrap: wrap;
            word-wrap: break-word;
            word-break: break-word;

            h1 {
                padding-right: .5rem;
                color: $dark;
                font-size: 1.1rem;

                a {
                    color: $dark !important;
                }

                &:hover a {
                    color: $primary !important;
                }
            }
        }

        .series-title-header{
            white-space: normal;
            word-break: break-word;
        }
    }

    .theme-dark {
        .series-header .series-title-box h1 a {
            color: #c9d1d9 !important;
        }
    }
</style>
