<template>
    <div class="d-flex flex-column post-pin-item">
        <div class="position-relative">
            <div class="post-pin-thumbnail first-pin">
                <img :src="post.thumbnail_url" alt="thumbnail">
            </div>
            <div
                :class="post.is_video ? 'avt-author-video' : 'avt-author-post-pin'"
                class="position-absolute d-flex flex-column author-info"
            >
                <div class="d-flex align-items-center">
                    <div class="username-user d-flex align-items-center">
                        <Avatar
                            :object="user"
                            :image="user.avatar"
                            :is-user="true"
                            :level-partner="user.level_partner"
                            :partner-width="42"
                            :partner-hight="42"
                            :size="postSmall ? 'sm' : 'lg-mini'"
                        />
                        <span
                            class="px-05 sidebar__feed-item__subtitle"
                            :class="postSmall ? 'username-size-small' : 'username-size-medium'"
                        >
                            {{ user.name }}
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="ml-1 d-flex justify-content-end"
            :class="{
                'post-meta-size-small': postSmall,
                'post-meta-size-medium': !postSmall,
                'my-1': postSmall && !post.is_video
            }"
        >
            <div class="text-muted">
                <span
                    v-tooltip
                    :title="post.published_at | format-time"
                    class="mr-05"
                >
                    {{ post.published_at | humanize-time }}
                </span>

                <ReadingTime
                    v-if="!post.is_video"
                    :reading-time="post.reading_time"
                    class="is-divide text-muted"
                />
            </div>
        </div>

        <div class="d-flex align-items-center justify-content-between">
            <h2 v-if="postSmall" class="text-dark">
                {{ post.title }}
            </h2>
            <h1 v-else class="text-dark">
                {{ post.title }}
            </h1>
        </div>
        <div v-if="!postSmall" class="contents_short-org" :class="{'text-white': isVideoPin}">
            <MarkdownPure :contents="post.contents_short" />
        </div>
        <div :class="{ 'mt-05': !postSmall }">
            <PostTags :tags="tags.tags" :size="postSmall ? 'mini' : 'medium'" class="d-flex align-items-center" />
        </div>

        <div class="d-flex footer-post-item mt-05">
            <Stats :stats="stats" />
        </div>
    </div>
</template>

<script>
    import _get from 'lodash/get'
    import { post as toPost, user as toUser } from '~/utils/url'
    import Avatar from '~/components/shared/Avatar/Avatar.vue'
    import PostTags from '~/components/posts/widgets/Tags.vue'
    import Stats from '~/components/subdomains/shared/Stats.vue'
    import ReadingTime from '~/components/posts/widgets/ReadingTime.vue'

    export default {
        components: {
            Avatar,
            PostTags,
            Stats,
            ReadingTime,
        },
        props: {
            post: {
                type: Object,
                required: true,
            },
            isVideoPin: {
                type: Boolean,
                default: false,
            },
            postSmall: {
                type: Boolean,
                default: false,
            },
            sizeTitle: {
                type: String,
                default: 'small',
            },
        },
        computed: {
            video() {
                return this.post.video
            },
            url() {
                return toPost(this.post)
            },
            toUser() {
                return toUser(this.user)
            },
            user() {
                const user = _get(this.post, 'user.data')
                return typeof user === 'object' && !!user
                    ? user
                    : this.$store.getters['entities/users/get'](user || this.post.user_id)
            },
            stats() {
                return [
                    {
                        name: this.$t('question.views').concat(`${this.post.views_count}`),
                        src: '/images/icon-eye.png',
                        value: this.post.views_count,
                    },
                    {
                        name: this.$t('question.votes').concat(`${this.post.points}`),
                        src: '/images/icon-like.png',
                        value: this.post.points,
                    },
                    {
                        name: this.$t('userInline.clips').concat(`${this.post.clips_count}`),
                        src: '/images/icon-bookmark.png',
                        value: this.post.clips_count,
                    },
                    {
                        name: this.$t('question.comments').concat(`${this.post.comments_count}`),
                        src: '/images/icon-cmt.png',
                        value: this.post.comments_count,
                    },
                ]
            },
            tags() {
                return {
                    tags: this.post.tags ? this.post.tags.data : null,
                    promoted: this.post.promoted,
                    trending: this.post.trending,
                    system: this.post.system_tag,
                }
            },
        },

    }
</script>

<style lang="scss">
@import "~/assets/sass/bootstrap/borders";

.theme-dark {
    .text-dark {
        color: #fff !important;
    }

    .contents_short-org .md-contents p {
        color: #e4e6eb;
    }
}
</style>
