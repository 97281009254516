// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div :class="modeThemeSubdomain">
        <div v-if="loading" class="text-center text-muted">
            <i class="fa fa-circle-o-notch fa-spin" aria-hidden="true" />
            <span>Loading comments...</span>
        </div>

        <comments-thread
            v-else
            v-jump-to-route-hash
            :commentable="commentable"
            store="profile/contact"
        />
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import { CommentableType } from 'viblo-sdk/libs/interactions'
    import CommentsThread from '~/containers/comments/Thread/Index.vue'

    export default {
        components: {
            CommentsThread,
        },
        computed: {
            ...mapState('profile', {
                loading(state) {
                    return state.contact.loading
                },

                commentable(state) {
                    return {
                        type: CommentableType.User,
                        hashId: state.profile.username,
                        id: state.profile.id,
                    }
                },

                modeThemeSubdomain() {
                    return this.$store.state.typeControl.modeTheme
                },
            }),
        },
    }
</script>

<style lang="scss">
.theme-dark {
  .list-transition {
    .post-author__name,
    .text-muted {
      color: #FFFFFF !important;
    }

    footer {
      a {
        color: #FFFFFF !important;
      }
    }
  }

  .tabs__link {
    color: #FFFFFF !important;

    &::after {
      background-color: #FFFFFF;
    }
  }

  textarea {
    background-color: #000000 !important;
    border-color: #FFFFFF !important;
    color: #FFFFFF !important;
  }

  .comment-form__actions {
    button {
      background-color: #A7A7A7 !important;
      border-color: #A7A7A7 !important;
      color: #000000 !important;
    }
  }

  .reply {
    border-top: 1px solid #FFFFFF;
  }

  .comment-threads {
    background-color: #000000 !important;
  }
}
</style>
