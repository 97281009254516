// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import _join from 'lodash/join'
import { absoluteUrl, routeToUser } from '~/lib/functions'
import { image as imageUrl } from '~/utils/url'

const getDescription = (user) => {
    if (user.posts_count > 0) {
        return `${user.name} - who has ${user.reputation} reputations, ${user.posts_count} published posts in the topics: ${_join(user.posts_stats_tags.slice(0, 5), ', ')}`
    }

    return `Read posts from ${user.name} and thousands of authors on Viblo`
}

const structureData = user => ({
    '@context': 'https://schema.org',
    '@type': 'User',
    url: absoluteUrl(routeToUser(user)),
    avt: imageUrl(user.avatar),
    description: getDescription(user),
})

const userSEO = (user) => {
    const title = `${user.name} - Viblo`
    const description = getDescription(user)

    const meta = [
        { hid: 'og:type', property: 'og:type', content: 'article:author' },
        { hid: 'og:url', property: 'og:url', content: absoluteUrl(routeToUser(user)) },
        { hid: 'og:title', property: 'og:title', content: title },
        { hid: 'og:image', property: 'og:image', content: imageUrl(user.avatar, 'full') },
        { hid: 'og:description', property: 'og:description', content: description },
        // twitter
        { hid: 'twitter:description', property: 'og:description', content: description },
        { hid: 'twitter:title', name: 'twitter:title', content: title },
        { hid: 'twitter:card', name: 'twitter:card', content: 'summary' },
        { hid: 'robots', name: 'robots', content: user.banned_at ? 'noindex,nofollow' : 'index,follow' },
    ]

    return {
        title,
        meta,
        script: [
            {
                type: 'application/ld+json',
                json: structureData(user),
            },
        ],
    }
}

export { userSEO }
