// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="border-tag-item">
        <div class="tag-item-profile">
            <div class="tag-item-image">
                <nuxt-link :to="to">
                    <img
                        :src="tag.image"
                        :alt="tag.name"
                    >
                </nuxt-link>
            </div>

            <tag-header
                :tag="tag"
                :can-change="isCollaborator"
                :nuxt-link="true"
                class="tag-name overflow-hidden h3 item-tag-profile"
            />
        </div>
        <div class="stast-tag-item d-flex flex-column align-items-center justify-content-center mt-05">
            <span class="name-tag-item">
                {{ tag.name }}
            </span>

            <div class="d-flex mb-05 justify-content-around w-100">
                <span
                    v-for="(stat, index) in stats"
                    :key="index"
                    v-tooltip
                    :title="stat.name"
                    class="d-flex stats-item text-muted"
                >
                    <img
                        :src="stat.src"
                        class="stats-item__icon mr-05"
                    >
                    {{ stat.value | formatNumber }}
                </span>
            </div>

            <subscribe
                ref="subscribe"
                :send="handleSubscribe"
                :subscribable="{ type: 'Tag', 'id': tag.slug }"
            >
                <button
                    :class="tag.following ? 'btn-primary-new' : 'btn-white'"
                    class="btn btn-small-padding btn-follow-subdomain-small font-weight-bold"
                    @click="$refs.subscribe.handle(!tag.following)"
                >
                    {{ tag.following ? $t('getStarted.follow.following') : $t('getStarted.follow.follow') }}
                </button>
            </subscribe>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import { routeToTag as toTag } from '~/lib/functions'
    import Subscribe from '~/components/widgets/Subscribe.vue'
    import TagHeader from '~/components/subdomains/tags/TagHeader.vue'

    export default {
        components: {
            Subscribe,
            TagHeader,
        },

        props: {
            tag: {
                type: Object,
                required: true,
            },
        },

        computed: {
            ...mapGetters('auth', ['isCollaborator']),

            to() {
                return toTag(this.tag)
            },

            stats() {
                return [
                    {
                        name: this.$t('userInline.posts').concat(`${this.tag.posts_count}`),
                        src: this.$store.state.typeControl.modeTheme === 'default' ? '/images/icon-posts-tag.png' : '/images/icon-posts-tag-white.png',
                        value: this.tag.posts_count,
                    },
                    {
                        name: this.$t('userInline.questions').concat(`${this.tag.questions_count}`),
                        src: this.$store.state.typeControl.modeTheme === 'default' ? '/images/icon-questions-tag.png' : '/images/icon-questions-tag-white.png',
                        value: this.tag.questions_count,
                    },
                    {
                        name: this.$t('userInline.followers').concat(`${this.tag.followers_count}`),
                        src: '/images/icon-follower-tag.png',
                        value: this.tag.followers_count,
                    },
                ]
            },
        },

        methods: {
            ...mapActions('entities/tags', ['subscribe']),

            handleSubscribe(value) {
                return this.subscribe({ tag: this.tag, value })
            },
        },
    }
</script>

<style lang="scss">
    @import "~/assets/sass/bootstrap/mixins";
    @import "~/assets/sass/bootstrap/variables";

    .border-tag-item {
        justify-content: center;
        padding-top: 0.5rem;
        padding-bottom: 0.25rem;

        .tag-item-profile {
            display: flex;
            justify-content: center;
            .tag-item-image {
                margin-left: 1.5rem;
                height: 100px;
                width: auto;
                img {
                    width: 100px;
                    border-radius: $base-border-radius;
                }
            }

            .tag-name {
                color: $dark;
                max-width: 100%;
            }

            .tag-counter {
                color: $gray-light;
            }

            .item-tag-profile {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }

        .name-tag-item {
            font-weight: 600;
            font-size: 17px;
        }


        .stats-item {
            max-width: 45px;
        }

        .btn-primary-new {
            color: #fff;
            background-color: #0EA8FF;
            font-size: 12px;
            font-weight: 700;
            border-radius: 9px;
            border: none;
        }
    }

    .theme-dark {
        .name-tag-item, .stats-item {
            color: white !important;
        }

        .solved {
            color: #ff00fb !important;
        }

        .btn-primary-new {
            color: #000;
            background-color: white;
        }

        .btn-white {
            border: 1px solid white;
            color: white;
            background-color: #000;
        }
    }
</style>
