// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div
        :style="style"
        :class="{ 'content-box--expanded': expanded }"
        class="content-box"
        @click="expand"
    >
        <slot />
    </div>
</template>

<script>
    export default {
        props: {
            height: Number,
        },

        data: () => ({
            expanded: false,
        }),

        computed: {
            style() {
                return this.height
                    ? { maxHeight: `${this.height}px` }
                    : {}
            },
        },

        methods: {
            expand() {
                this.expanded = true
            },
        },
    }
</script>

<style lang="scss">
    .content-box {
        max-height: 300px;
        overflow-y: hidden;
        padding-bottom: 1rem;

        &:not(&--expanded) {
            cursor: pointer;
            mask-image: linear-gradient(180deg,#000 60%,transparent);
        }

        &--expanded {
            max-height: initial !important;
        }
    }
</style>
