<template>
    <div v-if="posts.length > 0 || isOwner" class="post-feed-org py-2 px-1 mb-3">
        <div class="subdomain-pin-post mb-4">
            <div class="d-flex justify-content-between align-items-center">
                <p class="title-all-posts">
                    <span class="pin-icon"><img src="@/static/images/icon-pin.svg"></span>
                    {{ $t('subdomain.PostPin') }}
                </p>
                <el-button
                    v-if="isOwner"
                    :title="$t('pinPostOrg.titlePinPost')"
                    size="small"
                    class="btn-set-pinned-post"
                    @click="$refs.listPosts.open()"
                >
                    <img class="icon-light" src="/images/Vector.svg">
                    <img class="icon-dark" src="/images/icon-menu.svg">
                </el-button>
            </div>
            <div v-if="posts.length >= 3" class="row mb-1">
                <div class="col-md-8">
                    <PostFeedItem :post="posts[0]" :post-small="false" :size-title="'small'" />
                </div>
                <div class="col-md-4 d-flex flex-column justify-content-between mini-posts">
                    <div class="mb-1">
                        <PostFeedItem :post="posts[1]" :size-title="'mini'" post-small />
                    </div>
                    <PostFeedItem :post="posts[2]" :size-title="'mini'" post-small />
                </div>
            </div>
            <div v-if="posts.length >= 6" class="row mini-posts">
                <div
                    v-for="(post, index) in posts"
                    :key="post.id"
                    class="col-md-4"
                >
                    <PostFeedItem
                        v-if="index >= 3 && index < 6"
                        :post="post"
                        :post-small="true"
                        :size-title="'mini'"
                    />
                </div>
            </div>
        </div>

        <ListPostsDialog
            ref="listPosts"
            :pinned-post="posts"
            :subdomain="subdomain"
            :is-org-subdomain="isOrgSubdomain"
            @set-posts-pin="setPostsPin"
        />
    </div>
</template>

<script>
    import { mixin as clickaway } from '@tranxuanthang/vue-clickaway'
    import { pinPostsOrganization } from '~/api/organizations'
    import { pinPostsProfile } from '~/api/users'
    import PostFeedItem from './PostPinItem.vue'
    import ListPostsDialog from './widgets/ListPostsDialog.vue'

    export default {
        components: {
            PostFeedItem,
            ListPostsDialog,
        },

        mixins: [clickaway],

        props: {
            orgPosts: {
                type: Array,
                default: () => [],
            },
            posts: {
                type: Array,
                default: () => [],
            },
            isOwner: {
                type: Boolean,
                default: false,
            },
            subdomain: String,
            isOrgSubdomain: {
                type: Boolean,
                default: true,
            },
        },

        data() {
            return {
                loading: false,
                visible: false,
            }
        },

        methods: {
            hidePopover() {
                if (!this.$ref.listPosts || !this.$ref.listPosts.show) {
                    this.visible = false
                }
            },
            async setPostsPin(postIds) {
                try {
                    this.loading = true
                    const res = this.isOrgSubdomain
                        ? await pinPostsOrganization(this.subdomain, { data: postIds })
                        : await pinPostsProfile(this.subdomain, { data: postIds })

                    if (res.status === 200) {
                        this.$message.success('successfull')
                        this.posts = res.data.data
                        this.loading = false
                    }
                    this.$refs.listPosts.close()
                } catch {
                    this.$message.error('Something went wrong!')
                }
            },
        },
    }
</script>

<style lang="scss">
.subdomain-pin-post {
    .title-all-posts span {
        display: inline-block;
        color: red;
    }
    .first-pin img {
        width: 100% !important;
    }
    .btn-set-pinned-post {
        border: none;
        padding: 0;
        height: 100%;
    }
    .mini-posts {
        .footer-post-item {
            max-width: 70%;
        }
    }
}

.post-pin__dark-mode {
  .subdomain-pin-post {
    .title-all-posts {
      color: #FFFFFF;
    }

    .title-all-posts span {
        display: inline-block;
        color: red;
    }
    .first-pin img {
        width: 100% !important;
    }
    .btn-set-pinned-post {
        border: none;
        padding: 0;
        height: 100%;
    }
  }
}
</style>

<style scoped lang="scss">
.post-pin__dark-mode {
  background-color: #000000 !important;
}
</style>

<style lang="scss" scoped>
 .post-feed-org {
    .icon-light {
        display: block;
    }

    .icon-dark {
        display: none;
    }
}

.theme-dark {
    .post-feed-org {
        background-color: #000;

        .title-all-posts {
            color: #fff;
        }

        .btn-set-pinned-post {
            background: transparent;
        }

        .icon-light {
            display: none;
        }

        .icon-dark {
            display: block;
        }
    }
}
</style>
