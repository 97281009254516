// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<script>
    export default {
        functional: true,
        render: (h, { props }) => h('div', { class: 'section-title-line' }, [
            h('h4', { class: 'text-uppercase' }, props.title),
            h('hr', { class: 'filler-line' }),
        ]),
    }
</script>
