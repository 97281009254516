// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <empty v-if="data.length <= 0">
        <p> {{ $t('empty') }} </p>
    </empty>

    <div v-else>
        <FeedItem
            v-for="(list, index) in data"
            :key="index"
            :list="list"
            :is-owner="isOwner"
        >
            <img
                src="/images/icon-series.png"
                alt="Bookmarked Image"
                class="icon-series-profile"
            >
        </FeedItem>
    </div>
</template>

<script>
    import Empty from '~/components/shared/Empty.vue'
    import FeedItem from '~/components/subdomains/series/ListItem.vue'

    export default {
        components: {
            Empty,
            FeedItem,
        },

        props: {
            data: Array,
            shouldHighlight: {
                type: Function,
                default: () => false,
            },
        },
    }
</script>

<style lang="scss" scoped>
.icon-series-profile {
    position: absolute;
    top: -1px;
    right: 0;
    height: 2.5rem;
    width: 3rem;
}
</style>
