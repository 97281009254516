// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="user-answer-item">
        <nuxt-link :to="url" class="link">
            <h2 class="user-answer-item__title">
                {{ question.title }}
            </h2>
        </nuxt-link>

        <div class="user-answer-item__meta mb-1">
            <div class="user-answer-item__user d-flex align-items-center">
                <Avatar
                    :image="user.avatar"
                    :username="user.username"
                    size="sm"
                />

                <div class="user-answer-item__user__info">
                    <nuxt-link :to="userPage" :class="{ 'is-banned': user.banned_at }">
                        {{ user.name }}
                    </nuxt-link>
                </div>
            </div>
            <div v-tooltip class="text-muted user-answer-item__time" :title="answer.created_at | format-time">
                {{ $t('profile.answered') }} {{ answer.created_at | humanize-time }}
            </div>
        </div>

        <ContentBox>
            <Markdown :contents="answer.contents" />
        </ContentBox>

        <div class="user-answer-item__footer">
            <ItemVote
                :score="answer.points"
                :rated-value="answer.rated_value"
                :send="sendVote.bind(null, answer.hash_id)"
            />
        </div>
    </div>
</template>

<script>
    import _get from 'lodash/get'
    import { user as toUser, question as toQuestion } from '~/utils/url'
    import ItemVote from '~/components/votes/ItemVote.vue'
    import Avatar from '~/components/shared/user/Avatar.vue'
    import Markdown from '~/components/contents/MarkdownPure.vue'
    import ContentBox from '~/components/contents/ContentBox.vue'

    export default {
        components: {
            Avatar,
            ItemVote,
            Markdown,
            ContentBox,
        },

        props: {
            answer: {
                type: Object,
                required: true,
            },

            sendVote: {
                type: Function,
                required: true,
            },
        },

        computed: {
            question() {
                return _get(this.answer, 'question.data', {})
            },

            user() {
                return _get(this.answer, 'user.data', {})
            },

            url() {
                return `${toQuestion(this.question)}#answer-${this.answer.hash_id}`
            },

            userPage() {
                return toUser(this.user)
            },
        },
    }
</script>

<style lang="scss">
    @import "~assets/sass/bootstrap/colors";

    .user-answer-item {
        padding: 1rem;
        border-radius: 1rem;
        background: #fff;

        &__title {
            font-family: 'Roboto';
            font-weight: 700;
            line-height: normal;
            margin-bottom: 1rem;
        }

        &__meta {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }

        &__user {
            background: #227FF3;
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
            padding: 4px 1rem;
            margin-left: -1rem;
            margin-right: 1.5rem;

            img {
                border: 1px solid #fff;
            }
        }

        &__user__info {
            padding-left: 1rem;
            padding-right: 1rem;

            a {
                font-family: 'Roboto';
                font-weight: 700;
                color: #fff;
            }
        }

        &__time {
            font-size: 14px;
        }
    }

    .theme-dark {
        .user-answer-item {
            background: #282828;
            color: #fff;

            .icon-btn {
                color: #fff;
            }
            .comment-points {
                color: #fff !important;
            }
        }
    }
</style>
