// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="flag-content">
        <slot>
            <span v-if="reported" class="reported">{{ $t('profile.reportDialog.reported') }}</span>
            <span
                v-else
                class="report"
                aria-haspopup="true"
                @click="openReportDialog"
            >
                <i class="fa fa-flag" aria-hidden="true" /> {{ $t('profile.report') }}
            </span>
        </slot>

        <v-dialog
            ref="dialog"
            :show.sync="show"
            :title="$t('profile.reportDialog.title')"
            size="small"
            @close="reset"
        >
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-position="top"
                size="mini"
            >
                <el-form-item prop="reason" :label="$t('profile.reportDialog.label')">
                    <el-radio-group v-model="form.reason" class="mb-05">
                        <el-radio name="reason" label="1" class="d-block mb-05">
                            {{ $t('profile.reportDialog.spam') }}
                        </el-radio>
                        <el-radio name="reason" label="2" class="d-block ml-0 mb-05">
                            {{ $t('profile.reportDialog.rulesViolation') }}
                        </el-radio>
                        <el-radio name="reason" label="3" class="d-block ml-0">
                            {{ $t('profile.reportDialog.harassment') }}
                        </el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item prop="source">
                    <el-input
                        v-model="form.source"
                        type="textarea"
                        :placeholder="$t('profile.reportDialog.comment')"
                    />
                </el-form-item>
            </el-form>

            <div class="d-flex align-items-center justify-content-between">
                <a
                    href="/terms"
                    class="link"
                    target="_blank"
                    rel="noopener"
                >{{ $t('profile.reportDialog.terms') }}</a>

                <el-button
                    :loading="processing"
                    size="small"
                    @click="submit($refs.form, report)"
                >
                    {{ $t('profile.reportDialog.reportBtn') }}
                </el-button>
            </div>
        </v-dialog>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import formMixin from '~/utils/form/mixin'

    export default {
        mixins: [formMixin],

        props: {
            sendReport: {
                type: Function,
                required: true,
            },
        },

        data() {
            return {
                rules: {
                    reason: { required: true, message: this.$t('profile.reportDialog.validations.reason') },
                },
                show: false,
                reported: false,
                processing: false,
                form: {
                    reason: null,
                    source: null,
                },
            }
        },

        computed: {
            ...mapGetters('auth', ['authenticated']),
        },

        methods: {

            ...mapActions('auth', ['showAuthDialog']),

            open() {
                this.show = true
            },

            close() {
                this.show = false
            },

            openReportDialog() {
                if (!this.authenticated) {
                    this.showAuthDialog()
                } else {
                    this.open()
                }
            },

            async report() {
                try {
                    this.processing = true
                    await this.sendReport(this.form)
                    this.close()
                    this.reported = true
                    this.$message(this.$t('postBody.postContent.dialogReport.success'))
                } catch (error) {
                    this.close()
                    this.$message.error(this.$t('postBody.postContent.dialogReport.error'))
                    throw error
                } finally {
                    this.processing = false
                }
            },

            reset() {
                this.$refs.form.resetFields()
            },
        },
    }
</script>

<style lang="scss">
    .report {
        cursor: pointer;
        transition: color 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    }

    .report:hover {
        color: #F00;
    }

    .reported {
        color: #F00;
    }

    .theme-dark {
        .report {
            color: white;
        }
    }
</style>
