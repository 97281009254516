// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="score">
        <button
            v-tooltip
            :class="upClass"
            :title="title('Upvote')"
            class="icon-btn vote"
            @click.prevent="vote('up')"
        >
            <i :class="`fa fa-chevron-up`" aria-hidden="true" />
        </button>

        <span
            :class="[
                'comment-points',
                (ratedValue != 0 && ratedValue != null ) ? 'text-primary text-bold' : 'text-muted'
            ]"
        >
            {{ score | signed }}
        </span>

        <button
            v-tooltip
            :class="downClass"
            :title="title('Downvote')"
            class="icon-btn vote"
            @click.prevent="vote('down')"
        >
            <i :class="`fa fa-chevron-down`" aria-hidden="true" />
        </button>
    </div>
</template>

<script>
    import { VoteDir } from 'viblo-sdk/libs/interactions'
    import { serializeVote } from '~/utils/voting'
    import Vote from '../widgets/vote/Vote.vue'

    export default {
        mixins: [Vote],

        props: {
            score: Number,
            ratedValue: Number,
            disabled: Boolean,
        },

        computed: {
            upClass() {
                return {
                    voted: this.ratedValue > 0,
                    disabled: this.ratedValue < 0,
                }
            },

            downClass() {
                return {
                    voted: this.ratedValue < 0,
                    disabled: this.ratedValue > 0,
                }
            },

            title() {
                return this.disabled ? () => null : title => title
            },
        },

        methods: {
            vote(value) {
                const nextValue = serializeVote(value) === this.ratedValue ? VoteDir.None : value

                return this.handle(nextValue)
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import "vote-color";
</style>
