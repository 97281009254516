// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="pt-2 pb-2 profile-following">
        <i
            v-if="current.length <= 0 && isLoading"
            class="fa fa-circle-o-notch fa-spin sb-icon profile-following--loading"
            aria-hidden="true"
        />
        <div v-else class="profile-following--content pt-4">
            <div class="pl-4">
                <FollowingFilter is-subdomain />
            </div>

            <div class="members">
                <div v-if="current.length <= 0" class="text-center">
                    <empty>
                        {{ $t('postBody.postContent.postAuthor.notFollowing') }}
                    </empty>
                </div>
                <div v-else class="members-list pt-2">
                    <div
                        v-for="(id, index) in current"
                        :key="index"
                        class="members-list--card"
                    >
                        <UserItem :user-id="id" :follow="follow" />
                    </div>
                </div>
            </div>

            <client-only>
                <InfiniteLoading :distance="50" spinner="spiral" @infinite="load">
                    <span slot="no-results" />
                    <span slot="no-more" />
                </InfiniteLoading>
            </client-only>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import InfiniteLoading from 'vue-infinite-loading'
    import { handlePageError, getContentSubDomain } from '~/utils/pages'
    import FollowingFilter from '~/components/shared/user/FollowingFilter.vue'
    import UserItem from '~/components/subdomains-theme-2/shared/UserItem.vue'

    export default {
        name: 'UserFollowing',

        components: {
            FollowingFilter,
            InfiniteLoading,
            UserItem,
        },

        data() {
            return {
                isLoading: false,
            }
        },

        scrollToTop: true,

        computed: {
            ...mapState('profile/following', ['current', 'pagination']),
        },

        // eslint-disable-next-line consistent-return
        async fetch() {
            this.isLoading = true

            const req = this.$nuxt.context.ssrContext?.req
            const store = this.$nuxt.context.store
            const params = this.$nuxt.context.route.params
            const error = this.$nuxt.context.error
            const page = this.pagination?.current_page ? this.pagination?.current_page + 1 : 1
            const host = process.client ? window.location.host : req.headers.host
            const username = store.state.config.vars.is_special_profile ? getContentSubDomain(host) : params.user
            const limit = store.state.settings.perPage - (store.state.settings.perPage % 3)

            try {
                await store.dispatch('profile/following/fetch', { username, params: { limit, page, isLoadMore: true } })

                this.isLoading = false
            } catch (err) {
                this.isLoading = false
                return handlePageError(error, err)
            }
        },

        beforeDestroy() {
            this.$store.dispatch('profile/following/resetData')
        },

        watchQuery: ['type', 'page'],

        methods: {
            ...mapActions('profile/following', ['follow']),

            async load($state) {
                const currentPage = this.pagination?.current_page
                const totalPages = this.pagination?.total_pages

                if (currentPage < totalPages) {
                    await this.$fetch()
                    $state.loaded()
                } else {
                    $state.complete()
                }
            },
        },
    }
</script>

<style lang="scss">
.profile-following {
    &--loading {
        position: unset;
        width: 100%;
        text-align: center;
        font-size: 24px;
    }

    &--content {
        background-color: #fff;
        border-radius: 8px;
    }
}

.theme-dark {
    .profile-following {
        color: #fff;

        &--content {
            background-color: #100F09;
        }
    }
}
</style>
