// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="comment-form">
        <div class="d-flex align-item-center">
            <nuxt-link :to="toUser(user)">
                <Avatar
                    v-if="showAvatar"
                    :image="user.avatar"
                    size="md"
                    class="mr-05"
                />
            </nuxt-link>
            <span class="comment-form__username">{{ user.name }}</span>
        </div>

        <div
            v-show="'write' === activeTab"
        >
            <LiteEditor
                ref="editor"
                v-model="form[name]"
                :name="name"
                :placeholder="
                    $t('detailQuestion.comment.commentForm.writeAResponse')
                "
                rows="1"
                @keydown.ctrl.enter="store"
                @keydown.esc.stop="cancel"
            />
        </div>

        <div v-show="'preview' === activeTab" class="comment-form__preview">
            <MarkdownPure
                v-if="form[name]"
                :contents="form[name]"
            />
            <div v-else class="text-muted">
                {{ $t("detailQuestion.comment.commentForm.empty") }}
            </div>
        </div>

        <div class="comment-form__actions">
            <button
                v-show="activeTab === 'preview'"
                v-tooltip
                src="~/assets/images/write.png"
                class="preview-comments el-button--text"
                alt="preview comments"
                :title="$t('detailQuestion.comment.commentForm.write')"
                @click.prevent="changeStatusForm"
            >
                <i class="fa fa-pencil-square-o" aria-hidden="true" />
            </button>
            <button
                v-show="activeTab === 'write'"
                v-tooltip
                class="preview-comments el-button--text"
                alt="preview comments"
                :title="$t('detailQuestion.comment.commentForm.preview')"
                @click.prevent="changeStatusForm"
            >
                <i class="fa fa-eye" aria-hidden="true" />
            </button>
            <button
                v-if="showCancel"
                v-tooltip
                :title="$t(`${cancelText}`)"
                class="el-button--text"
                @click.stop="cancel"
            >
                <i class="fa fa-times-circle" aria-hidden="true" />
            </button>
            <button
                v-tooltip
                :loading="isSubmiting"
                :disabled="!form[name]"
                class="send-comments el-button--text"
                alt="send comments"
                :title="submitText"
                @click.prevent="store"
            >
                <i class="fa fa-send fa-fw" />
            </button>
            <slot name="extend" />
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import { user as toUser } from '~/utils/url'
    import { doNothing } from '~/utils/pages'

    import Avatar from '~/components/shared/Avatar.vue'
    import LiteEditor from '~/components/editor/Lite.vue'

    export default {
        components: {
            Avatar,
            LiteEditor,
        },

        props: {
            name: {
                type: String,
                default: 'contents',
            },
            value: {
                type: String,
                default: '',
            },
            submitText: {
                type: String,
                default: 'Save',
            },
            cancelText: {
                type: String,
                default: 'detailQuestion.comment.commentForm.discard',
            },
            showCancel: {
                type: Boolean,
                default: true,
            },
            showAvatar: {
                type: Boolean,
                default: true,
            },
            submit: {
                type: Function,
            },
        },

        data() {
            const now = Date.now()

            return {
                form: {
                    [this.name]: this.value,
                },

                tabs: {
                    ids: {
                        write: `write-${now}`,
                        preview: `preview-${now}`,
                    },
                    write: `#write-${now}`,
                    preview: `#preview-${now}`,
                },

                isWriting: true,
                isSubmiting: false,
                activeTab: 'write',
            }
        },

        computed: {
            ...mapState('auth', ['user']),
        },

        methods: {
            toUser,

            store() {
                this.isSubmiting = true
                const data = this.form
                this.submit({ data })
                    .then((res) => {
                        this.$emit('submit-success', res)
                    })
                    .catch((err) => {
                        this.$emit('submit-fail', err)
                    })
                    .finally(() => {
                        this.$emit('submit-finally')
                        this.isSubmiting = false
                    })
            },

            cancel() {
                if (this.$refs.editor.value.trim()) {
                    this.$confirm(
                        this.$t(
                            'detailQuestion.comment.commentForm.comfirmDiscard.content'
                        ),
                        this.$t(
                            'detailQuestion.comment.commentForm.comfirmDiscard.title'
                        ),
                        {
                            confirmButtonText: this.$t('confirm.ok'),
                            cancelButtonText: this.$t('confirm.cancel'),
                        }
                    )
                        .then(() => this.$emit('cancel'))
                        .catch(doNothing)
                } else {
                    this.$emit('cancel')
                }
            },

            writingMode(value) {
                this.isWriting = value
            },

            append(text) {
                this.form[this.name] += text
            },

            reset() {
                this.$refs.editor.reset()
            },

            focus() {
                this.$refs.editor.focus()
            },

            changeStatusForm() {
                this.activeTab = this.activeTab === 'preview' ? 'write' : 'preview'
            },
        },
    }
</script>

<style lang="scss">
.profile-subdomain {
    .comment-form {
        position: relative;
        padding: 16px;

        &__preview {
            min-height: 120px;
        }

        &__username {
            max-width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: flex;
            align-items: center;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            font-weight: 700;
        }

        &__actions {
            position: absolute;
            right: 16px;
            bottom: 16px;
            display: flex;
            justify-content: flex-end;
            gap: 4px;

            i {
                color: rgb(155, 155, 155);;
                font-size: 18px;
                cursor: pointer;
            }
        }

        .editor-lite {
            textarea {
                border: none;
            }

            &__extras {
                top: unset;
                right: unset;
                bottom: -8px;
                left: 0;
                flex-direction: row;
                margin: 0;
            }
        }
    }
}

.theme-dark {
    .profile-subdomain .comment-form {
        .editor-lite textarea {
            background-color: #202020 !important;
        }

        &__actions {
            button {
                background: transparent !important;
                border: none !important;
            }

            i {
                color: #fff;
            }
        }
    }
}
</style>
