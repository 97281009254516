var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column post-pin-item h-100",class:{
        'video-subdomain': _vm.isVideoPin,
        'justify-content-between': _vm.post.is_video || _vm.postSmall,
    }},[_c('div',{staticClass:"position-relative"},[_c('div',{staticClass:"post-pin-thumbnail first-pin"},[(!_vm.post.is_video)?_c('nuxt-link',{attrs:{"to":_vm.url}},[_c('img',{attrs:{"src":_vm.post.thumbnail_url,"alt":"thumbnail"}})]):_c('Video',{attrs:{"id":_vm.video.uuid,"provider":_vm.video.provider,"url":_vm.video.url}})],1),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column author-info",class:_vm.post.is_video ? 'avt-author-video' : 'avt-author-post-pin'},[_c('div',{staticClass:"d-flex align-items-center position-relative"},[_c('div',{staticClass:"username-user d-flex align-items-center"},[_c('Avatar',{attrs:{"object":_vm.user,"image":_vm.user.avatar,"is-user":true,"level-partner":_vm.user.level_partner,"partner-width":_vm.postSmall ? 40 : 42,"partner-hight":_vm.postSmall ? 40 : 42,"size":_vm.postSmall ? 'sm' : 'md-mini'}}),_vm._v(" "),_c('nuxt-link',{staticClass:"link",attrs:{"to":_vm.toUser}},[_c('span',{staticClass:"px-05 sidebar__feed-item__subtitle author-name",class:_vm.postSmall ? 'username-size-small' : 'username-size-medium'},[_vm._v("\n                            "+_vm._s(_vm.user.name)+"\n                        ")])])],1),_vm._v(" "),(_vm.isVideoPin)?_c('div',{staticClass:"ml-1 position-relative",class:{
                        'post-meta-size-small': _vm.postSmall,
                        'post-meta-size-medium': !_vm.postSmall,
                        'my-1': _vm.postSmall && !_vm.post.is_video
                    },staticStyle:{"bottom":"-1.2em"}},[_c('div',{staticClass:"text-muted"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip"}],staticClass:"mr-05",attrs:{"title":_vm._f("format-time")(_vm.post.published_at)}},[_vm._v("\n                            "+_vm._s(_vm._f("humanize-time")(_vm.post.published_at))+"\n                        ")]),_vm._v(" "),(!_vm.post.is_video)?_c('ReadingTime',{staticClass:"is-divide text-muted",attrs:{"reading-time":_vm.post.reading_time}}):_vm._e()],1)]):_vm._e()])]),_vm._v(" "),(_vm.isOwner && _vm.post.is_video)?_c('span',{staticClass:"pin-icon",on:{"click":function($event){return _vm.openConfirm(_vm.post)}}},[_c('div',{class:_vm.post.organization_pinned_at ? 'pinned-icon' : 'unpin-icon'})]):_vm._e()]),_vm._v(" "),(!_vm.isVideoPin && !_vm.postSmall)?_c('div',{staticClass:"ml-1 d-flex justify-content-end",class:{
            'post-meta-size-small': _vm.postSmall,
            'post-meta-size-medium': !_vm.postSmall,
            'my-1': _vm.postSmall && !_vm.post.is_video
        }},[(!_vm.postSmall)?_c('div',{staticClass:"text-muted"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip"}],staticClass:"mr-05",attrs:{"title":_vm._f("format-time")(_vm.post.published_at)}},[_vm._v("\n                "+_vm._s(_vm._f("humanize-time")(_vm.post.published_at))+"\n            ")]),_vm._v(" "),(!_vm.post.is_video)?_c('ReadingTime',{staticClass:"is-divide text-muted",attrs:{"reading-time":_vm.post.reading_time}}):_vm._e()],1):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"d-flex align-items-center justify-content-between mt-1"},[_c('PostTitleInline',{attrs:{"url":_vm.url,"title":_vm.post.title,"is-video":_vm.post.is_video,"size-title":_vm.sizeTitle}})],1),_vm._v(" "),_c('div',[(!_vm.postSmall)?_c('div',{staticClass:"contents_short-org",class:_vm.isVideoPin ? 'text-white' : 'post-pin-content-short'},[_c('MarkdownPure',{staticClass:"description-posts-pin",attrs:{"contents":_vm.convertImgToText(_vm.post.contents_short)}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"tags-list",class:{ 'mt-05': !_vm.postSmall, 'mt-05': _vm.post.is_video },style:(_vm.postSmall ? 'height: 48px' : 'max-height: 64px')},[_c('div',{directives:[{name:"popover",rawName:"v-popover:popover",arg:"popover"}],staticClass:"table-services-cell"},[_c('PostTags',{staticClass:"d-flex align-items-center",attrs:{"tags":_vm.tags.tags,"size":_vm.postSmall ? 'mini' : 'medium'}}),_vm._v(" "),(_vm.post.is_video)?_c('el-popover',{ref:"popover",attrs:{"title":("Tags (" + (_vm.tags.tags.length) + ")"),"width":"300","trigger":"hover"}},[_c('PostTags',{staticClass:"d-flex align-items-center",attrs:{"tags":_vm.tags.tags,"size":_vm.postSmall ? 'mini' : 'medium'}})],1):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"d-flex footer-post-item"},[_c('Stats',{attrs:{"stats":_vm.stats}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }