// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="post-feed-org px-1 pb-2">
        <Empty v-if="answers.length === 0">
            <p> {{ $t('empty') }} </p>
        </Empty>

        <div v-else class="user-feed__answers">
            <AnswerItem
                v-for="answer in answers"
                :key="answer.id"
                :answer="answer"
                :send-vote="voteAnswer"
            />

            <Pagination :current-page="pagination.current_page" :last-page="pagination.total_pages" />
        </div>
    </div>
</template>

<script>
    import _assign from 'lodash/assign'
    import _findIndex from 'lodash/findIndex'
    import { castVote } from 'viblo-sdk/api/voting'
    import { serializeVote } from '~/utils/voting'
    import { getAnswers } from '~/api/users'
    import Empty from '~/components/shared/Empty.vue'
    import Pagination from '~/components/subdomains/Pagination.vue'
    import AnswerItem from '~/components/feeds/AnswerItem.vue'
    import { handlePageError, getContentSubDomain } from '~/utils/pages'

    export default {
        components: {
            Empty,
            AnswerItem,
            Pagination,
        },

        watchQuery: ['page'],

        async asyncData({
            store, params, query, error, req,
        }) {
            try {
                const host = process.client ? window.location.host : req.headers.host
                const user = store.state.config.vars.is_special_profile ? getContentSubDomain(host) : params.user
                const data = await getAnswers(user, query)

                return {
                    answers: data.data,
                    pagination: data.meta.pagination,
                }
            } catch (e) {
                return handlePageError(error, e)
            }
        },

        methods: {
            async voteAnswer(hashId, value) {
                await castVote('answers', hashId, value)
                const votedAnswerIndex = _findIndex(this.answers, { hash_id: hashId })

                if (votedAnswerIndex !== -1) {
                    const answer = this.answers[votedAnswerIndex]
                    const currentVote = answer.rated_value
                    const nextVote = serializeVote(value)

                    const updatedAnswer = _assign({}, answer, {
                        rated_value: serializeVote(value),
                        points: answer.points + (nextVote - currentVote),
                    })
                    this.answers = [
                        ...this.answers.slice(0, votedAnswerIndex),
                        updatedAnswer,
                        ...this.answers.slice(votedAnswerIndex + 1),
                    ]
                }
            },
        },
    }
</script>

<style scoped lang="scss">
.dark-mode {
  color: #FFFFFF !important;
  background-color: #000000 !important;
}
</style>
