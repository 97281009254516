// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template functional>
    <div class="d-flex">
        <span
            v-for="(stat, index) in props.stats"
            :key="index"
            v-tooltip
            :title="stat.name"
            class="d-flex stats-item text-muted mr-1"
        >
            <img :src="stat.src" class="stats-item__icon">
            {{ stat.value | formatNumber }}
        </span>
        <slot name="append" />
    </div>
</template>

<script>
    export default {
        props: {
            stats: {
                type: Array,
                required: true,
            },
            isSuggestion: {
                type: Boolean,
                default: false,
            },
        },
    }
</script>

<style lang="scss">
.stats-item {
    cursor: default;

    &__icon {
        margin-right: 4px;
    }

    &__icon-home {
        margin-right: 4px;
    }
}
</style>
