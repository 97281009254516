// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <ul v-if="hasPages" class="pagination-subdomain">
        <li v-if="currentPage == 1" class="page-item">
            <a class="disabled"><i aria-hidden="true" class="fa fa-angle-left" /></a>
        </li>
        <li v-else class="page-item">
            <nuxt-link
                v-if="nuxt"
                :to="to(currentPage - 1)"
                rel="prev"
                exact
            >
                <i aria-hidden="true" class="fa fa-angle-left" />
            </nuxt-link>
            <a
                v-else
                class="cursor-pointer"
                @click.prevent="() => $emit('click-page-item', currentPage - 1)"
            >
                <i aria-hidden="true" class="fa fa-angle-left" />
            </a>
        </li>

        <li v-for="(page, index) in pages" :key="index">
            <a v-if="page === '...'" class="disabled">...</a>
            <nuxt-link
                v-if="page !== '...' && nuxt"
                :to="to(page)"
                :class="{active: page === currentPage}"
                active-class=""
            >
                {{ page }}
            </nuxt-link>
            <a
                v-else-if="page !== '...'"
                :class="{active: page === currentPage, 'cursor-pointer': true}"
                @click.prevent="() => $emit('click-page-item', page)"
            >
                {{ page }}
            </a>
        </li>

        <li v-if="currentPage == lastPage" class="page-item">
            <a class="disabled"><i aria-hidden="true" class="fa fa-angle-right" /></a>
        </li>

        <li v-else class="page-item">
            <nuxt-link
                v-if="nuxt"
                :to="to(currentPage + 1)"
                rel="next"
                exact
            >
                <i aria-hidden="true" class="fa fa-angle-right" />
            </nuxt-link>
            <a
                v-else
                class="cursor-pointer"
                @click.prevent="() => $emit('click-page-item', currentPage + 1)"
            >
                <i aria-hidden="true" class="fa fa-angle-right" />
            </a>
        </li>
    </ul>
</template>

<script>
    import flatten from 'lodash/flatten'
    import filter from 'lodash/filter'
    import isArrayLike from 'lodash/isArrayLike'
    import omit from 'lodash/omit'
    import range from 'lodash/range'

    export default {

        props: {
            path: String,
            query: Object,
            currentPage: {
                type: Number,
                default: 1,
            },
            lastPage: {
                type: Number,
                default: 1,
            },
            onEachSide: {
                type: Number,
                default: 3,
            },
            queryParam: {
                type: String,
                default: 'page',
            },
            nuxt: {
                type: Boolean,
                default: true,
            },
        },

        computed: {
            pages() {
                const window = this.get(this.onEachSide)
                return flatten(filter([
                    window.first,
                    isArrayLike(window.slider) ? ['...'] : null,
                    window.slider,
                    isArrayLike(window.last) ? ['...'] : null,
                    window.last,
                ], item => item !== null))
            },
            hasPages() {
                return this.lastPage > 1
            },
        },

        methods: {
            to(page) {
                const path = this.path || this.$route.path

                const otherParams = omit(this.query || this.$route.query, [this.queryParam])
                const query = page !== 1 ? {
                    ...otherParams,
                    [this.queryParam]: page,
                } : otherParams

                return { path, query }
            },

            get(onEachSide = 3) {
                if (this.lastPage < (onEachSide * 2) + 6) {
                    return this.getSmallSlider()
                }

                return this.getUrlSlider(onEachSide)
            },

            getSmallSlider() {
                return {
                    first: range(1, this.lastPage + 1),
                    slider: null,
                    last: null,
                }
            },

            getUrlSlider(onEachSide) {
                const window = onEachSide * 2

                if (!this.hasPages) {
                    return { first: null, slider: null, last: null }
                }

                // If the current page is very close to the beginning of the page range, we will
                // just render the beginning of the page range, followed by the last 2 of the
                // links in this list, since we will not have room to create a full slider.
                if (this.currentPage <= window) {
                    return this.getSliderTooCloseToBeginning(window)
                } // eslint-disable-line brace-style

                // If the current page is close to the ending of the page range we will just get
                // this first couple pages, followed by a larger window of these ending pages
                // since we're too close to the end of the list to create a full on slider.
                if (this.currentPage > (this.lastPage - window)) {
                    return this.getSliderTooCloseToEnding(window)
                }

                // If we have enough room on both sides of the current page to build a slider we
                // will surround it with both the beginning and ending caps, with this window
                // of pages in the middle providing a Google style sliding paginator setup.
                return this.getFullSlider(onEachSide)
            },

            getSliderTooCloseToBeginning(window) {
                return {
                    first: range(1, window + 3),
                    slider: null,
                    last: this.getFinish(),
                }
            },

            getSliderTooCloseToEnding(window) {
                return {
                    first: this.getStart(),
                    slider: null,
                    last: range(this.lastPage - (window + 2), this.lastPage + 1),
                }
            },

            getFullSlider(onEachSide) {
                return {
                    first: this.getStart(),
                    slider: this.getAdjacentUrlRange(onEachSide),
                    last: this.getFinish(),
                }
            },

            getAdjacentUrlRange(onEachSide) {
                return range(this.currentPage - onEachSide, this.currentPage + onEachSide + 1)
            },

            getStart() {
                return [1, 2]
            },

            getFinish() {
                return [this.lastPage - 1, this.lastPage]
            },
        },

    }
</script>

<style lang="scss" src="~/assets/sass/components/subdomain_pagination.scss"/>
