<template>
    <div>
        <section-title :title="$t('profile.badges')" />

        <div class="mb-2 d-flex list-badges">
            <div v-for="badge in badges" :key="badge.name" class="d-flex mb-1 mr-1">
                <a
                    v-tooltip
                    :title="badge.name ? $t(badge.name) : badge.description "
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <div class="badge">
                        <img :src="getURlImage(badge)" :alt="badge.name">
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import SectionTitle from './SectionTitle.vue'

    export default {
        components: {
            SectionTitle,
        },

        props: {
            badges: {
                type: Array,
                default: () => [],
            },
        },

        methods: {
            getURlImage(badge) {
                return `/${badge.image}`
            },
        },
    }
</script>

<style lang="scss" scoped>
    .list-badges {
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 1.5rem;
        .badge {
            filter: drop-shadow(-1px 3px 1px rgba(0, 0, 0, 0.4));

            img {
                width: 80px;
                height: 80px;
                clip-path: polygon(
                    98.66025% 45%,99.39693% 46.5798%,99.84808% 48.26352%,100% 50%,99.84808%
                    51.73648%,99.39693% 53.4202%,98.66025% 55%,78.66025% 89.64102%,77.66044%
                    91.06889%,76.42788% 92.30146%,75% 93.30127%,73.4202% 94.03794%,71.73648%
                    94.48909%,70% 94.64102%,30% 94.64102%,28.26352% 94.48909%,26.5798% 94.03794%,25%
                    93.30127%,23.57212% 92.30146%,22.33956% 91.06889%,21.33975% 89.64102%,1.33975%
                    55%,.60307% 53.4202%,.15192% 51.73648%,0 50%,.15192% 48.26352%,.60307%
                    46.5798%,1.33975% 45%,21.33975% 10.35898%,22.33956% 8.93111%,23.57212% 7.69854%,25%
                    6.69873%,26.5798% 5.96206%,28.26352% 5.51091%,30% 5.35898%,70% 5.35898%,71.73648%
                    5.51091%,73.4202% 5.96206%,75% 6.69873%,76.42788% 7.69854%,77.66044%
                    8.93111%,78.66025% 10.35898%
                );
            }
        }
    }
</style>
