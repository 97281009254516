// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<script lang="js">
    import formatNumber from '~/filters/formatNumber'

    export default {
        functional: true,

        props: {
            stats: Object,
            className: [String, Array, Object],
            title: Object,
        },

        render(h, { parent, props }) {
            return (
                <div class={['post-stats', props.className]}>
                    <span class='stats-item mr-05' title={ `${parent.$t('stats.views')}: ${props.stats.views_count}` } v-tooltip>
                        <i class='fa fa-eye' aria-hidden='true'></i> { formatNumber(props.stats.views_count) }
                    </span>

                    <span class='stats-item mx-05' title={ `${parent.$t('stats.clips')}: ${props.stats.clips_count}` } v-tooltip>
                        <i class='fa fa-bookmark' aria-hidden='true'></i> { formatNumber(props.stats.clips_count) }
                    </span>

                    <span class='stats-item mx-05' title={ `${parent.$t('stats.comments')}: ${props.stats.comments_count}` } v-tooltip>
                        <i class='fa fa-comments' aria-hidden='true'></i> { formatNumber(props.stats.comments_count) }
                    </span>
                </div>
            )
        },
    }
</script>

<style lang="scss">
    @import "../../../assets/sass/bootstrap/colors";

    .post-stats {
        display: flex;
        align-items: center;
        line-height: 22px;
        color: $gray-light;

        .stats-item {
            cursor: default;
        }
    }
</style>
