// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="editor-lite">
        <textarea
            ref="textarea"
            :value="value"
            v-bind="$attrs"
            :class="modeTheme"
            v-on="$_listeners"
        />
        <div v-if="isUploading" class="editor-lite__info bg-light w-100 m-0 px-1 text-left border">
            <i class="el-icon-loading mr-05" />{{ $t('editor.upload.fileUploading') }}...
        </div>

        <div class="editor-lite__extras">
            <markdown-help btn-class="editor-lite__extra-button" />
            <button
                ref="uploadClick"
                v-tooltip
                :title="$t('detailQuestion.comment.commentForm.image')"
                class="editor-lite__extra-button icon-btn"
            >
                <i class="fa fa-image" aria-hidden="true" />
            </button>
            <emoji-pop-up btn-class="editor-lite__extra-button" />
        </div>

        <suggestions
            ref="suggestions"
            :typing-text="typingText"
            :style="{ top: `${suggestionBoxPosition.top}px`, left: `${suggestionBoxPosition.left}px` }"
            @select="applySuggestion"
            @pending-complete="adjustSuggestionBoxPosition"
        />
    </div>
</template>

<script>
    import _get from 'lodash/get'
    import _last from 'lodash/last'
    import _replace from 'lodash/replace'
    import autosize from 'autosize'
    import getCaretPos from 'textarea-caret'
    import { mapState } from 'vuex'
    import editorMixins from '~/utils/editor/mixin'
    import { calculateElementOffset } from '~/lib/dom-utils'

    import Suggestions from './Suggestions/Index.vue'
    import MarkdownHelp from '../help/MarkdownHelp.vue'
    import EmojiPopUp from '../help/EmojiPopUp.vue'

    export default {
        components: {
            Suggestions,
            MarkdownHelp,
            EmojiPopUp,
        },

        mixins: [editorMixins],

        props: {
            value: String,
        },

        data: () => ({
            suggestionBoxPosition: {
                top: 0,
                left: 0,
            },
            isUploading: false,
        }),

        computed: {
            ...mapState('typeControl', ['modeTheme']),

            $_listeners() {
                return {
                    ...this.$listeners,
                    input: e => this.$emit('input', e.target.value),
                    change: e => this.$emit('change', e.target.value),
                    keydown: this.onKeydown,
                    paste: this.onPaste,
                    drop: this.onDrop,
                }
            },

            typingText() {
                const selectionStart = _get(this.$refs, 'textarea.selectionStart', 0)
                const lines = this.value.slice(0, selectionStart).split('\n')

                return _last(lines)
            },
        },

        mounted() {
            autosize(this.$refs.textarea)

            const { createDropzone } = require('../../lib/dropzone')
            this.$_dropzone = createDropzone(this.$el, {
                previewsContainer: false,
                clickable: this.$refs.uploadClick,
            })

            this.$_dropzone.on('success', this.onUploadImage)
        },

        beforeDestroy() {
            autosize.destroy(this.$refs.textarea)
            if (this.$_dropzone) {
                this.$_dropzone.destroy()
            }
        },

        provide() {
            const actions = {
                insertText: this.insertText,
            }

            return {
                actions,
            }
        },

        methods: {
            focus() {
                this.$refs.textarea.focus()
            },

            reset() {
                this.$emit('input', '')
                this.$nextTick(() => {
                    autosize.update(this.$refs.textarea)
                })
            },

            onKeydown(e) {
                this.$refs.suggestions.handleKeyEvents(e)
                this.$emit('keydown', e)
            },

            async onPaste(event) {
                const file = this.getImageFromClipboard(event)

                if (file && !this.isUploading) {
                    const uploadText = this.imageUploadMdText(file)
                    this.isUploading = true
                    this.insertText(uploadText)

                    const image = await this.uploadImage(file)

                    this.replaceText(uploadText, this.imageMdText(image))
                    this.isUploading = false
                }
            },

            async onDrop(event) {
                const imageUrl = this.getImageUrlFromDropEvent(event)

                if (imageUrl) {
                    event.preventDefault()
                    this.insertText(this.imageMdText({ path: imageUrl }))
                }
            },

            replaceText(searchText, text) {
                const pointerPosition = this.$refs.textarea.selectionStart - searchText.length + text.length
                const nextValue = _replace(this.value, searchText, text)
                const textarea = this.$refs.textarea
                textarea.value = nextValue
                textarea.setSelectionRange(pointerPosition, pointerPosition)

                this.$emit('input', nextValue)
            },

            /**
             * Insert text at current position
             *
             * @param {string} text
             */
            insertText(text) {
                const selectionStart = this.$refs.textarea.selectionStart
                const selectionEnd = this.$refs.textarea.selectionEnd
                this.alterText(text, selectionStart, selectionEnd)
            },

            /**
             * Alter a range of text
             *
             * @param {string} value
             * @param {number} start
             * @param {number} end
             */
            alterText(value, start, end) {
                const textBefore = this.value.slice(0, start)
                const textAfter = end > start ? this.value.slice(end) : this.value.slice(start)
                const nextValue = `${textBefore}${value}${textAfter}`
                const cursorPosAfterInsert = nextValue.length - textAfter.length

                const textarea = this.$refs.textarea
                textarea.value = nextValue
                textarea.setSelectionRange(cursorPosAfterInsert, cursorPosAfterInsert)

                this.$emit('input', nextValue)
            },

            /**
             * Apply suggestion
             */
            applySuggestion({ type, pending, suggestion }) {
                _get({
                    emoji: this.completeEmoji,
                    mention: this.completeMention,
                }, type, () => {})(pending, suggestion)
            },

            completeEmoji(pending, emoji) {
                this.completeText(pending, `:${emoji}:`)
            },

            completeMention(pending, username) {
                this.completeText(pending, `@${username}`)
            },

            /**
             * Complete pending text at current cursor
             *
             * @param {string} pending
             * @param {string} completion
             */
            completeText(pending, completion) {
                const selectionStart = this.$refs.textarea.selectionStart
                const shouldInsertSpace = selectionStart >= this.value.length || !(/\s/.test(this.value[selectionStart]))
                const replacement = shouldInsertSpace ? `${completion} ` : completion
                this.alterText(replacement, selectionStart - pending.length - 1, selectionStart)
            },

            /**
             * Set suggestion box position based on cursor coordinates and pending complete text
             *
             * @param {string} pendingText
             */
            adjustSuggestionBoxPosition(pendingText) {
                const offset = pendingText ? -1 * pendingText.length : 0
                const cursorCoords = this.getCursorCoords(offset)
                this.suggestionBoxPosition = {
                    top: cursorCoords.top,
                    left: cursorCoords.left,
                }
            },

            /**
             * Return current cursor coordinate
             *
             * @param   {number} offset
             * @returns {object}
             */
            getCursorCoords(offset = 0) {
                const textarea = this.$refs.textarea

                const cursorCoords = getCaretPos(textarea, textarea.selectionStart + offset)
                const elOffset = calculateElementOffset(textarea, this.$el)
                const lineHeight = getComputedStyle(textarea).lineHeight

                return {
                    top: elOffset.top + cursorCoords.top + parseFloat(lineHeight),
                    left: elOffset.left + cursorCoords.left,
                }
            },

            /**
             * Insert image when dropzone finished uploading
             *
             * @param {object} file
             * @param {object} response
             */
            onUploadImage(file, response) {
                const uploadedFile = response.data
                this.insertText(`![${uploadedFile.name}](${uploadedFile.path})`)
            },
        },
    }
</script>

<style lang="scss">
    @import "../../assets/sass/bootstrap/colors";
    @import "../../assets/sass/bootstrap/borders";

    .editor-lite {
        width: 100%;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        &.dz-drag-hover {
            textarea {
                border: 1px $primary solid;
            }
        }

        textarea {
            padding: 8px 2.2rem 30px 8px;
            width: 100%;
            resize: none;
            outline: none;
            min-height: 120px;
            vertical-align: top;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: $base-border-radius;
            transition: border-color .1s ease-in;
            overflow: hidden !important;
            &:focus {
                border-color: lighten($primary, 25);
            }
        }

        &__extras {
            position: absolute;
            top: 0;
            right: 0;
            margin: .25rem .75rem;
            display: flex;
            align-items: center;
            flex-direction: column-reverse;
        }

        &__info {
            position: absolute;
            bottom: 0;
            left: 0;
            margin: .25rem .75rem;
        }

        &__extra-button {
            font-size: 1.2rem;
            flex-direction: row;
            color: $gray-light;
            padding: 0 .25rem;
        }

        .theme-dark {
            background-color: #0d1117;
            color: white;
        }

        .theme-sepia {
            background-color: #f1e7d0;
            color: $black;
        }

        .suggestion-container {
            z-index: 999;
        }
    }
</style>
