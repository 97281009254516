// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="list-reported ml-2 mr-3">
        <div v-if="report.reportable && report.reportable.url">
            <p class="title-report">
                <a :href="report.reportable.url">
                    {{ title }}
                </a>
            </p>
            <p class="report-detail">
                <span class="reason">{{ $t('profile.reason') }} {{ $t(report.reason) }}</span>
                <span class="time-report">{{ $t('profile.reportAt') }} {{ report.created_at | humanizeTime }}</span>
            </p>
        </div>

        <div v-else>
            <div>
                <el-alert
                    :show-icon="true"
                    :closable="false"
                    :description="$t('profile.deleted', { type: $t(`${report.reportable_type}`) })"
                    class="mb-05"
                />
                <p class="report-detail">
                    <span class="reason">
                        {{ $t('profile.reason') }} {{ $t(report.reason) }}
                    </span>
                    <span class="time-report">
                        {{ $t('profile.reportAt') }} {{ report.created_at | humanizeTime }}
                    </span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            report: {
                type: Object,
            },
        },

        computed: {
            title() {
                return this.report.reportable.title ? this.report.reportable.title : this.report.reportable_type
            },
        },
    }
</script>

<style lang="scss">
    .list-reported {
        border-bottom: 1px solid #dbdbe6;
        margin-top: 15px;
    }

    .title-report {
        width: 40%;
    }

    .time-report {
        float: right;
    }

    .theme-dark {
        .title-report a {
            color: white;
        }

        .report-detail {
            color: #e4e6eb;
        }
    }
</style>
