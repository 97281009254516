// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <Empty v-if="posts.length <= 0 && !pinnedItem">
        <p> {{ $t('empty') }} </p>
    </Empty>

    <div v-else class="post-feed">
        <LayoutSwitcher v-if="posts.length > 0 || pinnedItem" />

        <slot name="before" />
        <div v-if="pinnedItem && pinnedItem.length > 0">
            <FeedItem
                v-for="(post, index) in pinnedItem"
                :key="index"
                :post="post"
                :pinned-post="true"
            />
        </div>

        <FeedItem
            v-for="(post, index) in posts"
            :key="index"
            :post="post"
            :show-preview="layout === 'preview'"
            :highlight="shouldHighlight(post)"
        />

        <Pagination
            :current-page="pagination.current_page"
            :last-page="pagination.total_pages"
        />
    </div>
</template>

<script>
    import _get from 'lodash/get'
    import _map from 'lodash/map'
    import _assign from 'lodash/assign'
    import { mapState } from 'vuex'

    import Empty from '~/components/shared/Empty.vue'
    import Pagination from '~/components/Pagination.vue'
    import LayoutSwitcher from '~/components/posts/widgets/LayoutSwitcher.vue'
    import FeedItem from './PostFeedItem.vue'

    export default {
        components: {
            Empty,
            Pagination,
            FeedItem,
            LayoutSwitcher,
        },

        props: {
            store: {
                type: String,
                required: true,
            },

            shouldHighlight: {
                type: Function,
                default: () => false,
            },

            pinnedItem: Array,
        },

        computed: {
            ...mapState({
                postIds(state) {
                    return _get(state, [...this.store.split('/'), 'all'])
                },

                pagination(state) {
                    return _get(state, [...this.store.split('/'), 'pagination'])
                },
            }),

            ...mapState('settings', ['layout']),

            posts() {
                const idPostsPin = []
                if (this.pinnedItem) {
                    this.pinnedItem.forEach((item) => {
                        idPostsPin.push(item.id)
                    })
                }
                const postsFeed = this.postIds.filter(item => !idPostsPin.includes(item))

                return _map(postsFeed, (id) => {
                    const entity = this.$store.getters['entities/posts/get'](id)
                    const data = _get(this.$store.getters, `${this.store}/get`)(id)

                    return _assign({}, entity, data)
                })
            },
        },
    }
</script>
