// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="post-feed-org px-1 pb-2">
        <chart
            :data="chartData"
        />

        <report
            v-if="flaggedContents"
            :reports="flaggedContents.data"
            :pagination="flaggedContents.meta.pagination"
        />
    </div>
</template>

<script>
    import _get from 'lodash/get'
    import _map from 'lodash/map'
    import { getReputation, getFlaggedContents } from '~/api/users'
    import Report from '~/components/reports/List.vue'
    import Chart from '~/components/charts/ChartReputation.vue'
    import { handlePageError, getContentSubDomain } from '~/utils/pages'

    export default {
        name: 'UserReputations',

        components: {
            Chart,
            Report,
        },

        scrollToTop: true,

        watchQuery: ['page'],

        computed: {
            chartData() {
                return {
                    labels: this.chart.date,
                    series: [this.chart.data],
                }
            },

            reports() {
                return this.data.reports
            },

            pagination() {
                return this.data.pagination
            },
        },

        async asyncData({
            params, store, query, req, error,
        }) {
            try {
                const host = process.client ? window.location.host : req.headers.host
                const user = store.state.config.vars.is_special_profile ? getContentSubDomain(host) : params.user
                const reputation = await getReputation(user)
                const authUsername = _get(store.state.auth, 'user.username')

                const flaggedContents = authUsername === user
                    ? await getFlaggedContents(query)
                    : null

                return {
                    chart: {
                        data: _map(reputation.data, reput => reput.total_reputation),
                        date: _map(reputation.data, reput => reput.date || []),
                    },
                    flaggedContents,
                }
            } catch (e) {
                return handlePageError(error, e)
            }
        },
    }
</script>
