<template>
    <el-dropdown
        trigger="click"
    >
        <img class="h-100" src="/images/icon-add-post-pin.png">
        <el-dropdown-menu slot="dropdown" class="list-posts-pin">
            <div v-for="post in posts" :key="post.id">
                <el-dropdown-item
                    v-if="!post.is_video"
                    :disabled="postPined(post.id)"
                    @click.native.prevent="handlePinedPost(post)"
                >
                    {{ post.title }}
                </el-dropdown-item>
            </div>
            <client-only>
                <InfiniteLoading
                    :distance="100"
                    spinner="spiral"
                    @infinite="onGetPost"
                >
                    <span slot="no-results" />
                    <span slot="no-more" />
                </InfiniteLoading>
            </client-only>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
    import InfiniteLoading from 'vue-infinite-loading'

    export default {
        components: {
            InfiniteLoading,
        },
        props: {
            posts: {
                type: Array,
                default: () => [],
            },
            postPined: Function,
            handlePinedPost: Function,
            onGetPost: Function,
        },
    }
</script>
