// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="md-help">
        <el-popover
            v-model="show"
            placement="top"
            visible-arrow="false"
            trigger="click"
        >
            <el-button
                slot="reference"
                :class="btnClass"
                type="text"
                icon="fa fa-smile-o text-muted"
                :title="$t('detailQuestion.comment.commentForm.emoji')"
                tabindex="-1"
                class="py-05"
            />

            <emoji-suggestion @emojiClick="handleEmojiClick" />
        </el-popover>
    </div>
</template>

<script>
    export default {
        components: {
            EmojiSuggestion: () => import('./EmojiSuggestion.vue'),
        },

        props: {
            btnClass: {
                type: String,
            },
        },

        data() {
            return {
                show: false,
                emojiSuggestionStyle: { button: '0px' },
            }
        },

        inject: ['actions'],

        methods: {
            open() {
                this.show = true
            },

            close() {
                this.show = false
            },

            handleEmojiClick(detail) {
                this.actions.insertText(detail.unicode)
            },
        },
    }
</script>
