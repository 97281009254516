// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="d-flex exhibition-item-org w-100 user justify-content-between align-items-center">
        <div class="d-flex w-100">
            <div>
                <Avatar
                    :image="organization.avatar"
                    :object="organization"
                    :level-partner="organization.level_partner"
                    :partner-width="62"
                    :partner-hight="62"
                    image-size="avatar"
                    :size="'xl-mini'"
                />
            </div>

            <div class="d-flex flex-column user-info overflow-hidden item-author ml-05">
                <div class="full-width h-100 name-author justify-content-around d-flex flex-column">
                    <!-- Name -->
                    <nuxt-link :to="organizationPage" class="link org-name">
                        <b>{{ organization.name }}</b>
                    </nuxt-link>

                    <!-- Stats -->
                    <slot name="stats">
                        <div class="user-stats-org text-muted d-flex">
                            <span
                                v-for="(stat, index) in organizationStats(organization)"
                                :key="index"
                                v-tooltip
                                :title="stat.name"
                                class="stats-item-organization text-muted w-25"
                                :class="{ 'ml-05': index !== 0 }"
                            >
                                <i :class="stat.icon" class="stats-item__icon" aria-hidden="true" />
                                {{ stat.value | formatNumber }}
                            </span>
                        </div>
                    </slot>
                </div>

                <!-- Follow button -->
                <div v-if="user && organization.user_id === user.id" class="edit-profile mt-05">
                    <nuxt-link
                        :to="`/o/${organization.slug}/info/edit`"
                        class="btn btn-follow-subdomain-small font-weight-bold"
                    >
                        {{ $t('detailOrgaization.edit') }}
                    </nuxt-link>
                </div>

                <subscribe
                    v-else
                    ref="subscribe"
                    :send="handleSubscribe"
                    :subscribable="{ type: 'Organization', id: organization.slug }"
                >
                    <button
                        :class="{ 'following': organization.following }"
                        class="btn btn-follow-subdomain-small font-weight-bold mt-05"
                        @click="$refs.subscribe.handle(!organization.following)"
                    >
                        <span
                            v-if="organization.following"
                        >
                            <i class="fa fa-check" />
                            {{ $t('detailOrgaization.following') }}
                        </span>
                        <span v-else><i class="fa fa-plus" /> {{ $t('detailOrgaization.follow') }} </span>
                    </button>
                </subscribe>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import { routeToOrganization } from '~/lib/functions'
    import Avatar from '~/components/shared/Avatar/Avatar.vue'
    import Subscribe from '~/components/widgets/Subscribe.vue'

    export default {

        components: {
            Avatar,
            Subscribe,
        },
        props: {
            organization: {
                type: Object,
                required: true,
            },
        },

        computed: {
            ...mapState('auth', ['user']),
            organizationPage() {
                return routeToOrganization(this.organization)
            },
        },

        methods: {
            ...mapActions('entities/organizations', ['subscribe']),
            organizationStats(organization) {
                return [
                    {
                        name: `${this.$t('profile.organizations.posts')}${organization.posts_count}`,
                        icon: 'fa fa-pencil',
                        value: organization.posts_count,
                    },
                    {
                        name: `${this.$t('profile.organizations.members')}: ${organization.members_count}`,
                        icon: 'fa fa-users',
                        value: organization.members_count,
                    },
                    {
                        name: `${this.$t('profile.organizations.follow')}: ${organization.followers_count}`,
                        icon: 'fa fa-user-plus',
                        value: organization.followers_count,
                    },
                    {
                        name: `${this.$t('profile.organizations.view')}: ${organization.postviews_count}`,
                        icon: 'fa fa-eye',
                        value: organization.postviews_count,
                    },
                ]
            },

            handleSubscribe(value) {
                this.$emit('updateFollow', { value, organization: this.organization })
                return this.subscribe({ organization: this.organization, value })
            },
        },
    }
</script>

<style lang="scss">
    @import "~/assets/sass/bootstrap/mixins";

    .exhibition-item-org {
        .user-info {
            .name-author {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;

                .org-name {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
            .user-stats-org {
                min-width: 9rem;
                flex-wrap: wrap;
                justify-content: flex-start;

                .stats-item-organization {
                    width: auto !important;
                }
            }
        }

        .item-user {
            flex-wrap: wrap;
        }
    }

    @media only screen and (max-width: 1300px) {
        .exhibition-item-org {
            flex-direction: column !important;
        }
    }

    @include media-breakpoint-down(xs) {
        .exhibition-item-org {
            &.user {
                margin: 1rem 0;

                img.avatar, .avatar > img {
                    max-width: 60px;
                }

                .user-info {
                    margin-left: 10px;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    align-items: center;

                    .user-stats-org {
                        font-size: 0.8rem;
                    }
                }

                .btn-subscribe {
                    font-size: 0.8rem;
                    min-width: 80px;
                    margin-top: 0;
                }
            }
        }
    }

    @media only screen and (max-width: 575px) {
        .name-user {
            max-width: 50vw;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block !important;
        }

        .stats-followers-tags {
            flex-wrap: wrap;
        }
    }

    @media screen and (max-width: 500px) {
        .list-followers-tags {
            flex-direction: column;
            align-items: center;
        }

        .stats-followers-tags {
            flex-wrap: nowrap;
            flex-direction: column;
            align-items: center;
        }

        .list-followers-tags {
            .item-author {
                display: flex;
                flex-direction: column;
                align-items: center;

                .subscribe, .edit-profile {
                    width: 100%;
                    margin-top: 0.5rem;
                    display: flex;
                    justify-content: center;
                }

                .name-author {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }
        }
    }

    @media only screen and (max-width: 400px) {
        .exhibition-item-org {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .item-author {
            display: flex;
            flex-direction: column;
            align-items: center;

            .subscribe, .edit-profile {
                width: 100%;
                margin-top: 0.5rem;
                display: flex;
                justify-content: center;
            }

            .name-author {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }
</style>
