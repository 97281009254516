// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="comment-form">
        <Tabs initial-active-pane="write">
            <TabPane :label="$t('detailQuestion.comment.commentForm.write')" name="write">
                <div :class="{ 'has-avatar': showAvatar }" class="comment-form__write">
                    <nuxt-link :to="toUser(user)">
                        <Avatar
                            v-if="showAvatar"
                            :image="user.avatar"
                            size="md"
                            class="mx-05"
                        />
                    </nuxt-link>

                    <LiteEditor
                        ref="editor"
                        v-model="form[name]"
                        :name="name"
                        :placeholder="$t('detailQuestion.comment.commentForm.writeAResponse')"
                        rows="1"
                        @keydown.ctrl.enter="store"
                        @keydown.esc.stop="cancel"
                    />
                </div>
            </TabPane>

            <TabPane :label="$t('detailQuestion.comment.commentForm.preview')" name="preview">
                <MarkdownPure v-if="form[name]" :contents="form[name]" class="comment-form__preview" />
                <div v-else class="text-muted comment-form__preview">
                    {{ $t('detailQuestion.comment.commentForm.empty') }}
                </div>
            </TabPane>

            <div class="comment-form__actions">
                <button v-if="showCancel" class="btn btn-sm" @click.stop="cancel">
                    {{ $t(cancelText) }}
                </button>
                <el-button
                    :loading="isSubmiting"
                    :disabled="!form[name]"
                    type="primary"
                    class="btn btn-sm btn-primary"
                    @click.prevent="store"
                >
                    {{ $t(submitText) }}
                </el-button>
                <slot name="extend" />
            </div>
        </Tabs>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import { user as toUser } from '~/utils/url'
    import { doNothing } from '~/utils/pages'

    import Avatar from '../shared/Avatar.vue'
    import LiteEditor from '../editor/Lite.vue'

    export default {
        components: {
            Avatar,
            LiteEditor,
        },

        props: {
            name: {
                type: String,
                default: 'contents',
            },
            value: {
                type: String,
                default: '',
            },
            className: [Array, Object, String],
            submitText: {
                type: String,
                default: 'Save',
            },
            cancelText: {
                type: String,
                default: 'detailQuestion.comment.commentForm.discard',
            },
            showCancel: {
                type: Boolean,
                default: true,
            },
            showAvatar: {
                type: Boolean,
                default: true,
            },
            submit: {
                type: Function,
            },
        },

        data() {
            const now = Date.now()

            return {
                form: {
                    [this.name]: this.value,
                },

                tabs: {
                    ids: {
                        write: `write-${now}`,
                        preview: `preview-${now}`,
                    },
                    write: `#write-${now}`,
                    preview: `#preview-${now}`,
                },

                isWriting: true,
                isSubmiting: false,
            }
        },

        computed: {
            ...mapState('auth', ['user']),
        },

        methods: {
            toUser,

            store() {
                this.isSubmiting = true
                const data = this.form
                this.submit({ data }).then((res) => {
                    this.$emit('submit-success', res)
                }).catch((err) => {
                    this.$emit('submit-fail', err)
                }).finally(() => {
                    this.$emit('submit-finally')
                    this.isSubmiting = false
                })
            },

            cancel() {
                if (this.$refs.editor.value.trim()) {
                    this.$confirm(this.$t('detailQuestion.comment.commentForm.comfirmDiscard.content'), this.$t('detailQuestion.comment.commentForm.comfirmDiscard.title'), {
                        confirmButtonText: this.$t('confirm.ok'),
                        cancelButtonText: this.$t('confirm.cancel'),
                    })
                        .then(() => this.$emit('cancel'))
                        .catch(doNothing)
                } else {
                    this.$emit('cancel')
                }
            },

            writingMode(value) {
                this.isWriting = value
            },

            append(text) {
                this.form[this.name] += text
            },

            reset() {
                this.$refs.editor.reset()
            },

            focus() {
                this.$refs.editor.focus()
            },
        },
    }
</script>

<style lang="scss">
    .comment-form {
        &__write {
            display: flex;
            margin: 8px 0px 14px;
        }

        &__preview {
            padding: 1rem .5rem;
        }

        &__actions {
            display: flex;
            justify-content: flex-end;

            .btn {
                margin-left: .6rem;
            }
        }
    }
</style>
