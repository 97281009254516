// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<script>
    import { tag as toTag } from '~/utils/url'

    const renderTagName = (h, tag, nuxtLink) => (nuxtLink
        ? <nuxt-link class="tag-header__name link" to={toTag(tag)}>{tag.name}</nuxt-link>
        : <span class="tag-header__name">{tag.name}</span>)

    const renderTechnicalIcon = (h, {
        technology, canChange, confirmChange,
    }) => (technology || canChange ? (
        <span
            v-tooltip
            tooltip-placement="right"
            title={technology ? 'Technical Tag' : 'Click on icon to mark as Technical Tag' }
            class={{
                'technical-icon': true,
                'cursor-pointer': canChange,
                'technical-icon--marked': technology,
            }}
            onClick={canChange ? confirmChange : () => {}}>
            <i class={{
                icon: true,
                'el-icon-star-on': technology,
                'el-icon-star-off': !technology,
            }}/>
        </span>
        ) : null)

    const renderRSSIcon = (h, tag) => {
        const url = `/rss/tags/${tag.slug}.rss`

        return (
            <span
                v-tooltip
                tooltip-placement="left"
                class="item"
                title="RSS"
            >
                <a href={url} target="_blank"><i class="rss-icon fa fa-rss-square pr-05"></i></a>
            </span>
        )
    }

    const makeConfirmation = ($confirm, tag, $dispatch) => () => {
        const confirmation = tag.technology ? 'Unmark Technical Tag' : 'Mark as Technical Tag'
        return $confirm('Are you sure?', confirmation)
            .then(() => $dispatch('entities/tags/markTechnical', { tag, value: !tag.technology }))
    }

    export default {
        functional: true,

        render: (h, {
            parent: { $confirm, $store },
            props: {
                tag, canChange, htmlTag = 'h3', nuxtLink,
            },
            data: { staticClass },
        }) => h(htmlTag, { class: ['tag-header', staticClass] }, [
            renderRSSIcon(h, tag),
            renderTagName(h, tag, nuxtLink),
            renderTechnicalIcon(h, {
                canChange,
                technology: tag.technology,
                confirmChange: makeConfirmation($confirm, tag, $store.dispatch),
            }),
        ]),
    }
</script>

<style lang="scss">
    $--icon-color--default: #9b9b9b;
    $--icon-color--actived: #FFCC37;

    .tag-header .technical-icon {
        .icon {
            color: $--icon-color--default;
            padding-right: 5px;
            padding-left: 5px;
            &:hover {
                color: $--icon-color--actived;
            }
        }

        &--marked .icon {
            color: $--icon-color--actived;
        }
    }

    .rss-icon {
        color: #fe9e40;
    }
</style>
