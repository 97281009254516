// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="subdomains-2-header profile-subdomain pb-2">
        <div class="d-flex banner">
            <img
                src="~/assets/images/banner-subdomain.svg"
                class="banner-img"
                alt="Image Cover"
            >
        </div>
        <div class="subdomain-header__card subdomain-header container">
            <div class="profile">
                <Avatar
                    :object="user"
                    :image="user.avatar"
                    :level-partner="user.level_partner"
                    :is-user="true"
                    :partner-width="180"
                    :partner-hight="180"
                    is-subdomain
                    image-size="avatar"
                    size="xxl"
                    class="profile-avatar"
                />
                <div class="profile-info">
                    <div class="d-flex justify-content-start flex-column profile-info__left">
                        <h1 class="user-name text-break mb-05">
                            {{ user.name }}
                        </h1>

                        <h4 class="text-muted mb-05">
                            @{{ user.username }}
                        </h4>

                        <Report v-if="!user.is_self" class="mb-05" :send-report="report" />

                        <DescriptionLevelPartner
                            v-if="user.level_partner"
                            :level="user.level_partner"
                        />
                    </div>

                    <div class="profile-info__right">
                        <div
                            v-if="stats.length > 0"
                            class="subdomain-header--stats"
                        >
                            <div
                                v-for="(item, index) in stats"
                                :key="index"
                                :class="`stats-card`"
                            >
                                <span class="stats-card--value">
                                    {{ formatNumber(item.value) }}
                                </span>
                                <p class="stats-card--label">
                                    {{ $t(item.name) }}
                                </p>
                            </div>
                        </div>

                        <div class="d-flex profile-info__right___action">
                            <div class="d-flex align-items-center">
                                <div class="switch-theme" :class="modeThemeSubdomain">
                                    <el-switch
                                        v-model="isDarkMode"
                                        v-tooltip
                                        :title="descButtonSwitchTheme"
                                        @change="switchTheme"
                                    />
                                </div>
                            </div>
                            <div class="subscribe">
                                <div v-if="user.is_self" class="edit-profile">
                                    <a
                                        :href="editProfile"
                                        class="btn btn-edit edit"
                                        target="_blank"
                                        rel="noopener"
                                    >
                                        <img
                                            src="/images/icon-edit.png"
                                            class="mr-05"
                                        >
                                        {{ $t('profile.edit') }}
                                    </a>
                                </div>

                                <subscribe
                                    v-else
                                    ref="subscribe"
                                    :send="follow"
                                    :subscribable="{ type: 'User', id: user.id }"
                                >
                                    <button
                                        :class="{ 'following-org': user.following }"
                                        class="btn btn-follow-org"
                                        @click="$refs.subscribe.handle(!user.following)"
                                    >
                                        <span
                                            v-if="user.following"
                                        >
                                            {{ $t('profile.following') }}
                                        </span>
                                        <span v-else>
                                            <img
                                                src="/images/icon-follow.png"
                                                class="stats-item__icon mr-05"
                                            >
                                            {{ $t('profile.follow') }}
                                        </span>
                                    </button>
                                </subscribe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapActions, mapMutations } from 'vuex'
    import { report } from '~/api/users'
    import Avatar from '~/components/shared/Avatar/Avatar.vue'
    import Subscribe from '~/components/widgets/Subscribe.vue'
    import Report from '~/components/dialogs/Report.vue'
    import DescriptionLevelPartner from '~/components/subdomains/DescriptionLevelPartner.vue'
    import switchModeTheme from '~/pages/__mixins/switchModeTheme'
    import { routeToUser } from '~/lib/functions'
    import formatNumber from '~/filters/formatNumber'

    export default {
        components: {
            Avatar,
            Subscribe,
            Report,
            DescriptionLevelPartner,
        },

        mixins: [switchModeTheme],

        props: {
            user: Object,
        },

        data() {
            return {
                isDarkMode: false,
            }
        },

        computed: {
            editProfile() {
                return `${process.env.SSO_APP_URL}/profile/personal`
            },

            modeThemeSubdomain() {
                return this.$store.state.typeControl.modeTheme
            },

            descButtonSwitchTheme() {
                return this.modeThemeSubdomain === 'default' ? this.$t('detailOrgaization.darkMode') : this.$t('detailOrgaization.lightMode')
            },

            profileStore() {
                return this.$store.getters['entities/users/get'](this.user.id)
            },

            stats() {
                const to = page => routeToUser(this.user, page)

                return [
                    {
                        name: this.$t('profile.statsHeader.postViews'),
                        value: this.user.total_post_views,
                        title: this.user.total_post_views,
                        href: to(),
                    },
                    { name: this.$t('profile.statsHeader.reputations'), value: this.profileStore.reputation, href: to('reputations') },
                    { name: this.$t('profile.statsHeader.followers'), value: this.profileStore.followers_count, href: to('followers') },
                    { name: this.$t('profile.statsHeader.posts'), value: this.profileStore.posts_count, href: to() },
                ]
            },
        },

        mounted() {
            this.isDarkMode = this.modeThemeSubdomain === 'theme-dark'
        },

        beforeUpdate() {
            this.isDarkMode = this.modeThemeSubdomain === 'theme-dark'
        },

        methods: {
            formatNumber,
            ...mapActions('profile', ['follow']),
            ...mapActions('entities/organizations', ['subscribe']),
            ...mapMutations({
                switchMode: 'typeControl/switchMode',
            }),
            report(data) {
                return report(this.user.username, data)
            },

            switchTheme() {
                const theme = this.modeThemeSubdomain === 'default' ? 'theme-dark' : 'default'
                this.handleSwitchTheme(theme)
            },
        },
    }
</script>

<style lang="scss">
@import '../../../assets/sass/components/subdomain-header';

.profile-subdomain.subdomains-2-header {
    .banner {
        position: relative;
        width: 100%;
        overflow: hidden;
        background: #00a2ea;

        &-img {
            width: 100%;
            height: 100%;
            max-width: 1200px;
            margin: 0 auto;
        }
    }

    .switch-theme {
        width: 90px;
        height: 40px;
        margin-right: 10px;
        background-color:#aeb3b4;
        border-radius: 24px;
        padding: 4px;
        cursor: pointer;

        .el-switch {
            width: 100%;
            height: 100%;

            &__core {
                width: 100% !important;
                height: 100%;
                background-image: url('/images/button-light-mode.png');
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                border-radius: 24px;
                border: none;

                &::after {
                    top: unset;
                    left: 0;
                    width: 32px;
                    height: 100%;
                    margin-left: unset;
                    transition: transform 0.2s ease;
                }
            }
        }

        &.theme-dark {
            background-color:#227FF3;

            .el-switch__core {
                background-image: url('/images/button-dark-mode.png');
            }

            .el-switch.is-checked .el-switch__core::after {
                left: unset;
                transform: translateX(155%);

                @media screen and (max-width: 500px) {
                    transform: translateX(30%);
                }
            }
        }
    }

    .subdomain-header__card {
        background-color: #fff;

        .profile {
            display: flex;
            padding-top: 12px;

            a.profile-avatar {
                top: 0;

                .avatar {
                    width: 150px;
                    max-height: 150px;
                    border-radius: 50% !important;
                }

                .border-avt-by-level {
                    left: unset !important;
                }
            }

            .profile-info {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex: 1;
                margin-left: 16px;
                margin-top: 16px;

                .profile-avatar_describe {
                    max-width: none;
                }

                &__left {
                      @media screen and (max-width: 500px) {
                            align-items: flex-start;
                      }
                }

                &__right {
                      flex-direction: column;

                      &___action {
                          justify-content: flex-end;

                          @media screen and (max-width: 1200px ) {
                              justify-content: flex-start;
                          }
                      }

                      .subdomain-header--stats {
                          display: flex;
                          gap: 14px;

                        @media screen and (max-width: 768px ) {
                            gap: 0;

                            .stats-card {
                                padding: 8px;

                                &--value {
                                    font-size: 20px;
                                    font-weight: 700;
                                }

                                &--label {
                                    margin-bottom: 0;
                                    font-size: 12px;
                                }
                            }
                        }

                        .stats-card {
                            padding: 12px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            &--value {
                                font-size: 24px;
                                font-weight: 700;
                            }

                            &--label {
                                margin-bottom: 0;
                                font-size: 16px;
                            }
                        }
                      }

                      .subscribe {
                          .btn-edit {
                              display: flex;
                              align-items: center;
                              padding-left: 15px;
                              padding-right: 15px;
                          }

                          .btn-edit, .following-org {
                              font-weight: 700;
                              color: #ADA7A7 !important;
                              border: 1px solid #0EA8FF;
                              background-color: #F6FDFF !important;
                              min-height: 40px;
                              border-radius: 20px;
                              white-space: nowrap;

                              img {
                                  width: 20px;
                                  height: 20px;
                              }

                              &:hover {
                                  opacity: 0.7;
                              }
                          }

                          .btn.btn-follow-org {
                              font-weight: 700;
                              color: white;
                              background-color: #0EA8FF;
                              min-height: 40px;
                              border-radius: 20px;
                              padding: 0 16px;
                              white-space: nowrap;

                              span {
                                  display: flex;
                                  align-items: center;
                              }

                              img {
                                  width: 20px;
                                  height: 20px;
                              }
                          }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1280px) {
        .subdomain-header__card {
            .profile {
                .profile-info {
                    .subdomain-header--stats {
                        flex-wrap: wrap;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1280px) {
        .subdomain-header {
            padding: 0 1.5rem;
        }
    }

    @media screen and (max-width: 500px) {
        .subdomain-header__card {
            .profile {
                flex-direction: column;

                a.profile-avatar {
                    margin: 0 auto;
                }

                .profile-info {
                    justify-content: center;
                    text-align: center;
                }
            }
        }
    }

    @media screen and (max-width: 1200px) {
        .subdomain-header__card {
            .profile {
                .profile-info {
                    flex-direction: column;
                }
            }
        }
    }
}

.theme-dark {
    .subdomains-2-header {
        background: #000;

        .subdomain-header__card {
            background-color: #000;

            .profile .profile-info {
                .profile-avatar_describe, .user-name, .subdomain-header--stats {
                    color: #fff;
                }
            }
        }
    }
}
</style>
