// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="position-relative user">
        <div class="user-avatar">
            <Avatar
                :object="organization"
                :image="organization.avatar"
                :level-partner="organization.level_partner"
                :partner-width="90"
                :partner-hight="90"
                size="xl"
            />
        </div>
        <div class="user-card">
            <div class="w-100">
                <nuxt-link :to="toOrganization(organization)" class="user-card--name text-decoration-none">
                    {{ organization.name }}
                </nuxt-link>
            </div>
            <div class="d-flex justify-content-between align-items-center user-card--icon">
                <span
                    v-tooltip
                    :title="$t('organization.companyItem.stast.posts').concat(`${organization.posts_count}`)"
                >
                    <i class="fa fa-pencil" /> {{ organization.posts_count || 0 }}
                </span>
                <span
                    v-tooltip
                    :title="$t('organization.companyItem.stast.followers')
                        .concat(`${organization.followers_count}`)"
                >
                    <i class="fa fa-user-plus" /> {{ followers_count || 0 }}
                </span>
                <span
                    v-tooltip
                    :title="$t('organization.companyItem.stast.member').concat(`${organization.members_count}`)"
                >
                    <i class="fa fa-users" /> {{ organization.members_count || 0 }}
                </span>
                <span
                    v-tooltip
                    :title="`${$t('profile.organizations.view')}: ${organization.postviews_count}`"
                >
                    <i class="fa fa-eye" /> {{ organization.postviews_count | formatNumber }}
                </span>
            </div>
            <subscribe
                ref="subscribe"
                :send="follow"
                :subscribable="{ type: 'Organization', id: organization.slug }"
            >
                <button
                    :class="{following: followings}"
                    class="user-card--follow btn"
                    @click="$refs.subscribe.handle(!followings)"
                >
                    {{ followings ? $t('organization.followings') : $t('organization.follow') }}
                </button>
            </subscribe>
        </div>
    </div>
</template>

<script>
    import { organization as toOrganization } from '~/utils/url'
    import { subscribe, unsubscribe } from '~/api/organizations'
    import Subscribe from '~/components/widgets/Subscribe.vue'
    import Avatar from '~/components/shared/Avatar/Avatar.vue'

    export default {
        components: {
            Avatar,
            Subscribe,
        },

        props: {
            organization: {
                type: Object,
                required: true,
            },
            type: {
                type: Boolean,
            },
        },

        data() {
            return {
                followings: this.organization.following,
                followers_count: this.organization.followers_count,
            }
        },

        methods: {
            toOrganization,

            follow(value) {
                try {
                    const slug = this.organization.slug
                    this.followings = value

                    if (value) {
                        this.followers_count += 1

                        return value ? subscribe(slug) : unsubscribe(slug)
                    }
                    this.followers_count -= 1

                    return value ? subscribe(slug) : unsubscribe(slug)
                } catch (err) {
                    throw err
                }
            },
        },
    }
</script>

<style lang="scss">
.user {
    & &-avatar {
        position: absolute;
        top: -22%;
        left: 50%;
        left: calc(50% - 37px);
        background-color: #fff;
        border-radius: 50%;

        @media screen and (max-width: 768px) {
            left: calc(50% - 30px);
        }

        &.member-top {
            top: -19%;
        }

        a {
            width: 75px;
            height: 75px;
            border: 1px solid #ccc;
            border-radius: 50%;
            text-decoration: none;

            .avatar--xl {
                width: 75px;
                height: 75px !important;
                max-height: 75px !important;
            }

            @media screen and (min-width: 480px) and (max-width: 768px) {
                width: 60px;
                height: 60px;

                .avatar--xl {
                    width: 60px;
                    height: 60px !important;
                    max-height: 60px !important;
                }
            }
        }
    }

    & .user-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        padding: 50px 15px 20px 15px;
        border-radius: 16px;
        border: 1px solid #ccc;
        background-color: #fff;

        &--star {
            width: 38px;
            height: 38px;
            margin-right: 8px;
        }

        &--view, &--name {
            color: #000;
            font-size: 20px;
            font-weight: 700;

            @media screen and (max-width: 768px) {
                font-size: 16px;
            }
        }

        &--name {
            display: block;
            width: 100%;
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        &--icon {
            gap: 8px;
            color: #808080;
            font-weight: 700;
            font-size: 14px;
        }

        &--follow {
            padding: 8px 24px;
            border-radius: 8px;
            background: #D9D9D9;
            font-weight: 700;

            @media screen and (max-width: 768px) {
                font-size: 12px;
            }

            &.following {
                color: #fff;
                background: #227FF3;

                  &:hover {
                      border-color:  darken(#227FF3, 10%);
                      background-color: darken(#227FF3, 10%);
                }
            }

            &:hover {
                color: #fff;
                background: #227FF3;
            }
        }
    }
}
</style>
