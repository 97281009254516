// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<script>
    import jump from 'jump.js'

    /**
     * @param {Element} el
     * @param {Function} done
     */
    function enter(el, done) {
        setTimeout(done, 5000)
        window.requestAnimationFrame(jump.bind(null, el, {
            offset: -150,
            duration: 200,
        }))
    }

    export default {
        functional: true,

        render: (h, props) => (
            <transition-group
                name="list"
                tag="div"
                class="list-transition"
                onEnter={enter}
            >
                { props.children }
            </transition-group>
        ),
    }
</script>

<style lang="scss">
    .list-transition {
        @keyframes flash {
            1%, 75% {
                background-color: rgba(75, 205, 159, 0.25);
            }
        }

        .list-enter, .list-leave-to {
            opacity: 0;
        }

        .list-enter-active {
            animation: flash 5s ease-out;
        }

        .list-enter-active, .list-leave-active {
            transition: opacity .1s ease-in;
        }
    }
</style>
