// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="pt-2 pb-2 profile-following">
        <i
            v-if="organizations.length <= 0 && isLoading"
            class="fa fa-circle-o-notch fa-spin sb-icon profile-following--loading"
            aria-hidden="true"
        />

        <div v-else class="profile-following--content pt-4">
            <div class="pl-4">
                <FollowingFilter is-subdomain />
            </div>

            <div class="members">
                <el-alert
                    v-if="organizations.length <= 0"
                    type="warning"
                    show-icon
                >
                    {{ $t('organization.listJoined.first') }}
                    {{ $t('organization.listJoined.organization') }}
                    {{ $t('organization.listJoined.last') }}
                </el-alert>

                <div v-else class="members-list pt-2">
                    <div
                        v-for="(organization, index) in organizations"
                        :key="index"
                        class="members-list--card"
                    >
                        <OrganizationItem
                            :organization="organization"
                            :type="true"
                        />
                    </div>
                </div>
            </div>

            <client-only>
                <InfiniteLoading :distance="50" spinner="spiral" @infinite="load">
                    <span slot="no-results" />
                    <span slot="no-more" />
                </InfiniteLoading>
            </client-only>
        </div>
    </div>
</template>

<script>
    import InfiniteLoading from 'vue-infinite-loading'
    import { getFollowingsWithUser } from '~/api/organizations'
    import { handlePageError, getContentSubDomain } from '~/utils/pages'
    import { loginWithSSR } from '~/utils/login'
    import FollowingFilter from '~/components/shared/user/FollowingFilter.vue'
    import OrganizationItem from '~/components/subdomains-theme-2/followings/OrganizationItem.vue'

    export default {
        name: 'UserFollowing',

        components: {
            FollowingFilter,
            InfiniteLoading,
            OrganizationItem,
        },

        data() {
            return {
                organizations: [],
                pagination: {},
                isLoading: false,
            }
        },

        // eslint-disable-next-line consistent-return
        async fetch() {
            this.isLoading = true

            const req = this.$nuxt.context.ssrContext?.req
            const store = this.$nuxt.context.store
            const params = this.$nuxt.context.route.params
            const redirect = this.$nuxt.context.redirect
            const error = this.$nuxt.context.error
            const host = process.client ? window.location.host : req.headers.host
            const username = store.state.config.vars.is_special_profile ? getContentSubDomain(host) : params.user

            try {
                const response = await getFollowingsWithUser(username, params)

                this.organizations = [...this.organizations, ...response.data]
                this.pagination = response.meta.pagination
                this.isLoading = false
            } catch (e) {
                this.isLoading = false

                if (e.response && e.response.status === 401) {
                    store.commit('auth/SET_USER', null)
                    store.commit('auth/SET_GUEST', true)

                    return loginWithSSR(req, redirect)
                }

                return handlePageError(error, e)
            }
        },

        methods: {
            async load($state) {
                const currentPage = this.pagination?.current_page
                const totalPages = this.pagination?.total_pages

                if (currentPage < totalPages) {
                    await this.$fetch()
                    $state.loaded()
                } else {
                    $state.complete()
                }
            },
        },

        watchQuery: ['page'],
    }
</script>

<style lang="scss">
.profile-following {
    &--loading {
        position: unset;
        width: 100%;
        text-align: center;
        font-size: 24px;
    }

    &--content {
        background-color: #fff;
        border-radius: 8px;
    }
}
</style>
