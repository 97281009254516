<template>
    <div>
        <div class="row mt-1">
            <div class="col-md-8">
                <DropDown
                    v-if="postPin[0] === undefined"
                    :is-loading="isLoading"
                    :position="0"
                    :posts="posts"
                    :post-pined="postPined"
                    :handle-pined-post="handlePinedPost"
                />
                <div v-else class="view-post-pin-item position-relative">
                    <div class="change-post-pin">
                        <DropDown
                            :is-loading="isLoading"
                            :position="0"
                            :posts="posts"
                            :post-pined="postPined"
                            :handle-pined-post="handlePinedPost"
                            is-change
                        />
                    </div>
                    <PostFeedItem :post="postPin[0]" show-contents-short />
                </div>
            </div>
            <div class="col-md-4 d-flex flex-column">
                <DropDown
                    v-if="postPin[1] === undefined"
                    :is-loading="isLoading"
                    :position="1"
                    :posts="posts"
                    :post-pined="postPined"
                    :handle-pined-post="handlePinedPost"
                />
                <div v-else class="view-post-pin-item position-relative">
                    <div class="change-post-pin">
                        <DropDown
                            :is-loading="isLoading"
                            :position="1"
                            :posts="posts"
                            :post-pined="postPined"
                            :handle-pined-post="handlePinedPost"
                            is-change
                        />
                    </div>
                    <PostFeedItem :post="postPin[1]" post-small show-contents-short />
                </div>

                <div class="mt-1">
                    <DropDown
                        v-if="postPin[2] === undefined"
                        :is-loading="isLoading"
                        :position="2"
                        :posts="posts"
                        :post-pined="postPined"
                        :handle-pined-post="handlePinedPost"
                    />
                    <div v-else class="view-post-pin-item position-relative">
                        <div class="change-post-pin">
                            <DropDown
                                :is-loading="isLoading"
                                :position="2"
                                :posts="posts"
                                :post-pined="postPined"
                                :handle-pined-post="handlePinedPost"
                                is-change
                            />
                        </div>
                        <PostFeedItem :post="postPin[2]" post-small show-contents-short />
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-1">
            <div class="col-md-4">
                <DropDown
                    v-if="postPin[3] === undefined"
                    :is-loading="isLoading"
                    :position="3"
                    :posts="posts"
                    :post-pined="postPined"
                    :handle-pined-post="handlePinedPost"
                />
                <div v-else class="view-post-pin-item position-relative">
                    <div class="change-post-pin">
                        <DropDown
                            :is-loading="isLoading"
                            :position="3"
                            :posts="posts"
                            :post-pined="postPined"
                            :handle-pined-post="handlePinedPost"
                            is-change
                        />
                    </div>
                    <PostFeedItem :post="postPin[3]" post-small show-contents-short />
                </div>
            </div>
            <div class="col-md-4">
                <DropDown
                    v-if="postPin[4] === undefined"
                    :is-loading="isLoading"
                    :position="4"
                    :posts="posts"
                    :post-pined="postPined"
                    :handle-pined-post="handlePinedPost"
                />
                <div v-else class="view-post-pin-item position-relative">
                    <div class="change-post-pin">
                        <DropDown
                            :is-loading="isLoading"
                            :position="4"
                            :posts="posts"
                            :post-pined="postPined"
                            :handle-pined-post="handlePinedPost"
                            is-change
                        />
                    </div>
                    <PostFeedItem :post="postPin[4]" post-small show-contents-short />
                </div>
            </div>
            <div class="col-md-4">
                <DropDown
                    v-if="postPin[5] === undefined"
                    :is-loading="isLoading"
                    :position="5"
                    :posts="posts"
                    :post-pined="postPined"
                    :handle-pined-post="handlePinedPost"
                />
                <div v-else class="view-post-pin-item position-relative">
                    <div class="change-post-pin">
                        <DropDown
                            :is-loading="isLoading"
                            :position="5"
                            :posts="posts"
                            :post-pined="postPined"
                            :handle-pined-post="handlePinedPost"
                            is-change
                        />
                    </div>
                    <PostFeedItem :post="postPin[5]" post-small show-contents-short />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _find from 'lodash/find'
    import PostFeedItem from './ViewPostPinItem.vue'
    import DropDown from './DropDown.vue'

    export default {
        components: {
            DropDown,
            PostFeedItem,
        },

        props: {
            postPin: Array,
            posts: Array,
            postPinId: Array,
            handlePinedPost: Function,
            isLoading: Number,
        },

        methods: {
            postPined(postId) {
                return _find(this.postPinId, id => id && id.id === postId) !== undefined
            },
        },
    }
</script>

<style lang="scss">
.btn-post-pin-first {
    background-color: #5387C6;
    border-radius: 12px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

    &:hover {
        opacity: 0.8;
    }

    .avatar-uploader-icon {
        font-size: 3em !important;
        color: white !important;
    }
}
.btn-post-pin-small {
    background-color: #31A5D5;
    border-radius: 8px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

    .avatar-uploader-icon {
        font-size: 2em;
        color: white;
    }

    &:hover {
        opacity: 0.8;
    }
}
.btn-post-pin:hover {
    border-color:#0EA8FF;
}
.list-posts-pin {
    max-height: 400px;
    overflow-y: scroll;
}
.view-post-pin-item {
    height: 100%;

    .change-post-pin {
        display: none;
    }

    &:hover {
        cursor: pointer;

        .change-post-pin {
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 2;
        }
    }
}
</style>

<style lang="scss" scoped>
.theme-dark {
    .view-post-pin-item {
        border: 0.5px solid #c1e0ff;
        border-radius: 8px;
        padding: 8px;

        .change-post-pin {
            left: 0;
            top: 0;
            border-radius: 8px;
        }
    }
}
</style>
