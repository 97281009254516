// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <user :user="user" :follow="follow" :is-follower="isFollower" />
</template>

<script>
    import _get from 'lodash/get'
    import User from './ProfileCard.vue'

    export default {

        components: {
            User,
        },
        props: {
            userId: Number,
            follow: Function,
            isFollower: {
                type: Boolean,
                default: false,
            },
        },

        inject: ['follow'],

        computed: {
            user() {
                const authUserId = _get(this.$store.state, 'auth.user.id')
                const user = this.$store.getters['entities/users/get'](this.userId)

                return {
                    ...user,
                    is_self: authUserId === user.id,
                }
            },
        },
    }
</script>
