// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<script>
    import Avatar from '~/components/shared/user/Avatar.vue'

    export default {
        functional: true,

        props: {
            suggestion: Object,
        },

        render: (h, { props, $style }) => {
            const { username, name, avatar } = props.suggestion.data

            return (
                <div class='d-flex'>
                    <Avatar
                        image={avatar}
                        username={username}
                        size='xs'
                        class='mr-05'
                    />
                    <strong class={['mr-05', $style.name]}>{ name }</strong>
                    <span class="text-muted">@{ username }</span>
                </div>
            )
        },
    }
</script>

<style module lang="scss">
    .name {
        max-width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
</style>
