// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="pt-2 pb-2">
        <i
            v-if="tags.length <= 0 && loading"
            class="fa fa-circle-o-notch fa-spin sb-icon loading"
            aria-hidden="true"
        />
        <empty v-else-if="tags.length <= 0 && !loading">
            <p> {{ $t('empty') }} </p>
        </empty>

        <div v-else>
            <div class="following-tags">
                <div v-for="tag in tags" :key="tag.id" class="following-tag-item">
                    <tag-item :tag="tag" />
                </div>
            </div>
            <client-only>
                <InfiniteLoading :distance="50" spinner="spiral" @infinite="load">
                    <span slot="no-results" />
                    <span slot="no-more" />
                </InfiniteLoading>
            </client-only>
        </div>
    </div>
</template>

<script>
    import { getUserFollowingTags } from 'viblo-sdk/api/users'
    import InfiniteLoading from 'vue-infinite-loading'
    import Empty from '~/components/shared/Empty.vue'
    import TagItem from '~/components/subdomains-theme-2/tags/Card.vue'
    import { normalizeTagList } from '~/components/entities/normalize'
    import { handlePageError, getContentSubDomain } from '~/utils/pages'

    export default {
        components: {
            Empty,
            TagItem,
            InfiniteLoading,
        },

        data() {
            return {
                tagIds: [],
                pagination: null,
                loading: false,
            }
        },

        scrollToTop: true,

        computed: {
            tags() {
                return this.$store.getters['entities/tags/mapId'](this.tagIds)
            },
            path() {
                return this.$route.path
            },
            page() {
                return (typeof this.$route.query.page === 'undefined') ? 1 : this.$route.query.page
            },
        },

        // eslint-disable-next-line consistent-return
        async fetch() {
            const req = this.$nuxt.context.ssrContext?.req
            const store = this.$nuxt.context.store
            const params = this.$nuxt.context.route.params
            const error = this.$nuxt.context.error
            const page = this.pagination ? { page: this.pagination.current_page + 1 } : { page: 1 }

            try {
                const host = process.client ? window.location.host : req.headers.host
                const user = store.state.config.vars.is_special_profile ? getContentSubDomain(host) : params.user

                this.loading = true

                const { data: tags, meta: { pagination } } = await getUserFollowingTags(user, {
                    ...page,
                    limit: store.state.settings.perPage - (store.state.settings.perPage % 3),
                })
                const normalizedTags = normalizeTagList(tags)
                const tagIds = normalizedTags.result

                store.commit('entities/tags/put', normalizedTags.entities.tags)

                this.tagIds = [...this.tagIds, ...tagIds]
                this.pagination = pagination
                this.loading = false
            } catch (e) {
                this.loading = false
                return handlePageError(error, e)
            }
        },

        methods: {
            async load($state) {
                if (this.pagination?.current_page < this.pagination?.total_pages) {
                    await this.$fetch()
                    $state.loaded()
                } else {
                    $state.complete()
                }
            },
        },

        watchQuery: ['page'],
    }
</script>

<style lang="scss">
    .following-tags {
        display: flex;
        flex-wrap: wrap;
        gap: 32px;

        .following-tag-item {
            flex: 0 0 calc(20% - 26px);
            display: flex;
            align-items: center;
            justify-content: center;

            @media screen and (max-width: 1280px) {
                flex: 0 0 calc(25% - 24px);
            }

            @media screen and (max-width: 1000px) {
                flex: 0 0 calc(33.33% - 22px);
            }

            @media screen and (max-width: 700px) {
                flex: 0 0 calc(50% - 16px);
            }

            @media screen and (max-width: 475px) {
                flex: 0 0 100%;
            }
        }
    }

    .loading {
        position: unset;
        width: 100%;
        text-align: center;
        font-size: 24px;
    }
</style>
