// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div :data-commentid="comment.hash_id" class="comment-thread">
        <header class="d-flex mb-05">
            <nuxt-link :to="toUser(user)">
                <Avatar
                    :image="user.avatar"
                    :username="user.username"
                    size="md"
                    class="mx-05"
                />
            </nuxt-link>
            <div>
                <nuxt-link :to="toUser(user)">
                    <span class="text-bold post-author__name">{{ user.name }}</span>
                    <span class="text-muted mr-05">
                        @{{ user.username }}
                    </span>
                </nuxt-link>
                <CommentMeta :comment="comment" />
            </div>
        </header>

        <template v-if="!editing">
            <template v-if="!!!comment.deleted_at">
                <Markdown :contents="comment.contents" class="mb-05" />

                <CommentFooter
                    :comment="comment"
                    :send-vote="vote"
                    :delete-comment="remove"
                    @edit="editing = true"
                    @report="() => $refs.reportDialog.open()"
                    @reply="onReply()"
                />
            </template>
            <template v-else>
                <el-alert
                    :closable="false"
                    :show-icon="true"
                    :title="$t('detailQuestion.commentHasNotAccepted.confirmConvert.commentDeleted')"
                    type="info"
                />
            </template>
        </template>

        <CommentForm
            v-else
            v-focus
            :value="comment.contents"
            :show-avatar="false"
            name="comment_contents"
            submit-text="Save"
            :submit="update"
            @submit-success="updateSuccess"
            @cancel="editing = false"
        />

        <CommentForm
            v-if="replying"
            v-focus
            name="comment_contents"
            submit-text="Reply"
            :submit="reply"
            :value="mention"
            @submit-success="replySuccess"
            @cancel="replying = false"
        />

        <div
            v-if="!comment.in_reply_to_comment && commentRemain"
            class="btn view-more-btn d-flex flex-column my-05 input-block-level text-bold"
            @click="showMoreComment"
        >
            <span><i class="fa fa-angle-down" /> {{ $t('commentPost.viewMore') }} ({{ commentRemain }})</span>
        </div>

        <Thread
            v-for="(replyId, index) in subChildren"
            :key="index"
            :commentable="commentable"
            :thread="replyId"
            :store="store"
            class="reply"
            @comment-success="commentSuccess"
        />

        <ReportDialog ref="reportDialog" :send-report="report" />
    </div>
</template>

<script>
    import _indexOf from 'lodash/indexOf'
    import { mapState, mapActions, mapGetters } from 'vuex'
    import { user as toUser } from '~/utils/url'
    import CommentMeta from '~/components/comments/Meta.vue'
    import CommentForm from '~/components/comments/Form.vue'
    import CommentFooter from '~/components/comments/Footer.vue'
    import ReportDialog from '~/components/dialogs/ReportDialog.vue'
    import Avatar from '~/components/shared/Avatar.vue'

    export default {
        components: {
            CommentMeta,
            CommentForm,
            CommentFooter,
            ReportDialog,
            Avatar,
        },

        props: {
            thread: {
                required: true,
                type: [Number, String],
            },

            store: {
                type: String,
                required: true,
            },

            commentable: Object,

            targetComment: {
                type: [Number, null],
                default: null,
            },
        },

        data: () => ({
            editing: false,
            replying: false,
            mention: '',
            subChildren: null,
            offset: 0,
            commentLoadMore: 10,
        }),

        computed: {
            ...mapGetters('auth', ['authenticated', 'userId']),
            ...mapState({
                comment(state, getters) {
                    const comment = getters[`${this.store}/get`](this.thread)
                    const authUserId = getters['auth/userId']

                    return {
                        ...comment,
                        is_own: comment.user_id === authUserId,
                    }
                },

                children(state, getters) {
                    return getters[`${this.store}/thread`](this.thread)
                },

                commentRemain() {
                    if (this.subChildren) {
                        const contentRemain = this.children.length - this.subChildren.length

                        return (contentRemain < this.commentLoadMore) ? contentRemain : this.commentLoadMore
                    }

                    return 0
                },

                user(state, getters) {
                    return getters['entities/users/get'](this.comment.user_id)
                },
            }),
        },

        created() {
            if (this.children) {
                const targetComment = this.targetComment ? _indexOf(this.children, this.targetComment) : null

                if (targetComment !== null && targetComment >= 0) {
                    this.offset = (this.children.length - targetComment === 1) ? targetComment - 1 : targetComment
                    this.subChildren = this.children.slice(this.offset, this.children.length)
                } else if (this.children.length >= 4) {
                    this.offset = this.children.length - 2
                    this.subChildren = this.children.slice(this.offset, this.children.length)
                } else {
                    this.subChildren = this.children
                }
            }
        },

        beforeCreate() {
            // eslint-disable-next-line import/no-self-import
            this.$options.components.Thread = require('./Thread.vue').default
        },

        methods: {
            toUser,
            ...mapActions('auth', ['showAuthDialog']),
            ...mapActions({
                onReply() {
                    if (!this.authenticated) {
                        this.showAuthDialog()
                    } else {
                        if (this.comment.in_reply_to_comment) {
                            this.mention = this.userId === this.user.id ? '' : `@${this.user.username} `
                        }
                        this.replying = true

                        return true
                    }

                    return false
                },

                reply(dispatch, formValues) {
                    return dispatch(`${this.store}/add`, {
                        commentable: this.commentable,
                        values: {
                            ...formValues.data,
                            ancestor_id: this.comment.id,
                        },
                    }).then((data) => {
                        if (this.comment.in_reply_to_comment) {
                            this.$emit('comment-success', data.id)
                        } else {
                            this.commentSuccess(data.id)
                        }
                    })
                },

                replySuccess() {
                    this.replying = false
                },

                update(dispatch, formValues) {
                    return dispatch(`${this.store}/update`, {
                        id: this.comment.hash_id,
                        values: formValues.data,
                    })
                },

                updateSuccess() {
                    this.editing = false
                },

                remove(dispatch, comment) {
                    return dispatch(`${this.store}/remove`, comment)
                },

                vote(dispatch, score) {
                    return dispatch(`${this.store}/vote`, {
                        id: this.comment.id,
                        value: score,
                    })
                },

                report(dispatch, formValues) {
                    const form = {
                        id: this.comment.hash_id,
                        values: formValues,
                    }

                    dispatch(`${this.store}/report`, form)
                },
            }),

            showMoreComment() {
                if (this.offset > 0) {
                    const index = this.offset - this.commentLoadMore > 0 ? this.offset - this.commentLoadMore : 0
                    const comments = this.children.slice(index, this.offset)

                    this.subChildren = comments.concat(this.subChildren)
                    this.offset -= this.commentLoadMore
                }
            },

            showAllComment() {
                this.subChildren = this.children
            },

            commentSuccess(commentId) {
                if (!this.subChildren) {
                    this.subChildren = []
                }
                this.subChildren.push(commentId)
            },
        },
    }
</script>


<style lang="scss">
    @import "../../../assets/sass/bootstrap/mixins";
    @import "../../../assets/sass/bootstrap/borders";

    .comment-thread {
        .dot-separator {
            margin: 0 .25rem;
        }

        .reply {
            margin-left: 1.5rem;
            border-top: 1px solid rgba(0,0,0,.1);
            padding: .5rem;
        }
    }

    .view-more-btn {
        color: #5488c7;

        &:hover {
            color: #5488c7;
        }
    }

    @include media-breakpoint-down(sm) {
        .comment-reply {
            margin-left: .75rem;
        }
    }

</style>
