// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <v-dialog :show.sync="showing" :title="$t('dialogMarkdownHelp.title')" class="md-help-dialog">
        <div v-once class="md-help-contents md-contents">
            <span><b>{{ $t('dialogMarkdownHelp.linkFull') }}</b>
            </span><a
                href="/helps/cach-su-dung-markdown-bxjvZYnwkJZ"
                class="md-link"
                target="_blank"
                rel="noopener"
            >
                <b>{{ $t('dialogMarkdownHelp.clickto') }}</b></a>
            <table>
                <thead>
                    <tr>
                        <th>Component</th>
                        <th>Markdown</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><b>{{ $t('dialogMarkdownHelp.row1.component') }}</b></td>
                        <td>{{ $t('dialogMarkdownHelp.row1.markdown') }}</td>
                    </tr>
                    <tr>
                        <td><h6>{{ $t('dialogMarkdownHelp.row2.component') }}</h6></td>
                        <td>{{ $t('dialogMarkdownHelp.row2.markdown') }}</td>
                    </tr>
                    <tr>
                        <td><i>{{ $t('dialogMarkdownHelp.row3.component') }}</i></td>
                        <td>{{ $t('dialogMarkdownHelp.row3.markdown') }}</td>
                    </tr>
                    <tr>
                        <td><code>{{ $t('dialogMarkdownHelp.row4.component') }}</code></td>
                        <td>{{ $t('dialogMarkdownHelp.row4.markdown') }}</td>
                    </tr>
                    <tr>
                        <td>
                            <code class="language-js">
                                <span>
                                    <span
                                        class="hljs-keyword"
                                    >
                                        {{ $t('dialogMarkdownHelp.row5.component1') }}
                                    </span>
                                    {{ $t('dialogMarkdownHelp.row5.component2') }}
                                </span>
                            </code>
                        </td>
                        <td>
                            {{ $t('dialogMarkdownHelp.row5.markdown1') }}<br>
                            {{ $t('dialogMarkdownHelp.row5.markdown2') }}<br>
                            ```
                            <hr>
                            {{ $t('dialogMarkdownHelp.row5.markdown4') }}<br>
                            ```javascript:app/index.js<br>
                            var s = 'Syntax Highlighting';<br>
                            ```
                            <hr>
                            {{ $t('dialogMarkdownHelp.row5.markdown7') }}
                            <a
                                href="https://viblo.asia/helps/cach-su-dung-markdown-bxjvZYnwkJZ#_nhan-manh-highlight-6"
                                target="_blank"
                                rel="noopener"
                            >{{ $t('dialogMarkdownHelp.row5.markdown8') }}</a>
                        </td>
                    </tr>
                    <tr>
                        <td><a href="#">{{ $t('dialogMarkdownHelp.row6.component') }}</a></td>
                        <td>{{ $t('dialogMarkdownHelp.row6.markdown') }}</td>
                    </tr>
                    <tr>
                        <td>{{ $t('dialogMarkdownHelp.row7.component') }}</td>
                        <td>![alt](http://~)</td>
                    </tr>
                    <tr>
                        <td><h4>{{ $t('dialogMarkdownHelp.row8.component') }}</h4></td>
                        <td>
                            {{ $t('dialogMarkdownHelp.row8.markdown1') }}<br>
                            {{ $t('dialogMarkdownHelp.row8.markdown2') }}<br>
                            {{ $t('dialogMarkdownHelp.row8.markdown3') }}
                        </td>
                    </tr>
                    <tr>
                        <td><li>{{ $t('dialogMarkdownHelp.row10.component') }}</li></td>
                        <td>{{ $t('dialogMarkdownHelp.row10.markdown') }}</td>
                    </tr>
                    <tr>
                        <td>
                            {{ $t('dialogMarkdownHelp.row11.component') }}
                        </td>
                        <td>1. Item</td>
                    </tr>
                    <tr>
                        <td>{{ $t('dialogMarkdownHelp.row12.component') }}</td>
                        <td>* * *</td>
                    </tr>
                    <tr>
                        <td>
                            <blockquote style="margin: 0;">
                                Blockquotes
                            </blockquote>
                        </td>
                        <td>&gt; Blockquotes</td>
                    </tr>
                    <tr>
                        <td>{{ $t('dialogMarkdownHelp.row14.component') }}</td>
                        <td>
                            <div>{{ $t('dialogMarkdownHelp.row14.markdown1') }}</div>
                            <div>| - | - |</div>
                            <div>{{ $t('dialogMarkdownHelp.row14.markdown3') }}</div>
                        </td>
                    </tr>
                    <tr>
                        <td>Escape markdown</td>
                        <td>\</td>
                    </tr>
                    <tr>
                        <td>{{ $t('dialogMarkdownHelp.row16.component') }}</td>
                        <td>
                            <div>{{ $t('dialogMarkdownHelp.row16.markdown1') }}</div>
                            <small>
                                {{ $t('dialogMarkdownHelp.row16.markdown2') }}
                            </small>
                        </td>
                    </tr>
                    <tr>
                        <td>{{ $t('dialogMarkdownHelp.row17.component') }}</td>
                        <td>
                            <ul class="pl-1 mb-0">
                                <li>
                                    <div>Inline: $f(x) = x$</div>
                                    <small>{{ $t('dialogMarkdownHelp.row17.markdown2') }}</small>
                                </li>
                                <li>
                                    {{ $t('dialogMarkdownHelp.row17.markdown3') }}<br>
                                    $$<br>
                                    f(x) = x<br>
                                    $$<br>
                                </li>
                            </ul>
                            <div>
                                <a
                                    href="/helps/math"
                                    target="_blank"
                                    rel="noopener"
                                >{{ $t('dialogMarkdownHelp.row17.markdown3') }}</a>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>{{ $t('dialogMarkdownHelp.row18.component') }}</td>
                        <td>
                            <div>
                                {{ $t('dialogMarkdownHelp.row18.markdown1') }}<b>
                                    {{ $t('dialogMarkdownHelp.row18.markdown2') }}</b>
                                {{ $t('dialogMarkdownHelp.row18.markdown3') }}
                            </div>
                            <div>
                                <code>
                                    &lt;div align="left|right|center|justify"&gt;<br><br>
                                    &nbsp;&nbsp;{{ $t('dialogMarkdownHelp.row18.markdown5') }}<br><br>
                                    &lt;/div&gt;
                                </code>
                            </div>
                            <small>{{ $t('dialogMarkdownHelp.row18.markdown7') }}</small>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </v-dialog>
</template>

<script>
    export default {
        data: () => ({
            showing: false,
        }),

        methods: {
            show() {
                this.showing = true
            },
        },
    }
</script>

<style lang="scss">
    @import "../../assets/sass/bootstrap/colors";
    @import "../../assets/sass/bootstrap/mixins";

    .md-link {
        text-decoration: none!important;
    }

    .md-help-dialog {
        &.dialog__wrapper {
            .dialog {
                margin: 20px auto 0;
            }
        }

        .md-help-contents {
            overflow: auto;
            max-height: calc(100vh - 13vh) !important;
        }

        table {
            width: 100%;

            th,
            td {
                padding: 0.5rem;
                border: solid 1px $gray-light;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .md-help-dialog {
            .md-help-contents {
                font-size: .8rem;
            }
        }
    }
</style>
