// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="user-series">
        <series-list :data="series" />

        <pagination
            v-if="pagination"
            :current-page="pagination.current_page"
            :last-page="pagination.total_pages"
        />
    </div>
</template>

<script>
    import { getUserSeries } from 'viblo-sdk/api/users'
    import Pagination from '~/components/Pagination.vue'
    import SeriesList from '~/components/series/List.vue'
    import { normalizeSeriesList } from '~/components/entities/normalize'
    import { handlePageError, getContentSubDomain } from '~/utils/pages'

    export default {

        components: {
            SeriesList,
            Pagination,
        },
        async asyncData({
            store, params, query, req, error,
        }) {
            try {
                const host = process.client ? window.location.host : req.headers.host
                const user = store.state.config.vars.is_special_profile ? getContentSubDomain(host) : params.user
                const series = await getUserSeries(user, {
                    ...query,
                    limit: store.state.settings.perPage,
                })

                const normalized = normalizeSeriesList(series.data)
                store.commit('entities/users/put', normalized.entities.users)

                return {
                    series: series.data,
                    pagination: series.meta.pagination,
                    host,
                }
            } catch (e) {
                return handlePageError(error, e)
            }
        },

        watchQuery: ['page'],
    }
</script>
