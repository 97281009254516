// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <v-dialog :show.sync="show" title="Delete Image" size="small">
        <div class="mb-1">
            {{ $t('deleteImage.title') }}
        </div>
        <div v-if="attachments.length !== 0">
            <p>If you delete, the image in your posts will be deleted:</p>
            <div v-for="(attachment, index) in attachments" :key="index">
                <div v-if="attachment.attachable.url">
                    <p class="title">
                        <a :href="attachment.attachable.url">
                            {{ attachment.attachable.title || attachment.attachable_type + attachment.attachable_id }}
                        </a>
                    </p>
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">{{ $t('deleteImage.cancel') }}</el-button>
            <el-button type="primary" @click="deleteImage">{{ $t('deleteImage.confirm') }}</el-button>
        </span>
    </v-dialog>
</template>

<script>
    import { deleteImage } from 'viblo-sdk/api/me'
    import { getAttachments } from '~/api/support'

    export default {
        data: () => ({
            image: Object,
            attachments: [],
            show: false,
        }),

        methods: {
            open(image) {
                this.image = image
                this.fetch()
                this.show = true
            },

            close() {
                this.show = false
            },

            async fetch() {
                this.attachments = await getAttachments({ id: this.image.id }).then(({ data: { data } }) => data)
            },

            async deleteImage() {
                try {
                    await deleteImage(this.image.id)
                    this.$emit('deleted', this.image.id)
                    this.$message.success('Image deleted successfully.')
                } catch (e) {
                    this.$message.error('Sorry, something went wrong.')
                } finally {
                    this.close()
                }
            },
        },
    }
</script>
