// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div v-if="isSelf || skills.length">
        <section-title :title="$t('profile.technicalSkills.title')" />

        <tags-list :tags="skills" class="technical-skills mb-2">
            <template slot="append">
                <el-button
                    v-if="isSelf && skills.length"
                    v-tooltip
                    :title="$t('profile.technicalSkills.updateSkill')"
                    type="text"
                    icon="el-icon-edit"
                    class="icon-edit-skill py-0"
                    @click="redirect"
                />

                <el-alert
                    v-if="!skills.length"
                    :closable="false"
                    type="info"
                    title
                >
                    <span class="mr-05">{{ $t('profile.technicalSkills.noSkill') }}</span>
                    <el-button v-if="isSelf" type="text" @click="redirect">
                        {{ $t('profile.technicalSkills.update') }}
                    </el-button>
                </el-alert>
            </template>
        </tags-list>
    </div>
</template>

<script>
    import SectionTitle from './SectionTitle.vue'
    import TagsList from '~/components/shared/tags/List.vue'


    export default {
        name: 'TechnicalSkills',

        components: {
            SectionTitle,
            TagsList,
        },

        props: {
            profile: Object,
            isSelf: Boolean,
            skills: {
                type: Array,
                default: () => [],
            },
        },

        methods: {
            redirect() {
                this.$router.push('/me/technical-skills')
            },
        },
    }
</script>

<style lang="css" scoped>
    .technical-skills .icon-edit-skill {
        font-size: 1rem;
        position: relative;
        top: -3px;
    }
</style>
