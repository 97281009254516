// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="py-2 px-1">
        <div
            class="d-flex search-sort-bar bookmark align-items-baseline justify-content-between mb-2"
        >
            <el-dropdown size="medium" trigger="click">
                <span class="cursor-pointer">
                    {{ $t("myClips.type") }} <b>{{ $t(activeType.label) }}</b>
                </span>

                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                        v-for="(entityType, key) in entityTypes"
                        :key="key"
                        :class="{
                            'el-dropdown-menu__item--active':
                                entityType == activeType,
                        }"
                        @click.native="sort(entityType)"
                    >
                        {{ $t(entityType.label) }}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>

            <el-dropdown size="medium" trigger="click">
                <span class="cursor-pointer">
                    {{ $t("myClips.sort") }} <b>{{ $t(activeSort.label) }}</b>
                </span>

                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                        v-for="(sortType, key) in sortTypes"
                        :key="key"
                        :class="{
                            'el-dropdown-menu__item--active':
                                sortType.field == activeSort.field,
                        }"
                        @click.native="sort(null, sortType)"
                    >
                        {{ $t(sortType.label) }}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>

        <i
            v-if="data.length <= 0 && loading"
            class="fa fa-circle-o-notch fa-spin sb-icon loading"
            aria-hidden="true"
        />
        <list-mix
            :data="data"
            :type="type"
            :pagination="pagination"
            :loading="loading"
            :load="load"
            class="container-fluid"
        />
        <client-only>
            <InfiniteLoading
                :key="infiniteKey"
                :distance="50"
                spinner="spiral"
                @infinite="load"
            >
                <span slot="no-results" />
                <span slot="no-more" />
            </InfiniteLoading>
        </client-only>
    </div>
</template>

<script>
    import _filter from 'lodash/filter'
    import _upperFirst from 'lodash/upperFirst'
    import InfiniteLoading from 'vue-infinite-loading'
    import { fetchUserPosts } from '~/api/users'
    import ListMix from '~/components/subdomains-theme-2/ListMix.vue'
    import { handlePageError, getContentSubDomain } from '~/utils/pages'

    const entityTypes = [
        {
            label: 'myClips.posts',
            field: 'posts',
        },
        {
            label: 'myClips.series',
            field: 'series',
        },
        {
            label: 'myClips.questions',
            field: 'questions',
        },
    ]

    const sortTypes = [
        {
            label: 'myClips.published',
            field: 'published_at',
        },
        {
            label: 'myClips.clipAt',
            field: 'clipped_at',
        },
    ]

    export default {
        components: { ListMix, InfiniteLoading },

        data() {
            return {
                sortTypes,
                entityTypes,
                pagination: null,
                data: [],
                type: null,
                loading: false,
                infiniteKey: 0,
            }
        },

        computed: {
            activeType() {
                const active = this.$route.params.clip || 'posts'
                return _filter(entityTypes, type => type.field === active)[0]
            },
            activeSort() {
                const active = this.$route.query.sort || 'published_at'
                return _filter(sortTypes, type => type.field === active)[0]
            },
        },

        async asyncData({
            query, store, route, params, error, req,
        }) {
            try {
                const type = route.params.clip
                const sort = route.query.sort || 'published_at'
                const page = query.page || 1
                const host = process.client
                    ? window.location.host
                    : req.headers.host
                const user = store.state.config.vars.is_special_profile
                    ? getContentSubDomain(host)
                    : params.user
                const fetchedData = await fetchUserPosts(user, `clipped-${type}`, {
                    sort,
                    page,
                    limit: store.state.settings.perPage,
                }).then(({ data }) => data)
                return {
                    pagination: fetchedData.meta.pagination,
                    data: fetchedData.data,
                    type,
                }
            } catch (e) {
                return handlePageError(error, e)
            }
        },

        // eslint-disable-next-line consistent-return
        async fetch() {
            const req = this.$nuxt.context.ssrContext?.req
            const store = this.$nuxt.context.store
            const route = this.$nuxt.context.route
            const params = this.$nuxt.context.route.params
            const page = this.pagination?.current_page ? this.pagination?.current_page + 1 : 1
            const error = this.$nuxt.context.error

            try {
                const type = route.params.clip
                const sort = route.query.sort || 'published_at'
                const host = process.client
                    ? window.location.host
                    : req.headers.host
                const user = store.state.config.vars.is_special_profile
                    ? getContentSubDomain(host)
                    : params.user

                this.loading = true
                const fetchedData = await fetchUserPosts(user, `clipped-${type}`, {
                    sort,
                    page,
                    limit: store.state.settings.perPage,
                }).then(({ data }) => data)

                this.pagination = fetchedData.meta.pagination
                this.data = [...this.data, ...fetchedData.data]
                this.type = type
                this.loading = false
            } catch (e) {
                this.loading = false
                handlePageError(error, e)
            }
        },

        methods: {
            _filter,
            _upperFirst,

            async sort(entityType, sortType) {
                entityType = entityType || this.activeType
                sortType = sortType || this.activeSort

                this.infiniteKey += 1

                if (this.$store.state.config.vars.is_special_profile) {
                    this.$router.push(
                        `/clips/${entityType.field}?sort=${sortType.field}`
                    )
                } else {
                    this.$router.push(
                        `/u/${this.$route.params.user}/clips/${entityType.field}?sort=${sortType.field}`
                    )
                }
            },
            async load($state) {
                const currentPage = this.pagination?.current_page
                const totalPages = this.pagination?.total_pages
                if (currentPage < totalPages) {
                    await this.$fetch()
                    $state.loaded()
                } else {
                    $state.complete()
                }
            },
        },

        watchQuery: true,
    }
</script>

<style lang="scss">
.theme-dark {
    .bookmark.search-sort-bar .el-dropdown {
        color: #fff;
    }
}
</style>
