// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="post-feed-org px-1 py-2" :class="modeThemeSubdomain">
        <Contact />
    </div>
</template>

<script>
    import Contact from '~/components/users/Contact.vue'
    import { handlePageError, getContentSubDomain } from '~/utils/pages'

    export default {
        name: 'ContactUser',

        components: {
            Contact,
        },

        computed: {
            modeThemeSubdomain() {
                return this.$store.state.typeControl.modeTheme
            },
        },

        async fetch({
            store, params, error, req,
        }) {
            try {
                const host = process.client ? window.location.host : req.headers.host
                const user = store.state.config.vars.is_special_profile ? getContentSubDomain(host) : params.user

                return await store.dispatch('profile/contact/fetch', user)
            } catch (e) {
                return handlePageError(error, e)
            }
        },
    }
</script>

<style scoped lang="scss">
.theme-dark {
  background-color: #000000;
}
</style>
