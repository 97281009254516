// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <Empty v-if="posts.length <= 0 && !pinnedItem">
        <p> {{ $t('empty') }} </p>
    </Empty>

    <div v-else class="post-feed-org py-2 px-1">
        <p class="title-all-posts">
            {{ $t('allPosts') }}
        </p>
        <pagination
            :current-page="pagination.current_page"
            :last-page="pagination.total_pages"
            class="mb-4"
        />

        <FeedItem
            v-for="(post, index) in posts"
            :key="index"
            :post="post"
            :is-owner="isOwner"
        />

        <Pagination
            :current-page="pagination.current_page"
            :last-page="pagination.total_pages"
        />
    </div>
</template>

<script>
    import _get from 'lodash/get'
    import _map from 'lodash/map'
    import _assign from 'lodash/assign'
    import { mapState } from 'vuex'

    import Empty from '~/components/shared/Empty.vue'
    import Pagination from '~/components/subdomains/Pagination.vue'
    import FeedItem from '~/components/subdomains/posts/PostFeedItem.vue'

    export default {
        components: {
            Empty,
            Pagination,
            FeedItem,
        },

        props: {
            store: {
                type: String,
                required: true,
            },

            shouldHighlight: {
                type: Function,
                default: () => false,
            },

            pinnedItem: Array,

            isOwner: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            ...mapState({
                postIds(state) {
                    return _get(state, [...this.store.split('/'), 'all'])
                },

                pagination(state) {
                    return _get(state, [...this.store.split('/'), 'pagination'])
                },
            }),

            ...mapState('settings', ['layout']),

            posts() {
                const idPostsPin = []
                if (this.pinnedItem) {
                    this.pinnedItem.forEach((item) => {
                        idPostsPin.push(item.id)
                    })
                }
                const postsFeed = this.postIds.filter(item => !idPostsPin.includes(item))

                return _map(postsFeed, (id) => {
                    const entity = this.$store.getters['entities/posts/get'](id)
                    const data = _get(this.$store.getters, `${this.store}/get`)(id)

                    return _assign({}, entity, data)
                })
            },
        },
    }
</script>
