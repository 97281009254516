// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="pt-2 pb-2">
        <i
            v-if="flaggedContents.length <= 0 && loading"
            class="fa fa-circle-o-notch fa-spin sb-icon loading"
            aria-hidden="true"
        />
        <chart
            v-if="hasChart && !loading"
            :data="chartData"
        />
        <div v-if="flaggedContents.length > 0">
            <p class="title-list-report mt-2">
                {{ $t('profile.reputationHistory') }}
            </p>

            <Item v-for="(report, index) in flaggedContents" :key="index" :report="report" />

            <client-only>
                <InfiniteLoading :distance="50" spinner="spiral" @infinite="load">
                    <span slot="no-results" />
                    <span slot="no-more" />
                </InfiniteLoading>
            </client-only>
        </div>
    </div>
</template>

<script>
    import _get from 'lodash/get'
    import _map from 'lodash/map'
    import _isEmpty from 'lodash/isEmpty'
    import InfiniteLoading from 'vue-infinite-loading'
    import { getReputation, getFlaggedContents } from '~/api/users'
    import Item from '~/components/reports/widgets/Item.vue'
    import Chart from '~/components/charts/ChartReputation.vue'
    import { handlePageError, getContentSubDomain } from '~/utils/pages'

    export default {
        name: 'UserReputations',

        components: {
            Chart,
            Item,
            InfiniteLoading,
        },

        data() {
            return {
                chart: {},
                flaggedContents: [],
                pagination: null,
                loading: false,
            }
        },

        scrollToTop: true,

        watchQuery: ['page'],

        computed: {
            chartData() {
                return {
                    labels: this.chart.date,
                    series: [this.chart.data],
                }
            },
            hasChart() {
                return !_isEmpty(this.chart.date)
            },
        },

        // eslint-disable-next-line consistent-return
        async fetch() {
            const req = this.$nuxt.context.ssrContext?.req
            const store = this.$nuxt.context.store
            const params = this.$nuxt.context.route.params
            const error = this.$nuxt.context.error
            const page = this.pagination ? { page: this.pagination.current_page + 1 } : { page: 1 }

            try {
                const host = process.client ? window.location.host : req.headers.host
                const user = store.state.config.vars.is_special_profile ? getContentSubDomain(host) : params.user
                this.loading = true
                const reputation = await getReputation(user)
                const authUsername = _get(store.state.auth, 'user.username')
                const result = authUsername === user
                    ? await getFlaggedContents(page)
                    : null

                this.chart = {
                    data: _map(reputation.data, reput => reput.total_reputation),
                    date: _map(reputation.data, reput => reput.date || []),
                }
                this.flaggedContents = [...this.flaggedContents, ...result.data]
                this.pagination = result.meta.pagination
                this.loading = false
            } catch (e) {
                this.loading = false
                return handlePageError(error, e)
            }
        },

        methods: {
            async load($state) {
                if (this.pagination?.current_page < this.pagination?.total_pages) {
                    await this.$fetch()
                    $state.loaded()
                } else {
                    $state.complete()
                }
            },
        },
    }
</script>
