// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div>
        <FollowingFilter />

        <el-alert
            v-if="!organizations.length"
            type="warning"
            show-icon
        >
            {{ $t('organization.listJoined.first') }}
            {{ $t('organization.listJoined.organization') }}
            {{ $t('organization.listJoined.last') }}
        </el-alert>

        <organization-list :organizations="organizations" :type="true" />

        <pagination
            :current-page="pagination.current_page"
            :last-page="pagination.total_pages"
        />
    </div>
</template>

<script>
    import { getFollowingsWithUser } from '~/api/organizations'
    import { handlePageError, getContentSubDomain } from '~/utils/pages'
    import Pagination from '~/components/Pagination.vue'
    import OrganizationList from '~/components/organizations/followings/List.vue'
    import { loginWithSSR } from '~/utils/login'
    import FollowingFilter from '~/components/shared/user/FollowingFilter.vue'

    export default {
        name: 'UserFollowing',

        components: {
            FollowingFilter,
            OrganizationList,
            Pagination,
        },

        async asyncData({
            query, error, store, req, redirect, params,
        }) {
            try {
                const host = process.client ? window.location.host : req.headers.host
                const user = store.state.config.vars.is_special_profile ? getContentSubDomain(host) : params.user
                const response = await getFollowingsWithUser(user, query)

                return {
                    organizations: response.data,
                    pagination: response.meta.pagination,
                }
            } catch (e) {
                if (e.response && e.response.status === 401) {
                    store.commit('auth/SET_USER', null)
                    store.commit('auth/SET_GUEST', true)

                    return loginWithSSR(req, redirect)
                }

                return handlePageError(error, e)
            }
        },

        watchQuery: ['page'],
    }
</script>
