// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <v-dialog
        :show.sync="show"
        class="dialog-pick-post-pin"
        @close="close"
    >
        <template slot="title">
            <div class="d-flex dialog-title">
                <span class="pin-icon"><img src="@/static/images/icon-pin.svg"></span>
                <h3>{{ $t(title) }}</h3>
            </div>
        </template>
        <client-only>
            <InfiniteLoading
                v-show="!finishLoad"
                ref="infiniteLoading"
                :distance="100"
                spinner="spiral"
                @infinite="getPost"
            />
        </client-only>
        <PickPostPin
            v-if="finishLoad"
            :posts="listPost"
            :post-pin="postPin"
            :post-pin-id="postPinIds"
            :is-loading="loading"
            :handle-pined-post="handlePinedPost"
        />

        <h2 class="mt-1 alert-post-pin">
            {{ $t('subdomain.alertPostPin') }}
        </h2>

        <div class="mt-1">
            <el-button
                type="primary"
                :disabled="canSavepin"
                @click.native="handleSavePinedPost"
            >
                {{ $t('createPost.buttonSave') }}
            </el-button>
            <el-button
                type="danger"
                @click.native="handleCancel"
            >
                {{ this.$t('confirm.cancel'), }}
            </el-button>
            <el-button
                type="warning"
                @click.native="handleReset"
            >
                {{ this.$t('pinPostOrg.reset'), }}
            </el-button>
        </div>
    </v-dialog>
</template>

<script>
    import _find from 'lodash/find'
    import InfiniteLoading from 'vue-infinite-loading'
    import { fetchPostOrganization } from '~/api/organizations'
    import { fetchUserPosts } from '~/api/users'
    import PickPostPin from './PickPostPin.vue'

    export default {
        components: {
            PickPostPin,
            InfiniteLoading,
        },

        props: {
            title: {
                type: String,
                default: 'subdomain.PinnedPosts',
            },
            pinnedPost: {
                type: Array,
                default: () => [],
            },
            subdomain: String,
            isOrgSubdomain: Boolean,
        },

        data() {
            return {
                postPin: [...this.pinnedPost],
                postPinIds: [],
                show: false,
                loading: -1,
                canSavepin: true,
                listPost: [],
                finishLoad: false,
                pagination: null,
            }
        },

        mounted() {
            this.setIdPostPin()
        },

        methods: {
            open() {
                if (this.$refs.infiniteLoading) {
                    this.$refs.infiniteLoading.$emit('$InfiniteLoading:reset')
                }
                this.finishLoad = false
                this.show = true
            },
            close() {
                this.show = false
            },
            handleCancel() {
                this.postPin = [...this.pinnedPost]
                this.setIdPostPin()
                this.close()
            },
            handleReset() {
                this.postPin = []
                this.postPinIds = []
                this.canSavepin = true
            },
            handlePinedPost(post, index) {
                if (_find(this.postPinIds, id => id && id.id === post.id)) {
                    this.$message.error('Bài viết đã được chọn')
                } else {
                    this.loading = index
                    this.postPin[index] = post
                    this.postPinIds[index] = {
                        id: post.id,
                        position: index + 1,
                    }
                    this.canSavepin = this.canSave()

                    setTimeout(() => {
                        this.loading = -1
                    }, 1000)
                }
            },
            canSave() {
                switch (this.postPin.length) {
                    case 3:
                        return !(this.postPin[0] && this.postPin[1] && this.postPin[2])
                    case 6:
                        return !(this.postPin[0] && this.postPin[1] && this.postPin[2]
                            && this.postPin[3] && this.postPin[4] && this.postPin[5])
                    default:
                        return true
                }
            },
            handleSavePinedPost() {
                this.$confirm(this.$t('pinPostOrg.confirmPinPost.content'), this.$t('pinPostOrg.confirmPinPost.title'), {
                    confirmButtonText: this.$t('confirm.ok'),
                    cancelButtonText: this.$t('confirm.cancel'),
                })
                    .then(() => this.$emit('set-posts-pin', this.postPinIds))
            },

            setIdPostPin() {
                const listIds = []
                if (this.pinnedPost.length >= 6) {
                    for (let i = 0; i < 6; i += 1) {
                        listIds[i] = {
                            id: this.pinnedPost[i].id,
                            position: this.isOrgSubdomain
                                ? this.pinnedPost[i].organization_pinned_at
                                : this.pinnedPost[i].profile_pin_position,
                        }
                    }
                } else {
                    for (let i = 0; i < this.pinnedPost.length; i += 1) {
                        listIds[i] = {
                            id: this.pinnedPost[i].id,
                            position: this.isOrgSubdomain
                                ? this.pinnedPost[i].organization_pinned_at
                                : this.pinnedPost[i].profile_pin_position,
                        }
                    }
                }
                this.postPinIds = listIds
            },

            async getPost($state) {
                const page = this.pagination ? { page: this.pagination.current_page + 1 } : { page: 1 }

                const posts = this.isOrgSubdomain
                    ? await fetchPostOrganization(this.subdomain, page).then(({ data }) => data)
                    : await fetchUserPosts(this.subdomain, 'posts', page).then(({ data: res }) => res)

                this.listPost = [...this.listPost, ...posts.data]
                this.pagination = posts.meta.pagination

                if (this.pagination.current_page < this.pagination.total_pages) {
                    $state.loaded()
                } else {
                    this.finishLoad = true
                    $state.complete()
                }
            },
        },
    }
</script>

<style lang="scss">
.btn-post-pin {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 60px;
}
.dialog-pick-post-pin .dialog {
    width: 60%;
}

.theme-dark {
    .dialog-pick-post-pin .dialog  {
        background: #353535;
    }
}

.dialog-pick-post-pin {
    .dialog-title {
        span {
            display: inline-block;
            color: red;
            font-size: 1.2em;
        }
        h3 {
            font-weight: 600;
            font-size: 1.5em;
            margin-left: 12px;
        }
    }
}
</style>

<style lang="scss" scoped>
.theme-dark {
    .dialog-title, .alert-post-pin {
        color: #fff;
    }
}
</style>
