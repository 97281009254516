// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div :class="type ? 'block-exhibition row' : ''">
        <Item
            v-for="(organization, index) in organizations"
            :key="index"
            :organization="organization"
            :type="type"
            :class="type ? 'col-sm-6 col-md-6 pr-0' : ''"
        />
    </div>
</template>

<script>
    import Item from './Item.vue'

    export default {
        components: {
            Item,
        },

        props: {
            organizations: {
                type: Array,
                default: () => [],
            },
            type: {
                type: Boolean,
                default: false,
            },
        },
    }
</script>
