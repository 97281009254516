// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="posts-list-item w-100">
        <div class="row w-100">
            <div class="posts-thumbnail col-md-6 d-md-flex flex-column d-none align-items-center w-100">
                <div class="d-none d-md-block position-relative">
                    <img
                        :src="list.thumbnail_url"
                        alt="Thumbnail Image"
                        class="w-100 mr-2"
                    >
                    <slot />
                </div>
                <div v-if="!list.system" class="w-100">
                    <Stats :stats="stats" />
                </div>
            </div>
            <div class="col-md-6 d-block">
                <div class="post-feed-item__info ml-05">
                    <div class="d-flex justify-content-between mb-05">
                        <div class="d-flex align-items-center">
                            <Avatar
                                :object="user"
                                :image="user.avatar"
                                :is-user="true"
                                :level-partner="user.level_partner"
                                :partner-width="44"
                                :partner-hight="44"
                                size="md"
                            />

                            <span class="username-user ml-05 text-dark"> {{ user.name }} </span>
                            <span
                                v-tooltip
                                :title="list.published_at | format-time"
                                class="ml-2 text-muted"
                            >
                                {{ list.published_at | humanize-time }}
                            </span>
                        </div>
                    </div>

                    <PostTitleInline
                        :url="url"
                        :title="list.title"
                    />

                    <MarkdownPure class="description-all-posts" :contents="convertImgToText(list.contents)" />

                    <PostTags class="mt-1" :tags="tags.tags" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import _get from 'lodash/get'
    import { series as toSeries, user as toUser } from '~/utils/url'
    import Stats from '~/components/subdomains/shared/Stats.vue'
    import Avatar from '~/components/shared/Avatar/Avatar.vue'
    import PostTitleInline from '~/components/subdomains/posts/widgets/TitleInline.vue'
    import MarkdownPure from '~/components/contents/MarkdownPure.vue'
    import PostTags from '~/components/posts/widgets/Tags.vue'

    export default {
        components: {
            Avatar,
            Stats,
            PostTitleInline,
            MarkdownPure,
            PostTags,
        },

        props: {
            list: {
                type: Object,
                required: true,
            },

            showPreview: Boolean,

            showOrganization: {
                type: Boolean,
                default: true,
            },

            pinnedPost: {
                type: Boolean,
                default: false,
            },
            isOwner: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            ...mapGetters('auth', ['hasRole', 'isAdmin']),

            url() {
                return toSeries(this.list)
            },

            toUser() {
                return toUser(this.user)
            },

            user() {
                const user = _get(this.list, 'user.data')

                return typeof user === 'object' && !!user
                    ? user
                    : this.$store.getters['entities/users/get'](user || this.list.user_id)
            },

            stats() {
                return [
                    {
                        name: this.$t('question.views').concat(`${this.list.views_count}`),
                        src: '/images/icon-eye.png',
                        value: this.list.views_count,
                    },
                    {
                        name: this.$t('question.votes').concat(`${this.list.points}`),
                        src: '/images/icon-like.png',
                        value: this.list.points,
                    },
                    {
                        name: this.$t('userInline.posts').concat(`${this.list.posts_count}`),
                        src: '/images/icon-post-count.png',
                        value: this.list.posts_count,
                    },
                    {
                        name: this.$t('question.comments').concat(`${this.list.comments_count}`),
                        src: '/images/icon-cmt.png',
                        value: this.list.comments_count,
                    },
                    {
                        name: this.$t('userInline.clips').concat(`${this.list.clips_count}`),
                        src: '/images/icon-bookmark.png',
                        value: this.list.clips_count,
                    },

                ]
            },

            tags() {
                return {
                    tags: this.list.tags ? this.list.tags.data : null,
                    promoted: this.list.promoted,
                    trending: this.list.trending,
                    system: this.list.system_tag,
                }
            },
        },

        methods: {
            convertImgToText(content) {
                return content.replace(/!\[\]\(.+?\)/g, '[IMG]')
            },
        },
    }
</script>

<style lang="scss">
    @import "~/assets/sass/bootstrap/borders";

    .posts-list-item {
        display: flex;
        align-items: flex-start;
        padding: .5rem;
        margin-bottom: 2rem;

        &__info {
            width: 100%;
        }

        .post-meta--inline {
            display: flex;
            flex-wrap: wrap;
            font-size: .8rem;
            margin-bottom: .25rem;
            align-items: center;
        }

        .commentators {
            .avatar {
                img {
                    width: 1.3rem;
                }
            }
        }

        .pinned-icon {
            margin-left: auto;
            font-size: 1rem;
            color: #5488c7;
            transform: rotate(15deg)
        }

        .el-icon-share {
            color: #9b9b9b;
        }

        .posts-thumbnail img {
            aspect-ratio: 16 / 9;
        }

        .description-all-posts {
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
        }
    }

    @media screen and (min-width: 992px) and (max-width: 1100px) {
        .posts-thumbnail {
            padding-left: 5px;
            padding-right: 5px;
        }
    }
</style>
