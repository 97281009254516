// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <footer class="d-flex text-muted">
        <comment-vote
            :send="sendVote"
            :score="comment.points"
            :value="comment.rated_value"
            :is-own="comment.is_own"
            :disabled="authenticated && (comment.is_own || !canRateComment)"
            :rateable="{ type: 'Comment', id: comment.hash_id }"
        />

        <span class="text-muted mx-05">|</span>

        <a
            v-tooltip
            :title="$t('activities.comment.reply')"
            class="mr-05 cursor-pointer"
            @click="$emit('reply')"
        >
            <span>{{ $t('detailQuestion.commentHasNotAccepted.reply') }}</span>
        </a>

        <share-tip
            :text="toComment"
            :sharing-id="`comment-${comment.hash_id}`"
            :label="$t('activities.comment.shareLinkComment')"
            :sharing-title="$t('activities.comment.commentAnswer')"
            class="mr-05 cursor-pointer"
        />

        <comment-menu
            :comment="comment"
            size="small"
            class="ml-05"
            @edit="$emit('edit')"
            @report="$emit('report')"
            @delete="destroy"
        />
    </footer>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'
    import { comment as toComment, absolute } from '~/utils/url'
    import ShareTip from '~/components/widgets/ShareTip.vue'
    import CommentVote from './Vote.vue'
    import CommentMenu from './Menu.vue'

    export default {
        components: {
            ShareTip,
            CommentVote,
            CommentMenu,
        },

        props: {
            comment: Object,
            sendVote: Function,
            deleteComment: Function,
            admin: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            ...mapState('auth', ['user']),

            ...mapGetters('auth', [
                'canRateComment',
                'authenticated',
                'isAdmin',
            ]),

            toComment() {
                return absolute(toComment(this.comment))
            },
        },

        methods: {
            destroy() {
                return this.deleteComment(this.comment)
            },
        },
    }
</script>
