// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="ct-chart ct-perfect-fourth posts-tendency" />
</template>
<script>
    export default {
        props: {
            data: {
                type: Object,
                default: () => {},
                require: true,
            },
            width: {
                type: Number,
                default: 200,
            },
            height: {
                type: Number,
                default: 200,
            },
        },

        data: () => ({ chart: null }),

        watch: {
            data(newData) {
                this.renderChart(newData, this.defaultOptions)
            },
        },

        mounted() {
            if (process.client) {
                this.renderChart()
            }
        },

        methods: {
            renderChart() {
                if (process.client) {
                    const { data } = this

                    const Chartist = require('chartist')
                    require('chartist-plugin-tooltips/dist/chartist-plugin-tooltip')

                    this.chart = new Chartist.Pie(this.$el, data, {
                        donut: true,
                        donutWidth: 60,
                        startAngle: 270,
                        showLabel: false,
                        onlyStroke: true,
                        plugins: [
                            Chartist.plugins.tooltip(),
                        ],
                        series: [
                            {
                                className: 'ct-series-a',
                            },
                            {
                                className: 'ct-series-b',
                            },
                            {
                                className: 'ct-series-c',
                            },
                            {
                                className: 'ct-series-d',
                            },
                            {
                                className: 'ct-series-e',
                            },
                            {
                                className: 'ct-series-f',
                            },
                            {
                                className: 'ct-series-g',
                            },
                        ],
                    })
                    this.chart.update(data)
                }
            },
        },
    }
</script>
<style lang="scss">
    .chartist-tooltip {
        opacity: 0;
        background: rgba(0, 0, 0, 0.8);
        margin: 0 10px 10px 0;
        color: #FFF;
        padding: 5px 10px;
        border-radius: 4px;
        position: absolute;
    }

    .chartist-tooltip.tooltip-show {
        opacity: 1;
        z-index: 100;
    }

    .ct-series-a .ct-slice-donut {
        stroke: #7357F6;
    }

    .ct-series-b .ct-slice-donut {
        stroke: #EC6B40;
    }

    .ct-series-c .ct-slice-donut {
        stroke: #ECC140;
;
    }

    .ct-series-d .ct-slice-donut {
        stroke: #00B2FF;
    }

    .ct-series-e .ct-slice-donut {
        stroke: #404040;
    }

    .ct-series-f .ct-slice-donut {
        stroke: #BAEDBD;
    }

    .ct-series-g .ct-slice-donut {
        stroke: #f05b4f;
    }
</style>
