// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="d-flex exhibition-item user" :class="{'list-followers-tags': isFollower}">
        <div>
            <Avatar
                :image="user.avatar"
                :object="user"
                :is-user="true"
                :level-partner="user.level_partner"
                :partner-width="65"
                :partner-hight="65"
                :size="user.level_partner ? 'xl-mini' : 'xl'"
            />
        </div>

        <div class="user-info overflow-hidden item-author" :class="{'stats-followers-tags': isFollower}">
            <div class="full-width name-author">
                <!-- Name -->
                <nuxt-link :to="profilePage" class="link name-user">
                    <b :class="{ 'is-banned' : user.banned_at}">{{ user.name }}</b>
                </nuxt-link>

                <!-- Stats -->
                <slot name="stats">
                    <div class="user-stats text-muted">
                        <span v-tooltip :title="`${$t('profile.reputations')}: ${user.reputation}`">
                            <i class="fa fa-star" aria-hidden="true" /> {{ user.reputation | formatNumber }}
                        </span>
                        <span v-tooltip :title="`${$t('profile.followers')}: ${user.followers_count}`">
                            <i class="fa fa-user-plus" aria-hidden="true" />
                            {{ user.followers_count | formatNumber }}
                        </span>
                        <span v-tooltip :title="`${$t('profile.posts')}: ${user.posts_count}`">
                            <i class="fa fa-pencil" aria-hidden="true" /> {{ user.posts_count | formatNumber }}
                        </span>
                    </div>
                </slot>
            </div>

            <!-- Follow button -->
            <div v-if="user.is_self" class="edit-profile">
                <a
                    class="btn btn-follow"
                    :href="editProfile"
                    target="_blank"
                    rel="noopener"
                >
                    {{ $t('profile.edit') }}
                </a>
            </div>

            <subscribe
                v-else
                ref="subscribe"
                :send="handleFollow"
                :subscribable="{ type: 'User', 'id': user.id }"
            >
                <button
                    :class="{following: user.following}"
                    class="btn btn-follow"
                    :disabled="user.banned_at"
                    @click="$refs.subscribe.handle(!user.following)"
                >
                    {{ user.following ? $t('profile.following') : $t('profile.follow') }}
                </button>
            </subscribe>
        </div>
    </div>
</template>

<script>
    import { routeToUser } from '~/lib/functions'
    import Avatar from '../shared/Avatar/Avatar.vue'
    import Subscribe from '~/components/widgets/Subscribe.vue'

    export default {

        components: {
            Avatar,
            Subscribe,
        },
        props: {
            user: {
                type: Object,
                required: true,
            },

            follow: Function,

            isFollower: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            profilePage() {
                return routeToUser(this.user)
            },

            editProfile() {
                return `${process.env.SSO_APP_URL}/profile/personal`
            },
        },

        methods: {
            handleFollow(value) {
                return this.follow({ username: this.user.username, value })
            },
        },
    }
</script>

<style lang="scss">
    @import "../../assets/sass/bootstrap/mixins";

    .exhibition-item {
        &.user {
            margin: 1.5rem 0;

            .user-info {
                margin-left: 20px;

                .name-author {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }

            .item-user {
                flex-wrap: wrap;
            }
        }
    }

    @include media-breakpoint-down(xs) {
        .exhibition-item {
            &.user {
                margin: 1rem 0;

                img.avatar, .avatar > img {
                    max-width: 60px;
                }

                .user-info {
                    margin-left: 10px;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    align-items: center;

                    .user-stats {
                        font-size: 0.8rem;
                    }
                }

                .btn-subscribe {
                    font-size: 0.8rem;
                    min-width: 80px;
                    margin-top: 0;
                }
            }
        }
    }

    @media only screen and (max-width: 575px) {
        .name-user {
            max-width: 50vw;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block !important;
        }

        .stats-followers-tags {
            flex-wrap: wrap;
        }
    }

    @media screen and (max-width: 500px) {
        .list-followers-tags {
            flex-direction: column;
            align-items: center;
        }

        .stats-followers-tags {
            flex-wrap: nowrap;
            flex-direction: column;
            align-items: center;
        }

        .list-followers-tags {
            .item-author {
                display: flex;
                flex-direction: column;
                align-items: center;

                .subscribe, .edit-profile {
                    width: 100%;
                    margin-top: 0.5rem;
                    display: flex;
                    justify-content: center;
                }

                .name-author {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }
        }
    }

    @media only screen and (max-width: 400px) {
        .exhibition-item {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .item-author {
            display: flex;
            flex-direction: column;
            align-items: center;

            .subscribe, .edit-profile {
                width: 100%;
                margin-top: 0.5rem;
                display: flex;
                justify-content: center;
            }

            .name-author {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }
</style>
<style lang="scss" scoped>
.theme-dark {
    .name-user, .user-stats.text-muted {
        color: #fff !important;
    }

    .btn-follow {
        &.following {
            background-color: #fff;
            color: #000 !important;
            border: 2px solid #fff;

            &:hover {
                border-color: darken(#0EA8FF, 10%);
                background-color: darken(#0EA8FF, 10%);
            }
        }

        &:not(.following) {
            border: 2px solid white;
            background-color: #000;
            color: #fff;

            &:hover {
                border-color: darken(#0EA8FF, 10%);
                background-color: darken(#0EA8FF, 10%);
                color: #fff;
            }
        }
    }
}
</style>
