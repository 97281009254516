// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="post-feed-org user-follow-org py-2 px-1">
        <FollowingFilter is-subdomain />

        <el-alert
            v-if="!organizations.length"
            type="warning"
            show-icon
        >
            {{ $t('organization.listJoined.first') }}
            {{ $t('organization.listJoined.organization') }}
            {{ $t('organization.listJoined.last') }}
        </el-alert>

        <organization-list :organizations="organizations" :type="true" />

        <pagination
            :current-page="pagination.current_page"
            :last-page="pagination.total_pages"
        />
    </div>
</template>

<script>
    import { getFollowingsWithUser } from '~/api/organizations'
    import { handlePageError, getContentSubDomain } from '~/utils/pages'
    import Pagination from '~/components/subdomains/Pagination.vue'
    import OrganizationList from '~/components/organizations/followings/List.vue'
    import { loginWithSSR } from '~/utils/login'
    import FollowingFilter from '~/components/shared/user/FollowingFilter.vue'

    export default {
        name: 'UserFollowing',

        components: {
            FollowingFilter,
            OrganizationList,
            Pagination,
        },

        async asyncData({
            query, error, store, req, redirect, params,
        }) {
            try {
                const host = process.client ? window.location.host : req.headers.host
                const user = store.state.config.vars.is_special_profile ? getContentSubDomain(host) : params.user
                const response = await getFollowingsWithUser(user, query)

                return {
                    organizations: response.data,
                    pagination: response.meta.pagination,
                }
            } catch (e) {
                if (e.response && e.response.status === 401) {
                    store.commit('auth/SET_USER', null)
                    store.commit('auth/SET_GUEST', true)

                    return loginWithSSR(req, redirect)
                }

                return handlePageError(error, e)
            }
        },

        watchQuery: ['page'],
    }
</script>

<style lang="scss">
.user-follow-org {
    .exhibition-item.user .user-info .subscribe .btn-follow {
        border-color: black;
        border-radius: 0.5rem;
        font-size: 15px !important;
        text-wrap: wrap;
        font-weight: 600;
        padding: 0.75rem;
        color: black;

        &:hover {
            background-color: #fff;
        }

        &.following:not(:disabled) {
            color: #fff;
            background-color: #0EA8FF;
            border-color: white;
        }
    }

    @media (max-width: 600px) {
        .exhibition-item.user .user-info .company-info {
            width: 160px;
        }
    }

    @media (min-width: 1280px) {
      .exhibition-item.user .user-info {
          display: flex;
          justify-content: space-between;

          .company-info {
              min-width: 200px;
              max-width: 200px;
              justify-content: flex-start !important;

              .company-status {
                  flex-wrap: wrap;
              }
          }

          .subscribe {
              margin-top: unset !important;
              margin-bottom: unset !important;
              margin-left: 10px;
          }
        }
    }
}
</style>
