<template>
    <div class="subdomain-organizations-list p-1 mb-2">
        <div class="d-flex justify-content-between align-items-center">
            <span class="title-navbar-top-members">{{ $t('profile.organizations.title') }}</span>
            <nuxt-link to="/organizations" class="see-all-members">
                {{ $t('navbarOrg.seeAll') }}
            </nuxt-link>
        </div>

        <section class="organizations-list">
            <div
                v-for="(organization, index) in organizations"
                :key="index"
                class="organizations-avatar w-100"
            >
                <item-oranization
                    :organization="organization"
                    @updateFollow="(payload) => updateOrgFollow(payload)"
                />
            </div>
        </section>
    </div>
</template>

<script>
    import itemOranization from './itemOranization.vue'

    export default {
        components: { itemOranization },
        props: {
            organizations: {
                type: Array,
                default: () => [],
            },
        },

        methods: {
            updateOrgFollow(data) {
                const foundIndex = this.organizations.findIndex(item => item.id === data.organization.id)
                this.organizations[foundIndex].following = data.value
                this.organizations[foundIndex].followers_count += (data.value ? 1 : -1)
            },
        },
    }
</script>

<style lang="scss">
@import "~/assets/sass/bootstrap/colors";

.subdomain-organizations-list {
    font-family: 'RobotoCondensed Medium', sans-serif;
    background-color: white;
    border-radius: 0.5em;
    font-weight: 500;

    .organizations-list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        margin-top: 12px;
    }

    .title-navbar-top-members {
        font-size: 20px;
    }

    .see-all-members {
        font-size: 16px;
        color: #0EA8FF;

        &:hover {
            opacity: .6;
            text-decoration: none;
        }
    }
}

.theme-dark {
    .subdomain-organizations-list {
        background-color: #000;

        .title-navbar-top-members, .see-all-members, .stats-item-organization {
            color: white !important;
        }

        .org-name{
            color: white;

            &:hover {
                color: $blue;
            }
        }

        .btn-follow-subdomain-small {
            border-color: white;
            color: white;
        }
    }
}
</style>
