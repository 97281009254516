// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="card border-0 rounded-0 mb-0">
        <div :class="className" class="card-block post-feed-item d-flex">
            <figure class="post-author-avatar">
                <div class="mr-05 d-flex position-relative flex-col justify-content-center align-items-center">
                    <avatar
                        :image="post.user.data.avatar"
                        :object="post.user.data"
                        :size="post.user.data.level_partner ? 'md-mini' : 'md'"
                        :is-user="true"
                        :level-partner="post.user.data.level_partner"
                        :partner-width="45"
                        :partner-hight="45"
                    />
                </div>
            </figure>

            <div class="ml-05 full-width">
                <post-header :post="post" />

                <div v-if="contentPreview && !onVideoPage" class="content word-break mt-05 mb-05">
                    <MarkdownPure :contents="post.contents_short" />
                </div>

                <div v-if="onVideoPage" class="content word-break mt-05 mb-05">
                    <div class="row">
                        <div
                            v-if="post.thumbnail_url"
                            class="col-md-5 col-sm-12 video-thumbnail"
                        >
                            <nuxt-link :to="toPost(post)">
                                <img :src="post.thumbnail_url" :alt="post.title" class="img-fluid m-0">
                            </nuxt-link>
                        </div>
                        <div :class="[post.thumbnail_url ? 'col-md-7 col-sm-12' : 'col-12']">
                            <MarkdownPure :contents="post.contents_short" />
                        </div>
                    </div>
                </div>

                <div
                    v-if="!post.system"
                    class="d-flex justify-content-between"
                >
                    <div class="d-flex">
                        <post-stats :stats="stats()" :title="titleStats" />
                        <commentators
                            v-if="post.commentators.data.length > 0"
                            :users="post.commentators.data"
                            class="commentators"
                        />
                    </div>
                    <post-score :score="post.points" :rated="post.rated_value" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _pick from 'lodash/pick'
    import { post as toPost, user as toUser } from '~/utils/url'

    import Avatar from '../shared/Avatar/Avatar.vue'
    import PostHeader from './Header.vue'
    import PostStats from './widgets/Stats.vue'
    import PostScore from '../widgets/Score.vue'
    import Commentators from '../shared/user/Participants.vue'

    export default {
        components: {
            Avatar,
            PostHeader,
            PostStats,
            PostScore,
            Commentators,
        },

        props: {
            post: {
                type: Object,
                required: true,
            },
            contentPreview: Boolean,
            onVideoPage: {
                type: Boolean,
                default: false,
            },
            className: [String, Array, Object],
        },

        computed: {
            titleStats() {
                return {
                    comments: this.$t('question.comment'),
                    views: this.$t('question.view'),
                    clips: this.$t('question.clips'),
                }
            },
        },

        methods: {
            stats() {
                return _pick(this.post, ['views_count', 'clips_count', 'comments_count'])
            },
            goToUser() {
                return toUser(this.post.user.data)
            },
            toPost() {
                return toPost(this.post)
            },
        },
    }
</script>

<style lang="scss">
    @import "../../assets/sass/bootstrap/borders";

    .post-feed-item {
        padding: .5rem;

        &:not(:last-child) {
            border-bottom: 1px solid $base-border-color;
        }

        &.new {
            background-color: rgba(75, 205, 159, .15);
        }

        .content {
            img:not(.emoji) {
                display: block;
                max-width: 100%;
                margin: 1rem auto;
                max-height: 240px;

                &:not(:first-of-type) {
                    display: none;
                }
            }

            p {
                margin-bottom: 0;
            }
        }

        .content.drafted {
            margin-left: 0;
        }

        .video-thumbnail {
            cursor: pointer;
        }

        .commentators {
            .avatar {
                img {
                    width: 1.3rem;
                }
            }
        }
    }

    .theme-dark {
        .post-feed-item {
            background-color: #000;
        }
    }
</style>
