// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="md-help">
        <el-button
            v-tooltip
            :class="btnClass"
            type="text"
            icon="fa fa-question-circle text-muted"
            :title="$t('detailQuestion.comment.commentForm.markdown')"
            tabindex="-1"
            class="py-05"
            @click.prevent="show"
        />

        <markdown-dialog ref="markdownDialog" />
    </div>
</template>

<script>
    import MarkdownDialog from './MarkdownHelpDialog.vue'

    export default {
        components: {
            MarkdownDialog,
        },

        props: {
            className: {
                type: String,
                default: '',
            },
            btnClass: {
                type: String,
            },
        },

        methods: {
            show() {
                this.$refs.markdownDialog.show()
            },
        },
    }
</script>
