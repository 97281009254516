// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<script>
    import _pick from 'lodash/pick'
    import MessageBox from 'element-ui/lib/message-box'
    import { routeToQuestion } from '~/lib/functions'
    import Menu from '~/components/questions/Item/Menu.vue'
    import Tags from '~/components/questions/widgets/Tags.vue'
    import Participants from '../../shared/user/Participants.vue'
    import UserInline from '~/components/shared/user/Inline.vue'
    import { humanizeTime, formatTime } from '~/filters/dates'
    import Score from '~/components/widgets/Score.vue'
    import formatNumber from '~/filters/formatNumber'

    const render = (h, question, actions, statsTitle, titleReply) => {
        const user = question?.user?.data
        const stats = _pick(question, [
            'solved', 'answers_count', 'points', 'rated_value', 'comments_count', 'views_count', 'created_at', 'subscribed_at', 'contents',
        ])
        const title = _pick(statsTitle, ['answers', 'comments', 'views', 'score', 'ok', 'cancel', 'titleDelete', 'contentDelete'])
        const handleDelete = confirmDelete.bind(null, question, actions.destroy, title)

        return (
            <div class="question">
                <div class="question-header">
                    <div class="question-header--info">
                        <UserInline user={user} show-username={false} />
                    </div>
                    <div class="question-header--date">
                        <span title={formatTime(stats.created_at)} v-tooltip>
                            <i class='fa fa-calendar mr-05'></i>{ humanizeTime(stats.created_at) }
                        </span>
                    </div>
                </div>
                <div class="question-body">
                    <div class="question-body--top">
                        <div class="question-body--top---title">
                            <nuxt-link to={routeToQuestion(question)}>
                                <h3>{question.title}</h3>
                            </nuxt-link>
                        </div>
                        <div class="question-body--top---menu">
                            {question.is_own && (
                                <Menu questionHashId={question.hash_id} onClickDelete={handleDelete} className='ml-auto'/>
                            )}
                        </div>
                    </div>
                    <div class="question-body--desc">
                        <span>{question.contents}</span>
                    </div>
                    <div class="question-body--tags">
                        <Tags tags={question.tags.data}/>
                    </div>
                </div>
                <div class="question-footer">
                    <div class="question-footer--icon">
                        <Score score={stats.points} rated={stats.rated_value} title={`${title.score}: ${stats.points}`}/>

                        <span title={`${title.comments}: ${stats.comments_count}`} v-tooltip>
                            <i class='fa fa-comments' aria-hidden='true'></i> {formatNumber(stats.comments_count)}
                        </span>

                        <span title={`${title.views}: ${stats.views_count}`} v-tooltip>
                            <i class='fa fa-eye' aria-hidden='true'></i> {formatNumber(stats.views_count)}
                        </span>
                    </div>
                    {question?.contributors && question.contributors?.data?.length > 0 && (
                        <div class='question-footer--contributors'>
                            {stats.solved ? (
                            <span class='solved' title={`${title.answers}: ${stats.answers_count}`} v-tooltip>
                                <i class="fa fa-check-circle" aria-hidden="true"></i>
                            </span>
                            ) : null}
                            <span class="text-muted mr-05">{formatNumber(stats.answers_count)} {titleReply}</span>
                            <i class='fa fa-reply text-muted mr-05'></i>
                            <Participants users={question.contributors.data} class='contributors-item' />
                        </div>
                    )}
                </div>
            </div>
        )
    }

    function confirmDelete(question, doDelete, title) {
        if (typeof doDelete !== 'function') return

        MessageBox.confirm(title.contentDelete, title.titleDelete, {
            confirmButtonText: title.ok,
            cancelButtonText: title.cancel,
        })
            .then(() => doDelete(question))
    }

    export default {
        functional: true,

        props: {
            questionId: Number,
            feedStore: String,
            statsTitle: Object,
            question: Object,
            titleReply: String,
        },

        inject: ['actions'],

        render: (h, { props, injections }) => render(
            h,
            props.question,
            injections.actions || {},
            props.statsTitle,
            props.titleReply
        ),
    }
</script>

<style lang="scss">
@import "../../../assets/sass/bootstrap/colors";
@import "../../../assets/sass/bootstrap/mixins";

.question {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 16px;
    height: 100%;

    &::before {
        content: "";
        position: absolute;
        bottom: -15px;
        right: 20px;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 15px solid #fff;
    }


    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;

        &--info {
            height: 30px;
            display: flex;
            flex: 1;
            align-items: center;
            background: #227FF3;
            border-top-right-radius: 24px;
            border-bottom-right-radius: 24px;
            padding-left: 16px;
            margin-left: -1px;

            a {
                color: #fff;
                font-size: 14px;
                text-decoration: none;
                font-weight: 700;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
        }

        &--date {
            flex: 1;
            font-size: 10px;
            color: #9b9b9b;
            text-align: right;
            padding-right: 16px;
        }
    }

    &-body {
        flex: 1;
        padding: 12px 16px;

        &--top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 8px;

            &---title {
                flex: 1;
                font-size: 16px;
                text-decoration: none;
                font-weight: 700;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;

                a {
                    color: #000;
                    font-weight: 700;
                    text-decoration: none;

                    h3 {
                        font-weight: 700;
                    }
                }
            }

            &---menu {
                margin-left: 16px;
            }
        }

        &--desc {
            margin-bottom: 8px;
            font-size: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            color: #707070;
        }
    }

    &-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 16px;
        border-top: 1px solid #D9D9D9;

        &--icon {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;
            cursor: pointer;
            color: #9b9b9b;

            .points .carets {
                padding-top: 0.2rem;
            }
        }

        &--contributors {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .solved {
                color: #2c984f;
                margin-right: 4px;
            }
        }
    }
}

.theme-dark {
    .question {
        border: 1px solid #636363;
        background: #282828;
        color: #fff;

        &::before {
            display: none;
        }

        &-body--top---title a {
            color: #fff;
        }

        &-footer {
            border-color: #636363;
        }
    }

    .question-footer--contributors {
        .solved {
            color: #2c984f !important;
        }
    }
}
</style>
