<template>
    <div class="wrapper-post-pin subdomain-phase-2">
        <div v-if="isEdit" class="action">
            <img src="/images/icon-next-slide.svg" @click="nextSlide(post)">
            <img src="/images/icon-prev-slide.svg" @click="prevSlide(post)">
        </div>
        <div
            class="d-flex flex-column post-pin-item h-100"
            :class="{
                'video-subdomain': isVideoPin,
                'justify-content-between': post.is_video || postSmall,
                'overlay-post': isEdit,
            }"
        >
            <div class="post-pin-item--head">
                <div class="author">
                    <div class="author-avatar">
                        <Avatar
                            :object="user"
                            :image="user.avatar"
                            :is-user="true"
                            :level-partner="user.level_partner"
                            :partner-width="20"
                            :partner-hight="20"
                            size="xs"
                        />
                    </div>
                    <div class="author-name">
                        <nuxt-link :to="toUser" class="link">
                            <span class="truncate-1">{{ user.name }}</span>
                        </nuxt-link>
                    </div>
                </div>
                <div class="publish-at">
                    <span
                        v-tooltip
                        :title="post.published_at | format-time"
                        class="mr-05"
                    >
                        {{ post.published_at | humanize-time }}
                    </span>
                </div>
            </div>
            <div class="post-pin-item--body">
                <div class="post-thumbnail">
                    <nuxt-link v-if="!post.is_video" :to="url">
                        <img class="thumbnail" :src="post.thumbnail_url" alt="thumbnail">
                    </nuxt-link>
                    <Video
                        v-else
                        :id="video.uuid"
                        :provider="video.provider"
                        :url="video.url"
                    />
                </div>
                <p class="post-pin--title truncate-3">
                    {{ post.title }}
                </p>
            </div>
            <div v-if="isOwner">
                <div v-if="isEdit" class="remove-pin">
                    <img src="/images/icon-remove-pin.svg" @click="onRemoveSlide(post)">
                </div>
                <div v-else>
                    <img
                        v-if="post.organization_pinned_at || post.profile_pin_position"
                        class="pin-icon pinned"
                        src="/images/pinned-icon.svg"
                        alt="pinned"
                        @click="onPined(post)"
                    >
                    <img
                        v-else
                        class="pin-icon"
                        src="/images/pin-icon.svg"
                        alt="pin"
                        @click="onPined(post)"
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _get from 'lodash/get'
    import { post as toPost, user as toUser } from '~/utils/url'
    import Avatar from '~/components/shared/Avatar/Avatar.vue'
    import Video from '~/components/posts/Video.vue'

    export default {
        components: {
            Avatar,
            Video,
        },
        props: {
            post: {
                type: Object,
                required: true,
            },
            isVideoPin: {
                type: Boolean,
                default: false,
            },
            postSmall: {
                type: Boolean,
                default: false,
            },
            sizeTitle: {
                type: String,
                default: 'small',
            },
            isOwner: {
                type: Boolean,
                default: false,
            },
            orgSubdomain: String,
            onPined: {
                type: Function,
                default: () => {},
            },
            isEdit: {
                type: Boolean,
                default: false,
            },
            nextSlide: {
                type: Function,
                default: () => {},
            },
            prevSlide: {
                type: Function,
                default: () => {},
            },
            onRemoveSlide: {
                type: Function,
                default: () => {},
            },
        },
        computed: {
            video() {
                return this.post.video
            },
            url() {
                return toPost(this.post)
            },
            toUser() {
                return toUser(this.user)
            },
            user() {
                const user = _get(this.post, 'user.data')
                return typeof user === 'object' && !!user
                    ? user
                    : this.$store.getters['entities/users/get'](user || this.post.user_id)
            },
            stats() {
                return [
                    {
                        name: this.$t('question.views').concat(`${this.post.views_count}`),
                        src: '/images/icon-eye.png',
                        value: this.post.views_count,
                    },
                    {
                        name: this.$t('question.votes').concat(`${this.post.points}`),
                        src: '/images/icon-like.png',
                        value: this.post.points,
                    },
                    {
                        name: this.$t('userInline.clips').concat(`${this.post.clips_count}`),
                        src: '/images/icon-bookmark.png',
                        value: this.post.clips_count,
                    },
                    {
                        name: this.$t('question.comments').concat(`${this.post.comments_count}`),
                        src: '/images/icon-cmt.png',
                        value: this.post.comments_count,
                    },
                ]
            },
            tags() {
                return {
                    tags: this.post.tags ? this.post.tags.data : null,
                    promoted: this.post.promoted,
                    trending: this.post.trending,
                    system: this.post.system_tag,
                }
            },
        },

        methods: {
            convertImgToText(content) {
                return content.replace(/!\[\]\(.+?\)/g, '[IMG]')
            },
        },
    }
</script>

<style scoped lang="scss">
@import "~/assets/sass/bootstrap/borders";
@import "~/assets/sass/bootstrap/colors";
@import "~/assets/sass/components/posts-view.scss";

.post-pin-item {
    position: relative;
    padding: 24px;
    padding-top: 10px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 2.24px 16.238px 0px rgba(0, 178, 255, 0.25);

    &--head {
        display: flex;
        align-items: center;
        margin-bottom: 6px;

        .author {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 30px;

            &-avatar {
                margin-right: 8px;
            }

            &-name {
                font-size: 14px;
                font-weight: 700;
                color: #000;
                line-height: normal;

                a.link {
                    display: grid;
                }
            }
        }

        .publish-at {
            flex: 0;
            white-space: nowrap;
            font-size: 10px;
            color: $text-secondary;
            line-height: normal;
        }
    }

    &--body {
        .post-pin--title {
            height: 3em;
            margin-top: 6px;
            margin-bottom: 0;
            color: $post-title;
            font-size: 26px;
            font-weight: 700;
            line-height: 27px;
        }

        .post-thumbnail img {
            width: 100%;
            aspect-ratio: 16 / 9;
        }
    }

    .pin-icon {
        position: absolute;
        top: 2px;
        right: 2px;
        cursor: pointer;
    }


    .title-all-posts span {
        transform: rotate(45deg);
        display: inline-block;
        color: red;
    }

    .first-pin img {
        width: 100% !important;
        aspect-ratio: 16 / 9;
    }

    .contents_short {
        .md-contents {
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
        }
    }

    .author-info {
        .min-w-180 {
            min-width: 180px;
        }

        .username-user {
            border-radius: 20px;
            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
            color: black;
            background-color: white;

            .author-name {
                max-width: 20rem;
            }
        }
    }

    .tags-list {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;

        .tag {
            margin-bottom: 8px;
        }
    }

    .footer-post-item {
        max-width: 200px;
    }

    .avt-author-post-pin {
        margin-top: -1.2rem;
    }

    .avt-author-video {
        margin-top: -1.5rem;
    }

    .username-size-small {
        font-size: 13px;
        max-width: 10rem !important;
    }

    .username-size-medium {
        font-size: 20px;
    }

    .post-meta-size-small {
        font-size: 10px;
    }

    .contents_short-org {
        font-size: 16px;

        .md-contents {
            font-family: 'Roboto';
        }
    }

    .post-pin-content-short .md-contents {
        font-family: 'Roboto';
        font-size: 16px;
        color: #707070;
        line-height: 26px;
    }

    .post-meta-size-medium {
        margin-top: -1rem;
    }

    .description-posts-pin {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
    }
}

.wrapper-post-pin {
    position: relative;
    margin-right: 24px;

    .action {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        display: flex;
        flex-direction: column;

        img {
            cursor: pointer;
        }

        &:hover {
            ~ .overlay-post {
                filter: blur(0px);
                -webkit-filter: blur(0px);
            }
        }
    }

    .remove-pin {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;

        img {
            cursor: pointer;
        }

        &:hover {
            ~ .overlay-post {
                filter: blur(0px);
                -webkit-filter: blur(0px);
            }
        }
    }

    .overlay-post {
        filter: blur(1px);
        -webkit-filter: blur(1px);

        &:hover {
            filter: blur(0px);
            -webkit-filter: blur(0px);
        }
    }
}

.video-subdomain {

    .contents_short,
    .subdomain-post-title--inline {
        color: white;
    }

    .author-info {
        .username-user {
            border-radius: 20px;
            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
            color: black;
            background-color: white;
            padding: 0 2px;
        }
    }

    .footer-post-item {
        max-width: 250px;
    }

    .footer-post-item {
        max-width: 400px;
    }
}

@media screen and (max-width: 768px) {
    .post-pin-item {
        margin-bottom: 1rem;
    }
}

.theme-dark {
    .subdomain-phase-2 {
        .post-pin-item {
            background-color: #fff;
        }
    }
}
</style>
