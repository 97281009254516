// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div :class="className" class="relative">
        <el-button
            plain
            type="primary"
            title="Embed Profile Stats"
            class="icon-share full-width"
            @click.prevent="setCurrentSharingId(sharingId)"
        >
            <i class="fa fa-code" />
            {{ $t('profile.embed.title') }}
        </el-button>

        <div
            v-show="openShareTip"
            v-on-clickaway="handleClickAway"
            class="share-tip"
        >
            <label class="tip-label">{{ label }}</label>

            <div class="d-flex share-input-group">
                <input
                    ref="text"
                    :data-sharing-id="sharingId"
                    :value="text"
                    type="text"
                    class="d-block share-link"
                    readonly="readonly"
                    @click="$event.target.select()"
                >
                <span
                    ref="clipboard"
                    :data-clipboard-text="text"
                    :data-clipboard-target="`[data-sharing-id='${sharingId}']`"
                    class="share-link-addon viblo-tooltip"
                    area-label="Copied!"
                    area-tooltip-hidden="true"
                    :title="$t('copyURL')"
                    @click="onCopied"
                >
                    <i class="fa fa-copy" />
                </span>
            </div>

            <div class="d-flex" style="justify-content: flex-end">
                <span class="close-share-tip" @click="setCurrentSharingId(null)">
                    {{ $t('profile.embed.close') }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import Clipboard from 'clipboard'
    import { mixin as clickaway } from '@tranxuanthang/vue-clickaway'

    const hasClassOn = (element, className) => {
        if (element && typeof element.className === 'string') {
            return element.className.indexOf(className) !== -1
        }

        return false
    }

    export default {
        mixins: [clickaway],

        props: {
            text: {
                type: String,
                default: '',
            },

            label: {
                type: String,
                default: 'Embed profile stats',
            },

            sharingId: {
                type: String,
                required: true,
            },

            sharingTitle: {
                type: String,
                default: '',
            },

            className: [String, Object],
        },

        data() {
            return {
                $clipboard: null,
            }
        },

        computed: {
            ...mapState('promoted', ['currentSharingId']),

            openShareTip() {
                return this.currentSharingId === this.sharingId
            },
        },

        mounted() {
            if (this.$refs.clipboard) {
                this.$clipboard = new Clipboard(this.$refs.clipboard)
            }
        },

        destroyed() {
            if (this.$clipboard) {
                this.$clipboard.destroy()
                this.$clipboard = null
            }
        },

        methods: {
            onCopied() {
                this.$refs.clipboard.setAttribute('area-tooltip-hidden', false)
                // Set timeout to hide tooltip:
                setTimeout(() => {
                    if (this.$refs.clipboard) {
                        this.$refs.clipboard.setAttribute('area-tooltip-hidden', true)
                        this.$refs.text.select()
                    }
                }, 1000)
            },

            setCurrentSharingId(value) {
                this.$store.commit('promoted/SET_SHARING_ID', value)
            },

            handleClickAway(event) {
                if (!event.composedPath()) {
                    return
                }

                const clickOutside = event.composedPath().findIndex(element => hasClassOn(element, 'share-tip')) === -1
                const clickOnShareIcon = hasClassOn(event.target, 'icon-share') || hasClassOn(event.target.parentElement, 'icon-share')

                if (clickOutside && !clickOnShareIcon) {
                    this.setCurrentSharingId(null)
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/sass/bootstrap/mixins";
    @import "../../assets/sass/bootstrap/variables";

    .share-tip {
        display: flex;
        width: 20rem;
        height: auto;
        flex-direction: column;
        position: absolute;
        background: rgba(0,0,0,0.8);
        color: white;
        padding: 0.625rem;
        z-index: 1000;
        .tip-label {
            font-size: 0.725rem;
            color: inherit;
        }
        .share-input-group {
            font-size: 0.9rem;
            background: $blue;
            .share-link, .share-link-addon { padding: 0.2rem 0.5rem; }
            .share-link { width: 100%; }
            .share-link-addon {
                cursor: pointer;
                @include transition(background 0.3s ease-in-out);
                &:hover { background: darken($blue, 15); }
            }
        }
        .close-share-tip {
            font-size: 0.8rem;
            color: $blue;
            align-self: flex-end;
            cursor: pointer;
        }
    }
</style>
