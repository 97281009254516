// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <suggestion-container
        ref="container"
        :show="show"
        :items="items"
        @selected="emitSelected"
    >
        <component :is="itemComponent" slot-scope="suggestion" :suggestion="suggestion.item" />
    </suggestion-container>
</template>

<script>
    import _includes from 'lodash/includes'
    import emoji from 'markdown-it-emoji/lib/data/full.json'
    import {
        getSuggestionType,
        getPendingText,
        createSuggestionProvider,
    } from '~/utils/typingSuggestions'

    import EmojiSuggestion from './EmojiSuggestion.vue'
    import MentionSuggestion from './MentionSuggestion.vue'
    import SuggestionContainer from './SuggestionContainer.vue'

    export default {
        components: {
            SuggestionContainer,
        },

        props: {
            typingText: String,
        },

        inject: {
            localMentions: {
                default: () => ([]),
            },
        },

        data: () => ({
            show: false,
        }),

        computed: {
            suggestionProvider() {
                const mentions = typeof this.localMentions === 'function'
                    ? this.localMentions()
                    : this.localMentions

                return createSuggestionProvider({ emoji, mentions })
            },

            type() {
                return getSuggestionType(this.typingText)
            },

            pendingComplete() {
                return getPendingText(this.type, this.typingText)
            },

            items() {
                return this.suggestionProvider(this.type, this.pendingComplete)
            },

            itemComponent() {
                return ({
                    emoji: EmojiSuggestion,
                    mention: MentionSuggestion,
                })[this.type]
            },
        },

        watch: {
            pendingComplete(to) {
                this.$emit('pending-complete', to)
            },

            items(to) {
                this.show = to.length > 0
            },

            show(oldValue) {
                this.$emit('add-emoji-suggestion', { status: oldValue })
            },
        },

        methods: {
            emitSelected(value) {
                this.$emit('select', {
                    type: this.type,
                    suggestion: value,
                    pending: this.pendingComplete,
                })
            },

            handleKeyEvents(event) {
                if (!this.show) {
                    return
                }

                if (_includes([38, 40, 27, 13], event.keyCode)) {
                    event.preventDefault()
                }

                switch (event.keyCode) {
                    case 38:
                        this.$refs.container.moveUp()
                        break
                    case 40:
                        this.$refs.container.moveDown()
                        break
                    case 27:
                        this.show = false
                        break
                    case 13:
                        this.$refs.container.selectCurrent()
                        break
                    default:
                }
            },
        },
    }
</script>
