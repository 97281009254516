// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import Dropzone from 'dropzone'
import cookies from 'axios/lib/helpers/cookies'

Dropzone.autoDiscover = false

/**
 * @param {string|Element} el
 * @param {Dropzone.DropzoneOptions} options
 * @returns {Dropzone}
 */
export const createDropzone = (el, options) => new Dropzone(el, {
    url: '/api/uploads',
    acceptedFiles: 'image/jpeg,image/png,image/gif',
    maxFiles: 5,
    maxFilesize: 6, // MB
    headers: {
        'X-CSRF-TOKEN': cookies.read('XSRF-TOKEN'),
    },
    ...options,
})
