// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="sidebar__organization mb-1">
        <div class="sidebar__organization__row">
            <Avatar
                :image="organization.avatar"
                :object="organization"
                :level-partner="organization.level_partner"
                :partner-width="65"
                :partner-hight="65"
                image-size="avatar"
                :size="organization.level_partner ? 'xl-mini' : 'xl'"
            />

            <div class="d-flex flex-column justify-content-center name-and-stats-org">
                <nuxt-link :to="organizationPage" class="word-break name-overflow">
                    {{ organization.name }}
                </nuxt-link>
                <div class="my-05">
                    <nuxt-link
                        v-if="organization.isOwner"
                        :to="`/o/${organization.slug}/info/edit`"
                        class="btn btn-follow"
                    >
                        {{ $t('detailOrgaization.edit') }}
                    </nuxt-link>
                    <subscribe
                        v-else
                        ref="subscribe"
                        :send="handleSubscribe"
                        :subscribable="{ type: 'Organization', id: organization.slug }"
                    >
                        <button
                            :class="{ 'following': organization.following }"
                            class="btn btn-follow"
                            @click="$refs.subscribe.handle(!organization.following)"
                        >
                            <span
                                v-if="organization.following"
                            >
                                <i class="fa fa-check" />
                                {{ $t('detailOrgaization.following') }}
                            </span>
                            <span v-else><i class="fa fa-plus" /> {{ $t('detailOrgaization.follow') }} </span>
                        </button>
                    </subscribe>
                </div>
            </div>
        </div>
        <div class="sidebar-user-action">
            <div class="stats text-nowrap d-flex stats-top-author justify-content-between">
                <span
                    v-for="(stat, index) in organizationStats(organization)"
                    :key="index"
                    v-tooltip
                    :title="stat.name"
                    class="stats-item-organization text-muted w-25"
                >
                    <i :class="stat.icon" class="stats-item__icon" aria-hidden="true" />
                    {{ stat.value | formatNumber }}
                </span>

                <slot name="append" />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import Subscribe from '~/components/widgets/Subscribe.vue'
    import { routeToOrganization } from '~/lib/functions'
    import Avatar from '~/components/shared/Avatar/Avatar.vue'

    export default {
        components: {
            Avatar,
            Subscribe,
        },

        props: {
            organization: {
                type: Object,
                required: true,
            },
        },

        computed: {
            organizationPage() {
                return routeToOrganization(this.organization)
            },
        },

        methods: {
            ...mapActions('entities/organizations', ['subscribe']),

            organizationStats(organization) {
                return [
                    {
                        name: this.$t('profile.organizations.posts').concat(`${organization.posts_count}`),
                        icon: 'fa fa-pencil',
                        value: organization.posts_count,
                    },
                    {
                        name: this.$t('profile.organizations.members').concat(`${organization.members_count}`),
                        icon: 'fa fa-users',
                        value: organization.members_count,
                    },
                    {
                        name: this.$t('profile.organizations.follow').concat(`${organization.followers_count}`),
                        icon: 'fa fa-user-plus',
                        value: organization.followers_count,
                    },
                    {
                        name: this.$t('profile.organizations.view').concat(`${organization.postviews_count}`),
                        icon: 'fa fa-eye',
                        value: organization.postviews_count,
                    },
                ]
            },

            handleSubscribe(value) {
                this.$emit('updateFollow', { value, organization: this.organization })
                return this.subscribe({ organization: this.organization, value })
            },
        },
    }
</script>

<style lang="scss">
    @import "~assets/sass/bootstrap/colors";
    @import "~assets/sass/bootstrap/borders";

    .sidebar__organization {
        &__row {
            display: flex;
            margin-bottom: 10px;

            & > * {
                &:nth-child(1) {
                    min-width: 80px;
                    max-width: 80px;
                    text-align: center;
                }

                &:nth-child(2) {
                    margin-left: 10px;
                    flex-grow: 1;
                    .stats-item-organization {
                        cursor: default;
                        margin-right: 2px;
                    }
                }
            }
        }
    }

    .avt-size-xl-mini {
        top: 5px;
        left: 13.5px;
    }
    .partner-index {
        z-index: 1;
    }

    .margin-right {
        margin-right: 16px;
    }

    .name-overflow {
        max-width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .name-and-stats-org {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
