// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="pin-post" :class="{'hidden-post-pin': posts.length === 0}">
        <div class="d-flex justify-content-between">
            <p class="pin-post--heading">
                <span class="pin-icon">
                    <img src="/images/icon-pin-lg.svg">
                </span>
                {{ $t('subdomain.PostPin') }}
            </p>
            <el-button
                v-if="isOwner"
                :title="$t('pinPostOrg.titlePinPost')"
                size="small"
                class="btn-set-pinned-post mr-1"
                @click="$refs.listPosts.open()"
            >
                <img src="/images/icon-edit-pin.svg">
            </el-button>
        </div>
        <div class="pin-post--content">
            <VueSlickCarousel
                v-if="posts.length > 0"
                ref="carousel"
                v-bind="settings"
            >
                <div v-for="(post) in posts" :key="post.id">
                    <PostPinItem
                        :post="post"
                        :post-small="true"
                        :size-title="'mini'"
                        :on-pined="onPined"
                        :is-owner="isOwner"
                        class="item-post-pin"
                    />
                </div>
                <div v-if="posts.length < 3 && windowWidth > 680" class="banner-add-post-pin d-flex mr-2">
                    <DropDown
                        v-if="isOwner"
                        class="mr-2"
                        :posts="listPost"
                        :post-pined="postPined"
                        :handle-pined-post="handlePinedPost"
                        :on-get-post="getPost"
                    />
                    <img src="~assets/images/banner-post-pin.png">
                </div>
            </VueSlickCarousel>
            <div v-else class="banner-add-post-pin no-post-pin d-flex">
                <DropDown
                    v-if="isOwner"
                    class="mr-2"
                    :posts="listPost"
                    :post-pined="postPined"
                    :handle-pined-post="handlePinedPost"
                    :on-get-post="getPost"
                />
                <img src="~assets/images/banner-post-pin.png" class="banner-post-pin-img">
            </div>
        </div>

        <ListPostsDialog
            ref="listPosts"
            :posts-pin="posts"
            :is-owner="isOwner"
            :on-pined="onPined"
            :is-org-subdomain="isOrgSubdomain"
            :on-save-pined-post="onSavePinedPost"
        />
    </div>
</template>

<script>
    import { mixin as clickaway } from '@tranxuanthang/vue-clickaway'
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
    import _find from 'lodash/find'

    import { fetchPostOrganization } from '~/api/organizations'
    import { fetchUserPosts } from '~/api/users'
    import PostPinItem from './PostPinItem.vue'
    import ListPostsDialog from './widgets/ListPostsDialog.vue'
    import DropDown from './widgets/DropDown.vue'

    export default {
        components: {
            VueSlickCarousel,
            PostPinItem,
            ListPostsDialog,
            DropDown,
        },

        mixins: [clickaway],

        props: {
            posts: {
                type: Array,
                default: () => [],
            },
            subdomain: String,
            isOwner: {
                type: Boolean,
                default: false,
            },
            isOrgSubdomain: {
                type: Boolean,
                default: true,
            },
            onPined: {
                type: Function,
                default: () => {},
            },
            onSavePinedPost: {
                type: Function,
                default: () => {},
            },
        },

        data() {
            return {
                listPost: [],
                pagination: null,
                settings: {
                    dots: false,
                    speed: 500,
                    slidesToShow: 1.1,
                    slidesToScroll: 1,
                    variableWidth: true,
                    infinite: false,
                },
                windowWidth: 0,
                loading: false,
                visible: false,
            }
        },

        mounted() {
            if (process.client) {
                this.windowWidth = window.innerWidth
                window.addEventListener('resize', this.handleResize)
            }
            this.getPost()
        },

        beforeDestroy() {
            if (process.client) {
                window.removeEventListener('resize', this.handleResize)
            }
        },

        methods: {
            handleResize() {
                this.windowWidth = window.innerWidth
            },
            handlePinedPost(post) {
                this.onPined(post)
            },

            postPined(postId) {
                return _find(this.posts, post => post && post.id === postId) !== undefined
            },

            async getPost($state) {
                const page = this.pagination ? { page: this.pagination.current_page + 1 } : { page: 1 }

                const posts = this.isOrgSubdomain
                    ? await fetchPostOrganization(this.subdomain, page).then(({ data }) => data)
                    : await fetchUserPosts(this.subdomain, 'posts', page).then(({ data: res }) => res)

                this.listPost = [...this.listPost, ...posts.data]
                this.pagination = posts.meta.pagination

                if ($state) {
                    if (this.pagination.current_page < this.pagination.total_pages) {
                        $state.loaded()
                    } else {
                        $state.complete()
                    }
                }
            },
        },
    }
</script>

<style lang="scss">
@import "../../../assets/sass/bootstrap/colors";

.pin-post {
    position: relative;
    background: $pin-bg;
    padding-top: 10px;
    padding-bottom: 30px;
    padding-left: 10px;
    padding-right: 0px;
    border-radius: 6px;

    .item-post-pin {
        width: 464px;

        .post-thumbnail img {
            min-height: 234px;
        }
    }

    .banner-add-post-pin.no-post-pin {
        @media screen and (max-width: 768px) {
            justify-content: center;
        }
    }

    .banner-add-post-pin {
        height: 378px;

        .banner-post-pin-img {
            max-width: calc(100% - 180px);

            @media screen and (max-width: 768px) {
                display: none;
            }
        }
    }

    &::after {
        content: '';
        position: absolute;
        width: 75px;
        top: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(270deg, #002D66 0%, rgba(0, 45, 102, 0.00) 100%);
    }

    &--heading {
        margin-bottom: 42px;
        color: #fff;
        font-size: 30px;
        font-weight: 700;
        line-height: normal;

        @media screen and (max-width: 768px) {
            font-size: 24px;
        }

        @media screen and (max-width: 500px) {
            font-size: 20px;
        }

        .pin-icon {
            display: inline-block;
            color: red;
        }
    }

    .btn-set-pinned-post {
        z-index: 1;
        border: none;
        padding: 0;
        height: 100%;
        background: none;
    }

    &--content {
        padding-left: 30px;
    }

    .slick-slider {
        .slick-prev::before {
            font-family: FontAwesome, serif;
            content: "\f053";
            font-size: 22px;
        }

        .slick-next {
            z-index: 1;
            right: 4px;
        }

        .slick-prev {
            left: -35px
        }

        .slick-next::before {
            font-family: FontAwesome, serif;
            content: "\f054";
            font-size: 22px;
        }
    }

    .slick-track {
        display: flex !important;
      }

    .slick-track .slick-slide {
        display: flex;
        height: auto;
    }

    @media screen and (min-width: 769px) {
        .slick-tree-item {
            .slick-list {
                //padding: 0 0 0 20% !important;
            }
        }
    }

    @media screen and (max-width: 1280px) {
        &::after {
            width: 170px;
        }
    }

    @media screen and (max-width: 768px) {
        .slick-list {
            //padding: 0 0 0 34% !important;
        }

        .slick-one-item {
            .slick-list {
                //padding: 0 !important;
            }
        }

        &::after {
            width: 100px;
        }
    }

    @media screen and (max-width: 425px) {
        .slick-list {
            //padding: 0 0 0 9% !important;
        }

        &::after {
            width: 55px;
        }
    }
}

</style>
