// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="post-feed-org py-2 px-1">
        <FollowingFilter is-subdomain />

        <user-grid :actions="{ follow }" store-path="profile/following">
            <template slot="empty">
                {{ $t('postBody.postContent.postAuthor.notFollowing') }}
            </template>
        </user-grid>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import UserGrid from '~/components/subdomains/users/UserGrid.vue'
    import FollowingFilter from '~/components/shared/user/FollowingFilter.vue'
    import { handlePageError, getContentSubDomain } from '~/utils/pages'

    export default {
        name: 'UserFollowing',

        components: {
            FollowingFilter,
            UserGrid,
        },

        scrollToTop: true,

        fetch: ({
            store, params, query, error, req,
        }) => {
            const host = process.client ? window.location.host : req.headers.host

            return store.dispatch('profile/following/fetch', {
                username: store.state.config.vars.is_special_profile ? getContentSubDomain(host) : params.user,
                params: {
                    limit: store.state.settings.perPage - (store.state.settings.perPage % 3),
                    page: query.page,
                },
            }).catch(handlePageError(error))
        },

        watchQuery: ['type', 'page'],

        methods: {
            ...mapActions('profile/following', ['follow']),
        },
    }
</script>
