// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
import cookies from 'axios/lib/helpers/cookies'

export default {
    methods: {
        handleSwitchTheme(theme) {
            this.switchMode(theme)
            const expires = Date.now() + 30 * 24 * 60 * 60 * 1000
            cookies.write('modeTheme', theme, expires, '/')
            this.$message(this.$t('descDarkMode'))
        },
    },
}
