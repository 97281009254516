// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div>
        <div class="d-flex search-sort-bar align-items-baseline justify-content-between mb-1">
            <el-dropdown size="medium" trigger="click">
                <span class="cursor-pointer">
                    {{ $t('myClips.type') }} <b>{{ $t(activeType.label) }}</b>
                </span>

                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                        v-for="(entityType, key) in entityTypes"
                        :key="key"
                        :class="{ 'el-dropdown-menu__item--active': entityType == activeType }"
                        @click.native="sort(entityType)"
                    >
                        {{ $t(entityType.label) }}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>

            <el-dropdown size="medium" trigger="click">
                <span class="cursor-pointer">
                    {{ $t('myClips.sort') }} <b>{{ $t(activeSort.label) }}</b>
                </span>

                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                        v-for="(type, key) in sortTypes"
                        :key="key"
                        :class="{ 'el-dropdown-menu__item--active': type.field == activeSort.field }"
                        @click.native="sort(null, type)"
                    >
                        {{ $t(type.label) }}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>

        <list-mix :data="data" :type="type" :pagination="pagination" />
    </div>
</template>

<script>
    import _filter from 'lodash/filter'
    import _upperFirst from 'lodash/upperFirst'
    import { fetchUserPosts } from '~/api/users'
    import ListMix from '~/components/ListMix.vue'
    import { handlePageError, getContentSubDomain } from '~/utils/pages'

    const entityTypes = [
        {
            label: 'myClips.posts',
            field: 'posts',
        },
        {
            label: 'myClips.series',
            field: 'series',
        },
        {
            label: 'myClips.questions',
            field: 'questions',
        },
    ]

    const sortTypes = [
        {
            label: 'myClips.published',
            field: 'published_at',
        },
        {
            label: 'myClips.clipAt',
            field: 'clipped_at',
        },
    ]

    export default {
        components: { ListMix },

        data() {
            return {
                sortTypes,
                entityTypes,
            }
        },

        computed: {
            activeType() {
                const active = this.$route.params.clip || 'posts'
                return _filter(entityTypes, type => type.field === active)[0]
            },
            activeSort() {
                const active = this.$route.query.sort || 'published_at'
                return _filter(sortTypes, type => type.field === active)[0]
            },
        },

        async asyncData({
            query, store, route, params, error, req,
        }) {
            try {
                const type = route.params.clip
                const sort = route.query.sort || 'published_at'
                const page = query.page || 1
                const host = process.client ? window.location.host : req.headers.host
                const user = store.state.config.vars.is_special_profile ? getContentSubDomain(host) : params.user
                const fetchedData = await fetchUserPosts(user, `clipped-${type}`, {
                    sort,
                    page,
                    limit: store.state.settings.perPage,
                }).then(({ data }) => data)

                return {
                    pagination: fetchedData.meta.pagination,
                    data: fetchedData.data,
                    type,
                }
            } catch (e) {
                return handlePageError(error, e)
            }
        },

        watchQuery: true,

        methods: {
            _filter,
            _upperFirst,
            sort(entityType, sortType) {
                entityType = entityType || this.activeType
                sortType = sortType || this.activeSort

                if (this.$store.state.config.vars.is_special_profile) {
                    this.$router.push(`/clips/${entityType.field}?sort=${sortType.field}`)
                } else {
                    this.$router.push(`/u/${this.$route.params.user}/clips/${entityType.field}?sort=${sortType.field}`)
                }
            },
        },
    }
</script>
