// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div :class="className" class="relative">
        <!--Share button-->
        <a
            v-tooltip
            :href="text"
            :title="label"
            class="icon-share link plain text-muted"
            @click.prevent="setCurrentSharingId(sharingId)"
        >
            <span>{{ $t('detailQuestion.comment.socialShare.button') }}</span>
        </a>

        <!--Share tip popup-->
        <div
            v-show="openShareTip"
            v-on-clickaway="handleClickAway"
            class="share-tip"
        >
            <label class="tip-label">{{ label }}</label>

            <!--Input copy text-->
            <div class="d-flex share-input-group">
                <input
                    :data-sharing-id="sharingId"
                    :value="text"
                    type="text"
                    class="d-block share-link"
                    readonly="readonly"
                    @click="$event.target.select()"
                >
                <span
                    ref="clipboard"
                    :data-clipboard-text="text"
                    :data-clipboard-target="`[data-sharing-id='${sharingId}']`"
                    class="share-link-addon viblo-tooltip"
                    area-label="Copied!"
                    area-tooltip-hidden="true"
                    :title="$t('copyURL')"
                    @click="onCopied"
                >
                    <i class="fa fa-copy" />
                </span>
            </div>

            <!--Social sharing icons-->
            <social-sharing
                :title="sharingTitle"
                :url="text"
                :tags="[]"
                class="social-sharing-icons"
            >
                <template slot-scope="socials">
                    <a
                        rel="noopener"
                        class="link social-btn-circle facebook-btn"
                        @click="socials.facebook"
                    >
                        <i class="fa fa-facebook" aria-hidden="true" />
                    </a>
                    <a
                        rel="noopener"
                        class="link social-btn-circle twitter-btn"
                        @click="socials.twitter"
                    >
                        <i class="fa fa-twitter" aria-hidden="true" />
                    </a>
                    <a
                        rel="noopener"
                        class="link social-btn-circle google-btn"
                        @click="socials.google"
                    >
                        <i class="fa fa-google-plus" aria-hidden="true" />
                    </a>
                </template>
            </social-sharing>

            <div class="d-flex" style="justify-content: flex-end">
                <span
                    class="close-share-tip"
                    @click="setCurrentSharingId(null)"
                >
                    {{ $t('detailQuestion.comment.socialShare.close') }}
                </span>
            </div>
        </div>
        <!--End popup-->
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import Clipboard from 'clipboard'
    import { mixin as clickaway } from '@tranxuanthang/vue-clickaway'
    import SocialSharing from '~/components/posts/view/Social.vue'

    const hasClassOn = (element, className) => {
        if (element && typeof element.className === 'string') {
            return element.className.indexOf(className) !== -1
        }

        return false
    }

    export default {
        components: {
            SocialSharing,
        },
        mixins: [clickaway],

        props: {
            text: {
                type: String,
                default: '',
            },
            label: {
                type: String,
                default: 'Share a link to this answer',
            },
            sharingId: {
                type: String,
                required: true,
            },
            sharingTitle: {
                type: String,
                default: '',
            },
            className: [String, Object],
        },
        data() {
            return {
                $clipboard: null,
            }
        },
        computed: {
            ...mapState('promoted', ['currentSharingId']),
            openShareTip() {
                return this.currentSharingId === this.sharingId
            },
        },
        mounted() {
            if (this.$refs.clipboard) {
                this.$clipboard = new Clipboard(this.$refs.clipboard)
            }
        },
        destroyed() {
            if (this.$clipboard) {
                this.$clipboard.destroy()
                this.$clipboard = null
            }
        },
        methods: {
            onCopied() {
                this.$refs.clipboard.setAttribute('area-tooltip-hidden', false)
                // Set timeout to hide tooltip:
                setTimeout(() => {
                    if (this.$refs.clipboard) {
                        this.$refs.clipboard.setAttribute('area-tooltip-hidden', true)
                    }
                }, 1000)
            },
            setCurrentSharingId(value) {
                this.$store.commit('promoted/SET_SHARING_ID', value)
            },
            handleClickAway(event) {
                if (!event.composedPath()) {
                    return
                }

                const clickOutside = event.composedPath().findIndex(element => hasClassOn(element, 'share-tip')) === -1
                const clickOnShareIcon = hasClassOn(event.target, 'icon-share') || hasClassOn(event.target.parentElement, 'icon-share')

                if (clickOutside && !clickOnShareIcon) {
                    this.setCurrentSharingId(null)
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/sass/bootstrap/mixins";
    @import "../../assets/sass/bootstrap/variables";

    .share-tip {
        @include border-radius(0.25rem);
        display: flex;
        width: 20rem;
        height: auto;
        flex-direction: column;
        position: absolute;
        background: rgba(0,0,0,0.8);
        color: white;
        padding: 0.625rem;
        z-index: 1000;
        .tip-label {
            font-size: 0.725rem;
            color: inherit;
        }
        .share-input-group {
            font-size: 0.9rem;
            background: $blue;
            .share-link, .share-link-addon { padding: 0.2rem 0.5rem; }
            .share-link { width: 100%; }
            .share-link-addon {
                cursor: pointer;
                @include transition(background 0.3s ease-in-out);
                &:hover { background: darken($blue, 15); }
            }
        }
        .close-share-tip {
            font-size: 0.8rem;
            color: $blue;
            align-self: flex-end;
            cursor: pointer;
        }
    }

    .social-sharing-icons {
        display: flex;
        flex-direction: row;
        margin-top: 0.625rem;
        & > a {
            margin-right: 0.625rem;
            width: 2rem;
            height: 2rem;
        }
    }

    @media only screen and (max-width: 500px) {
        .share-tip {
            left: 0;
        }
    }
</style>
