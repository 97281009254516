// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="pt-2 pb-2">
        <i
            v-if="series.length <= 0 && loading"
            class="fa fa-circle-o-notch fa-spin sb-icon loading"
            aria-hidden="true"
        />
        <empty v-if="series.length <= 0 && !loading">
            <p> {{ $t('empty') }} </p>
        </empty>
        <div v-else>
            <SerieItem
                v-for="(list, index) in series"
                :key="index"
                :post="list"
                :show-organization="false"
                layout="horizontal"
                class="serie-item"
                is-serie
            />
        </div>
        <client-only>
            <InfiniteLoading :distance="50" spinner="spiral" @infinite="load">
                <span slot="no-results" />
                <span slot="no-more" />
            </InfiniteLoading>
        </client-only>
    </div>
</template>

<script>
    import InfiniteLoading from 'vue-infinite-loading'
    import { getUserSeries } from 'viblo-sdk/api/users'
    import SerieItem from '~/components/subdomains-theme-2/posts/PostFeedItem.vue'
    import { normalizeSeriesList } from '~/components/entities/normalize'
    import { handlePageError, getContentSubDomain } from '~/utils/pages'

    export default {

        components: {
            SerieItem,
            InfiniteLoading,
        },

        data() {
            return {
                series: [],
                pagination: null,
                loading: false,
            }
        },

        // eslint-disable-next-line consistent-return
        async fetch() {
            const req = this.$nuxt.context.ssrContext?.req
            const store = this.$nuxt.context.store
            const params = this.$nuxt.context.route.params
            const error = this.$nuxt.context.error
            const page = this.pagination ? { page: this.pagination.current_page + 1 } : { page: 1 }

            try {
                const host = process.client ? window.location.host : req.headers.host
                const user = store.state.config.vars.is_special_profile ? getContentSubDomain(host) : params.user
                this.loading = true
                const series = await getUserSeries(user, {
                    ...page,
                    limit: store.state.settings.perPage,
                })

                const normalized = normalizeSeriesList(series.data)
                store.commit('entities/users/put', normalized.entities.users)

                this.series = [...this.series, ...series.data]
                this.pagination = series.meta.pagination
                this.loading = false
            } catch (e) {
                this.loading = false
                return handlePageError(error, e)
            }
        },

        watchQuery: ['page'],

        methods: {
            async load($state) {
                if (this.pagination?.current_page < this.pagination?.total_pages) {
                    await this.$fetch()
                    $state.loaded()
                } else {
                    $state.complete()
                }
            },
        },
    }
</script>

<style lang="scss">
    .loading {
        position: unset;
        width: 100%;
        text-align: center;
        font-size: 24px;
    }

    .serie-item {
        margin-bottom: 24px;
    }
</style>
