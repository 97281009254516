// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template functional>
    <div class="d-flex justify-content-between w-100 mt-05">
        <span
            v-for="(stat, index) in props.stats"
            :key="index"
            v-tooltip
            :title="stat.name"
            class="d-flex align-items-center stats-item text-muted"
        >
            <img
                v-if="index !== 1"
                :src="stat.src"
                class="stats-item__icon"
            >
            <div v-else class="carets stats-item__icon d-flex flex-column">
                <i aria-hidden="true" class="fa fa-caret-up" />
                <i aria-hidden="true" class="fa fa-caret-down" />
            </div>
            {{ stat.value | formatNumber }}
        </span>

        <slot name="append" />
    </div>
</template>

<script>
    export default {
        props: {
            stats: {
                type: Array,
                required: true,
            },
            isSuggestion: {
                type: Boolean,
                default: false,
            },
        },
    }
</script>

<style lang="scss">
    .stats-item {
        cursor: default;
        max-height: 1.25rem;

        &__icon {
            aspect-ratio: unset !important;
            margin-right: 2px;

            .fa-caret-down {
                margin-top: -6px;
            }
        }
    }

    .stats-item-profile {
        font-weight: 500;
        margin-right: 12px;

        &:last-child {
            margin-right: 0;
        }
    }
</style>

<style lang="scss" scoped>
.theme-dark {
    .stats-item.text-muted, .stats-item__icon {
        color: #e4e6eb !important;
    }
}
</style>
