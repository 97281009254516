// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="user-card">
        <div class="user-card__main">
            <Avatar
                :image="user.avatar"
                :object="user"
                :is-user="true"
                :level-partner="user.level_partner"
                :partner-width="95"
                :partner-hight="95"
                size="xxl"
                class="user-card__main__hero"
            />

            <div class="user-card__main__body">
                <div class="user-card__main__body__username">
                    <div class="d-flex profile-name">
                        <h1
                            :class="{'is-banned': user.banned_at}"
                            :title="!user.banned_at ? user.name : 'This user was banned.'"
                            class="user-name d-inline text-break"
                        >
                            {{ user.name }}
                        </h1>
                    </div>
                    <h4 class="text-muted">
                        @{{ user.username }}
                    </h4>
                    <div class="">
                        <Report v-if="!user.is_self" :send-report="report" />
                    </div>
                    <DescriptionLevelPartner v-if="user.level_partner" :level="user.level_partner" />
                </div>

                <div class="user-card__main__body__action">
                    <a
                        v-if="user.is_self"
                        class="btn btn-follow"
                        :href="editProfile"
                        target="_blank"
                        rel="noopener"
                    >
                        {{ $t('userCard.edit') }}
                    </a>

                    <subscribe
                        v-else
                        ref="subscribe"
                        :send="follow"
                        :subscribable="{ type: 'User', id: user.id }"
                    >
                        <button
                            :class="{ 'following': user.following }"
                            :disabled="user.banned_at"
                            class="btn btn-follow"
                            @click="$refs.subscribe.handle(!user.following)"
                        >
                            <span v-if="user.following"><i class="fa fa-check" /> {{ $t('userCard.following') }} </span>
                            <span v-else><i class="fa fa-plus" /> {{ $t('userCard.follow') }} </span>
                        </button>
                    </subscribe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import { user as toUser } from '~/utils/url'
    import Avatar from '~/components/shared/Avatar/Avatar.vue'
    import Subscribe from '../widgets/Subscribe.vue'
    import { report } from '~/api/users'
    import Report from '~/components/dialogs/Report.vue'
    import DescriptionLevelPartner from '~/components/DescriptionLevelPartner.vue'

    export default {

        components: {
            Avatar,
            Subscribe,
            Report,
            DescriptionLevelPartner,
        },

        props: {
            user: Object,
        },

        computed: {
            ...mapGetters('auth', ['isEditor']),

            editProfile() {
                return `${process.env.SSO_APP_URL}/profile/personal`
            },
        },

        methods: {
            toUser,
            ...mapActions('profile', ['follow']),

            report(data) {
                return report(this.user.username, data)
            },
        },
    }
</script>

<style lang="scss">
    @import "~assets/sass/bootstrap/colors";
    @import "~assets/sass/bootstrap/mixins";

    .user-name {
        font-size: 1.3rem;
    }

    .user-stats {
        color: $gray-light;
        display: flex;
        justify-content: flex-start;

        span {
            flex-shrink: 0;

            &:not(:first-child) {
                margin-left: 1rem;
            }
        }
    }

    .user-card {
        &__main {
            width: 100%;
            display: grid;
            column-gap: 5px;
            grid-template-columns: 100px calc(100% - 100px - 5px);
            padding: 5px;
            align-items: center;

            &__body {
                display: grid;
                column-gap: 5px;
                grid-template-columns: 70% 20%;
                align-items: start;

                &__username {
                    padding: 0 10px;
                }
                &__action {
                    margin-top: 5px;
                    margin-right: 5px;
                }
            }
        }
    }

    @media only screen and (max-width: 991px) {
        .user-card{
            &__main {
                &__body {
                    grid-template-columns: 100%;
                }
            }
        }
    }
</style>
