import _forEach from 'lodash/forEach'
import { uploadFile } from '~/api/uploads'
import { convertToFormData } from '~/utils/form'

export default {
    methods: {
        validateImage(image) {
            const acceptedFiles = ['image/jpeg', 'image/png', 'image/gif']
            const maxFilesize = 6 // MB
            const imageSize = image.size / 1000 / 1000 // MB

            if (!acceptedFiles.includes(image.type)) {
                this.$message.error(this.$t('validateUploadImage.invalidType'))

                return false
            }
            if (imageSize > maxFilesize) {
                this.$message.error(this.$t('validateUploadImage.maxFilesize', { imageSize: Math.round(imageSize * 100) / 100, maxFilesize }))

                return false
            }
            // eslint-disable-next-line
            if ($nuxt.isOffline) {
                this.$message.error(this.$t('validateUploadImage.offline'))

                return false
            }

            return true
        },

        getImageFromClipboard(event) {
            let image = null

            if (event.clipboardData && event.clipboardData.items) {
                const { items } = event.clipboardData
                _forEach(items, (item) => {
                    if (item.type.indexOf('image') !== -1 && this.validateImage(item.getAsFile())) {
                        image = item.getAsFile()
                    }
                })
            }

            return image
        },

        getImageFromDropEvent(event) {
            let image = null

            if (event.dataTransfer && event.dataTransfer.files) {
                const { files } = event.dataTransfer
                _forEach(files, (file) => {
                    if (file.type.indexOf('image') !== -1 && this.validateImage(file)) {
                        image = file
                    }
                })
            }

            return image
        },

        getImageUrlFromDropEvent(event) {
            const imgUrlRegex = new RegExp(/(https?:\/\/.*\.(?:png|jpg))/i)
            const imageUrl = event.dataTransfer ? event.dataTransfer.getData('text') : null

            return imgUrlRegex.test(imageUrl) ? imageUrl : null
        },

        async uploadImage(file) {
            try {
                const formData = convertToFormData({
                    file,
                    has_wartermark: false,
                })
                const { data: image } = await uploadFile(formData)

                return image.data
            } catch (error) {
                this.$message.error('Something went wrong. Please try again later')

                return null
            }
        },

        imageMdText(image) {
            return `![${image.name || ''}](${image.path})`
        },

        imageUploadMdText(image) {
            return `![${this.$t('editor.upload.uploading')} ${image.name || ''}…]()`
        },
    },
}
