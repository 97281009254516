// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <post-feed store="profile/posts" />
</template>

<script>
    import PostFeed from '~/containers/posts/PostFeed.vue'
    import { handlePageError, getContentSubDomain } from '~/utils/pages'

    export default {
        scrollToTop: true,

        components: {
            PostFeed,
        },

        fetch: ({
            params, query, store, error, req,
        }) => {
            const host = process.client ? window.location.host : req.headers.host

            return store.dispatch('profile/posts/fetch', {
                username: store.state.config.vars.is_special_profile ? getContentSubDomain(host) : params.user,
                params: {
                    limit: store.state.settings.perPage,
                    page: query.page,
                },
            }).catch(handlePageError(error))
        },

        watchQuery: ['page'],
    }
</script>
