<template>
    <div class="position-relative justify-content-center">
        <div
            class="d-flex"
            :class="user.level_partner ? 'partner-border-image-org' : 'border-image-org'"
        >
            <img
                v-if="user.level_partner"
                src="~/assets/images/partner-subdomain-img-cover.png"
                class="profile-cover-photo subdomain-header container"
                alt="Image Cover"
            >
            <img
                v-else
                src="~/assets/images/subdomain-img-cover.png"
                class="profile-cover-photo subdomain-header container"
                alt="Image Cover"
            >
        </div>
        <div class="subdomain-header__card subdomain-header container">
            <div class="profile-avatar org-avatar d-flex">
                <Avatar
                    :object="user"
                    :image="user.avatar"
                    :level-partner="user.level_partner"
                    :is-user="true"
                    :partner-width="180"
                    :partner-hight="180"
                    is-subdomain
                    image-size="avatar"
                    size="xxl"
                    class="user-card__main__hero"
                />

                <div class="profile-avatar_info d-flex justify-content-between ml-1">
                    <div>
                        <h1 class="user-name d-inline text-break">
                            {{ user.name }}
                        </h1>

                        <h4 class="text-muted py-05">
                            @{{ user.username }}
                        </h4>

                        <Report v-if="!user.is_self" :send-report="report" />

                        <DescriptionLevelPartner
                            v-if="user.level_partner"
                            :level="user.level_partner"
                        />
                    </div>

                    <div class="subscribe d-flex align-items-center">
                        <div class="d-flex align-items-center">
                            <div
                                v-tooltip
                                :title="descButtonSwitchTheme"
                                class="button-switch-theme"
                                :class="modeThemeSubdomain"
                                @click="switchTheme"
                            />
                        </div>

                        <div v-if="user.is_self" class="edit-profile">
                            <a
                                :href="editProfile"
                                class="btn btn-edit edit"
                                target="_blank"
                                rel="noopener"
                            >
                                <img
                                    src="/images/icon-edit.png"
                                    class="mr-05"
                                >
                                {{ $t('profile.edit') }}
                            </a>
                        </div>

                        <subscribe
                            v-else
                            ref="subscribe"
                            :send="follow"
                            :subscribable="{ type: 'User', id: user.id }"
                        >
                            <button
                                class="btn btn-follow-org"
                                @click="$refs.subscribe.handle(!user.following)"
                            >
                                <span
                                    v-if="user.following"
                                >
                                    {{ $t('profile.following') }}
                                </span>
                                <span v-else class="d-flex align-items-center">
                                    <img
                                        src="/images/icon-follow.png"
                                        class="stats-item__icon mr-05"
                                    >
                                    {{ $t('profile.follow') }}
                                </span>
                            </button>
                        </subscribe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapMutations } from 'vuex'
    import { report } from '~/api/users'
    import Avatar from '~/components/shared/Avatar/Avatar.vue'
    import Subscribe from '~/components/widgets/Subscribe.vue'
    import Report from '~/components/dialogs/Report.vue'
    import DescriptionLevelPartner from '~/components/subdomains/DescriptionLevelPartner.vue'
    import switchModeTheme from '~/pages/__mixins/switchModeTheme'

    export default {
        components: {
            Avatar,
            Subscribe,
            Report,
            DescriptionLevelPartner,
        },
        mixins: [switchModeTheme],
        props: {
            user: Object,
        },

        computed: {
            editProfile() {
                return `${process.env.SSO_APP_URL}/profile/personal`
            },

            modeThemeSubdomain() {
                return this.$store.state.typeControl.modeTheme
            },

            descButtonSwitchTheme() {
                return this.modeThemeSubdomain === 'default' ? this.$t('detailOrgaization.darkMode') : this.$t('detailOrgaization.lightMode')
            },
        },

        methods: {
            ...mapActions('profile', ['follow']),
            ...mapMutations({
                switchMode: 'typeControl/switchMode',
            }),
            report(data) {
                return report(this.user.username, data)
            },

            switchTheme() {
                const theme = this.modeThemeSubdomain === 'default' ? 'theme-dark' : 'default'
                this.handleSwitchTheme(theme)
            },
        },
    }
</script>

<style lang="scss">
@import '~/assets/sass/components/subdomain-header';
.subdomain-header__card {
    .profile-avatar {
        .subscribe .btn-edit {
            font-weight: 550;
            color: #ADA7A7 !important;
            border: 1px solid #0EA8FF;
            background-color: #F6FDFF !important;

            &:hover {
                opacity: 0.7;
            }
        }

        .btn.btn-follow-org {
            font-weight: 600;
            color: white;
            background-color: #0EA8FF;
            height: 47px;
            min-height: 47px;
            border-radius: 0.5rem;
        }
    }

    @media screen and (min-width: 769px) {
        .profile-avatar {
            a .avatar {
                width: 150px !important;
                max-height: 150px !important;
            }

            .border-avt-by-level {
                left: -14px !important;
            }
        }
    }
}
.partner-border-image-org {
    background-color: #00A2E9;
}
.border-image-org {
    background-color: #0420C1;
}
.button-switch-theme {
    width: 60px;
    height: 47px;
    background-image: url('/images/button-dark-theme.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    cursor: pointer;
    margin-right: 8px;

    &.theme-dark {
        background-image: url('/images/button-light-theme.png');
    }
}

.theme-dark {
    .subdomain-header__card {
        background: #000;
    }
}
</style>
