// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div class="tag-item">
        <div class="tag-item-image">
            <nuxt-link :to="to">
                <img
                    :src="tag.image"
                    :alt="tag.name"
                >
            </nuxt-link>
        </div>
        <div class="tag-details">
            <tag-header
                :tag="tag"
                :can-change="isCollaborator"
                :nuxt-link="true"
                class="tag-name overflow-hidden h3"
            />
            <div>
                <span class="tag-counter">
                    <strong>{{ tag.posts_count }}</strong> {{ $t('getStarted.tagItem.posts') }}
                </span>
                <br>
                <span class="tag-counter">
                    <strong>{{ tag.questions_count }}</strong> {{ $t('getStarted.tagItem.questions') }}
                </span>
                <br>
                <span class="tag-counter">
                    <strong v-cloak>
                        {{ tag.followers_count }}
                    </strong>
                    {{ $t('getStarted.tagItem.followers') }}
                </span>
            </div>

            <subscribe
                ref="subscribe"
                :send="handleSubscribe"
                :subscribable="{ type: 'Tag', 'id': tag.slug }"
            >
                <button
                    :class="tag.following ? 'btn-primary' : 'btn-white'"
                    class="btn btn-small-padding btn-medium-border btn-subscribe"
                    @click="$refs.subscribe.handle(!tag.following)"
                >
                    <span v-if="tag.following"><i class="fa fa-check" /> {{ $t('getStarted.follow.following') }}</span>
                    <span v-else><i class="fa fa-plus" /> {{ $t('getStarted.follow.follow') }}</span>
                </button>
            </subscribe>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import { routeToTag as toTag } from '~/lib/functions'
    import Subscribe from '../widgets/Subscribe.vue'
    import TagHeader from '~/components/tags/TagHeader.vue'

    export default {
        components: {
            Subscribe,
            TagHeader,
        },

        props: {
            tag: {
                type: Object,
                required: true,
            },
        },

        computed: {
            ...mapGetters('auth', ['isCollaborator']),

            to() {
                return toTag(this.tag)
            },
        },

        methods: {
            ...mapActions('entities/tags', ['subscribe']),

            handleSubscribe(value) {
                return this.subscribe({ tag: this.tag, value })
            },
        },
    }
</script>

<style lang="scss">
    @import "../../assets/sass/bootstrap/mixins";
    @import "../../assets/sass/bootstrap/variables";

    .tag-item {
        display: flex;

        .tag-item-image {
            margin-right: 1rem;

            img {
                width: 100px;
                border-radius: $base-border-radius;
            }
        }

        .tag-details {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            min-width: 0;
        }

        .tag-name {
            color: $dark;
            max-width: 100%;
        }

        .tag-counter {
            color: $gray-light;
        }
    }
</style>
