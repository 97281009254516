// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<script>
    import { humanizeTime, formatTime } from '~/filters/dates'

    export default {
        functional: true,

        props: {
            comment: Object,
        },

        render: (h, { props, parent }) => {
            const comment = props.comment
            const time = humanizeTime(comment.created_at)
            const updateTime = comment.edited_at || comment.created_at
            const editedTime = updateTime !== comment.created_at
                ? humanizeTime(updateTime)
                : null

            return (
                <div class='comment-meta word-break'>
                    <span
                        v-tooltip title={`${formatTime(comment.created_at)}`}
                        class="text-muted"
                    >{ time }</span>
                    {
                        editedTime !== null
                        ? <i v-tooltip title={`${parent.$t('lastEdited')} ${editedTime}`} class='fa fa-pencil text-muted ml-05'/>
                        : null
                    }
                </div>
            )
        },
    }
</script>
