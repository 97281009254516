// gkc_hash_code : 01DNGPFFRE6J9BGNH5H18GN5SX
<template>
    <div v-if="reports">
        <p class="title-list-report mt-2">
            {{ $t('profile.reputationHistory') }}
        </p>

        <Item v-for="(report, index) in reports" :key="index" :report="report" />

        <pagination
            v-if="pagination"
            :current-page="pagination.current_page"
            :last-page="pagination.total_pages"
        />
    </div>
</template>

<script>
    import Item from './widgets/Item.vue'
    import Pagination from '~/components/subdomains/Pagination.vue'

    export default {

        components: {
            Item,
            Pagination,
        },

        props: {
            reports: {
                type: Array,
            },
            pagination: {
                type: Object,
            },
        },
    }
</script>

<style lang="scss">
    .title-list-report {
        font-size: 17px;
    }

    .theme-dark {
        .title-list-report {
            color: white;
        }
    }
</style>
